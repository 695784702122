import React, { useEffect, useState } from "react";
//b
import axios from "axios";
import { uri } from "../../../../help/Api";
import { insh, sto } from "../../../../Context/InshData";
import moment from "moment";
// rt
import CssBaseline from "@material-ui/core/CssBaseline";
// mt
// f e
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import ScannerIcon from "@material-ui/icons/Scanner";
import KeyboardReturnSharpIcon from "@material-ui/icons/KeyboardReturnSharp";
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import Chip from "@material-ui/core/Chip";

import PrintIcon from "@material-ui/icons/Print";
import { useHistory } from "react-router-dom";

// import tableIcons from "../../../../Elemnts/TableIcons";
import { TableIcons as tableIcons } from "../../../../Elemnts/TableIcons";

import Container from "@material-ui/core/Container";
// cuG
import {
  useStylesUp,
  useStylesHed,
  useStylesAuto,
  useStylesTF,
  useStylesTD,
  useStylesBA,
  useStylesLbl,
  useStylesUiElemment,
  useStylesSpDial,
} from "../../../../St/Reports/UseStyle";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import SaleReportTaxTable from "./saleReportTax-table";

const defaultValues = {
  dateFrom: moment(new Date()).format("YYYY-MM-DD"),
  dateTo: moment(new Date()).format("YYYY-MM-DD"),
  branch: {},
  branchId: 0,
  type: "transAll",
};

//=====================================================================

// let prm = {Ne:nl,Sa:nl,De:nl,Se:nl,Pr:nl,Up:nl}
const inshAutoOp = [{ id: -1, name: "" }];

export default function SaleReportTaxForm(props) {
  const {
    data,
    onChangeData,
    rptParams,
    printHandle,
    setRptParams,
    fetching,
    fetchURL,
    title,
    systemId,
  } = props;

  const clasup = useStylesUp();
  const clashd = useStylesHed();
  const clasAo = useStylesAuto();
  const clasTF = useStylesTF();
  const clasTD = useStylesTD();
  const claslbl = useStylesLbl();
  const clasui = useStylesUiElemment();
  const clasSp = useStylesSpDial();
  const insOrders = [
    { id: 1, name: "ترتيب حسب التاريخ" },
    { id: 2, name: "ترتيب حسب رقم المستند" },
  ];
  const [optionsOrder, setOptionsOrder] = useState(insOrders);

  const insReviws = [
    { id: 1, name: "إجمالي" },
    { id: 2, name: "تفصيلي" },
  ];
  const [optionsReviw, setOptionsReviw] = useState(insReviws);
  const [valueReviw, setValueReviw] = useState(optionsReviw[1]);

  const inshrpt = {
    dateFrom: moment(new Date()).format("YYYY-MM-DD"),
    dateTo: moment(new Date()).format("YYYY-MM-DD"),
    branch: -1,
    user: -1,
    safe: -1,
    stock: -1,
  };

  const [rpt, setRpt] = useState(inshrpt);
  const [gets, setGets] = useState(insh.tbname);

  const inshValueAuto = {
    safe: {},
    center: {},
    branch: {},
    user: {},
  };
  const [valueAutos, setValueAutos] = useState(inshValueAuto);
  const [optionsCustomers, setOptionsCustomers] = useState(inshAutoOp);
  const [valueCustomer, setValueCustomer] = useState();

  const getAutoCustomers = async (i) => {
    const t = systemId == 0 ? 2 : 1;
    axios
      .get(uri + "Auto" + `/custm?t=${t}`)
      .then((res) => {
        setOptionsCustomers(res.data);
        setValueCustomer(res.data[i]);
      })
      .catch((error) => {});
  };

  const reLoadRpt = async () => {
    const tb = {
      RptSalesDayle: false,
      wait: true,
      stor: sto,
      parms: {
        dateFrom: rpt.dateFrom,
        dateTo: rpt.dateTo,
        branch: rpt.branch,
        user: rpt.user,
        safe: rpt.safe,
        stock: rpt.stock,
      },
      // autos:{},
    };
    console.log("tb___");
    console.log(tb);
    await axios
      .post(uri + "Rept", tb)
      .then((res) => {
        setGets({
          ...gets,
          SalesDayle: res.data.SalesDayle,
          tot: res.data.tot,
        });
      })
      .catch((error) => {
        /*console.log('no rpt');*/
      });
  };

  const loadAutos = async () => {
    const tb = {
      wait: true,
      Safes: true,
      Branches: true,
      Users: true,
      costcenters: true,
      stor: sto,
      parms: {
        safe: -1,
      },
    };
    await axios
      .post(uri + "Rept", tb)
      .then((res) => {
        console.log(res);
        setGets(res.data);
      })
      .catch((error) => {
        /*console.log('no rpt');*/
      });
  };

  useEffect(async () => {
    await loadAutos();
    getAutoCustomers(0);
  }, []);

  const changAutos = (prop, newValue) => {
    setRptParams({ ...rptParams, [prop]: newValue });
  };

  const changTField = (prop) => (e) => {
    setRpt({ ...rpt, [prop]: e.target.value });
    prop == "dateFrom"
      ? (rpt.dateFrom = e.target.value)
      : (rpt.dateTo = e.target.value);
    reLoadRpt();
  };

  // ==== b sd
  const [open, setOpen] = React.useState(false);
  const toBack = () => {
    his.push({ pathname: "/" });
  };

  let his = useHistory();
  const frontDownR = () => {
    return (
      <Grid container lg={12} md={12} className={[clasup.rootdown]}>
        <Grid container xs={12} className={[clasup.downsub, clasup.downsubR]}>
          {/* S front F */}
          <Grid container lg={5} md={5} sm={5} className={clasui.rootchip}>
            <Grid item lg={12} md={12} sm={12} xs={6}>
              <Chip
                className={[clasui.chipS, clasui.chipTR]}
                label={"إجمالي عدد السجلات"}
                clickable
                color="primary"
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={6}>
              <Chip
                className={[clasui.chipV, clasui.chipVR]}
                label={data.totals.rowsCount}
                clickable
                color="default"
              />
            </Grid>
          </Grid>
          <Grid container lg={7} md={7} sm={7} className={clasui.rootchip}>
            <Grid item lg={12} md={12} sm={12} xs={6}>
              <Chip
                className={[clasui.chipS, clasui.chipTR]}
                label={`الإجمالي(${title}-المردودات)قبل الضريبة`}
                clickable
                color="primary"
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={6}>
              <Chip
                className={[clasui.chipV, clasui.chipVR]}
                label={data.totals.TotalBeforVat}
                clickable
                color="default"
              />
            </Grid>
          </Grid>
          <Grid container lg={5} md={5} sm={5} className={clasui.rootchip}>
            <Grid item lg={12} md={12} sm={12} xs={6}>
              <Chip
                className={[clasui.chipS, clasui.chipTR]}
                label={`الإجمالي(${title}-المردودات)`}
                color="primary"
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={6}>
              <Chip
                className={[clasui.chipV, clasui.chipVR]}
                label={data.totals.TotalVat}
                clickable
                color="default"
              />
            </Grid>
          </Grid>
          <Grid container lg={7} md={7} sm={7} className={clasui.rootchip}>
            <Grid item lg={12} md={12} sm={12} xs={6}>
              <Chip
                className={[clasui.chipS, clasui.chipTR]}
                label={`الإجمالي(${title}-المردودات)بعد الضريبة`}
                clickable
                color="primary"
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={6}>
              <Chip
                className={[clasui.chipV, clasui.chipVR]}
                label={data.totals.TotalAfterVat}
                clickable
                color="default"
              />
            </Grid>
          </Grid>
          {/* E front F */}
        </Grid>
      </Grid>
    );
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="xl">
        <Typography
          component="div"
          style={{
            textAlign: "center",
            backgroundColor: "#fff",
            height: "100vh",
            direction: "rtl",
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Chip
                size="small"
                className={clashd.ChipHdSale}
                label={`التقرير الضريبي ${title}  `}
                color="primary"
              />
            </Grid>

            {/* S front Right */}
            <Grid container lg={4} md={5}>
              {/* S front up R */}
              <Grid container lg={12} md={12} className={[clasup.rootup]}>
                <Grid
                  container
                  spacing={1}
                  xs={12}
                  className={[clasup.upsub, clasup.upsubL]}
                >
                  <Grid item lg={6} md={6} sm={4} xs={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      id="fd"
                      label="من تاريخ"
                      className={clasTF.rootSale}
                      InputProps={{ className: clasTD.input }}
                      type="date"
                      format="YYYY-MM-DD"
                      name="fd"
                      value={rptParams.dateFrom}
                      onChange={onChangeData("dateFrom")}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} sm={4} xs={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      id="td"
                      label="إلى تاريخ"
                      className={clasTF.rootSale}
                      InputProps={{ className: clasTD.input }}
                      type="date"
                      format="YYYY-MM-DD"
                      name="td"
                      value={rptParams.dateTo}
                      onChange={onChangeData("dateTo")}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} sm={4} xs={6}>
                    <Autocomplete
                      classes={clasAo}
                      value={rptParams.safe}
                      labelPlacement=""
                      onChange={(event, newValue) => {
                        changAutos("safe", newValue);
                      }}
                      id="controllable-states-demo"
                      size="small"
                      options={gets.Safes}
                      getOptionLabel={(option) =>
                        typeof option === "string" ? option : option.name
                      }
                      // style={{  }}
                      disableClearable
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="المخزن"
                          variant="outlined"
                          size="small"
                          InputLabelProps={{ className: claslbl.clrSale }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} sm={4} xs={6}>
                    <Autocomplete
                      classes={clasAo}
                      value={rptParams.costCenter}
                      labelPlacement=""
                      onChange={(event, newValue) => {
                        changAutos("costCenter", newValue);
                      }}
                      id="controllable-states-demo"
                      size="small"
                      options={gets.center}
                      getOptionLabel={(option) =>
                        typeof option === "string" ? option : option.name
                      }
                      disableClearable
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="مركز التكلفة"
                          variant="outlined"
                          size="small"
                          InputLabelProps={{ className: claslbl.clrSale }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} sm={4} xs={6}>
                    <Autocomplete
                      classes={clasAo}
                      value={rptParams.branch}
                      onChange={(event, newValue) => {
                        changAutos("branch", newValue);
                      }}
                      id="controllable-states-demo"
                      size="small"
                      options={gets.Branches}
                      getOptionLabel={(option) =>
                        typeof option === "string" ? option : option.name
                      }
                      disableClearable
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="الفرع"
                          variant="outlined"
                          size="small"
                          InputLabelProps={{ className: claslbl.clrSale }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} sm={4} xs={6}>
                    <Autocomplete
                      classes={clasAo}
                      value={rptParams.cust}
                      onChange={(event, newValue) => {
                        changAutos("cust", newValue);
                      }}
                      id="controllable-states-demo"
                      size="small"
                      options={optionsCustomers}
                      getOptionLabel={(option) =>
                        typeof option === "string" ? option : option.name
                      }
                      disableClearable
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={systemId == 0 ? "المورد" : "العميل"}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{ className: claslbl.clrSale }}
                        />
                      )}
                    />
                  </Grid>
                  <RadioGroup
                    row
                    onChange={onChangeData("itemsTypeVat")}
                    value={rptParams.itemsTypeVat}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="كل الأصناف"
                    />
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="أصناف خاضعة للضريبة"
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio />}
                      label="أصناف غير خاضعة للضريبة"
                    />
                  </RadioGroup>

                  <p
                    style={{
                      backgroundColor: "#777777",
                      padding: "2px",
                      color: "#fff",
                    }}
                    color="default"
                  >
                    {`هذا التقرير الضريبي بناء على فواتير ${title} فقط .. وناتج التقرير بناء على ما تم إدخالة من قبل المستخدم حسب الإعدادات `}
                  </p>

                  <Hidden>
                    <Grid item container spacing={1} className={clasui.contbnt}>
                      <Grid item>
                        <Button
                          disabled={fetching}
                          variant="contained"
                          startIcon={<ScannerIcon />}
                          color="primary"
                          size="large"
                          className={[clasui.button, clasSp.clPri]}
                          onClick={() => {
                            fetchURL();
                          }}
                        >
                          عرض{" "}
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button
                          variant="contained"
                          startIcon={<PrintIcon />}
                          color="primary"
                          size="large"
                          className={[clasui.button, clasSp.clSec]}
                          onClick={printHandle}
                        >
                          طباعة{" "}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          startIcon={<KeyboardReturnSharpIcon />}
                          color="secondary"
                          size="large"
                          className={[clasui.button, clasSp.clcan]}
                          onClick={toBack}
                        >
                          خروج
                        </Button>
                      </Grid>
                    </Grid>
                  </Hidden>
                </Grid>
              </Grid>
              <Hidden smDown={["sm"]}>{frontDownR()}</Hidden>
              {/* E front down R */}
            </Grid>
            <Grid container lg={8} md={7}>
              {/* s front up L */}
              <Grid container lg={12} md={12} className={[clasup.rootup]}>
                <Grid
                  container
                  xs={12}
                  className={[clasup.upsub, clasup.upsubL]}
                >
                  <Grid item xs={12}>
                    <SaleReportTaxTable
                      {...{
                        data: data.SalePurchVat,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Hidden mdUp={["md"]}>{frontDownR()}</Hidden>
          </Grid>
        </Typography>
      </Container>
    </React.Fragment>
  );
}
