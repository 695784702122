import React from 'react';
import MaterialTable from "material-table";
import {cellS,headerS,} from '../../../../St/comps/cStyle'

export default function CurrencyDetailsRPTable(props){

    const {data} = props;

 var columns = [
    {title: "نوع الحركة", field: "Name_Trans",},
    {title: "المخزن", field: "Name_Safe",},
    {title: "رقم الفاتورة", field: "id",},
    {title: "التاريخ", field: "date",},
    {title: "الكمية", field: "val",},
    {title: "السعر", field: "exchange_price",},
    {title: "الإجمالي", field: "total",},
    {title: "البيان", field: "Name_Customer",},
    {title: "الفرع", field: "Name_Branche",},
  ];


    return(
        <MaterialTable style={{boxShadow:'none'}}
              options={{ 
              pageSize: 7,
              toolbar: false,
              sorting: false,
              showTitle: false,
              paging: false,
              headerStyle: headerS,
              cellStyle: cellS,
              maxBodyHeight: '70vh',
              minBodyHeight: '70vh',

             }}
       
             localization={{
               
              body:{
                emptyDataSourceMessage:<h3 
                style={{ marginTop:'20vh', border:'solid 0px', boxShadow:'none'}}
                ></h3> } }}
             columns={columns}
             data={data}            
             onRowClick={(event, rowData) => {
              event.stopPropagation();
            }}/>
    )
}