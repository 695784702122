import React from "react";
import { uri } from "../../../../../help/Api";
import useFetchObjectByPost from "../../../../../components/Hooks/useFetchObjectByPost";
import { useEffect } from "react";
import { insh, sto } from "../../../../../Context/InshData";
import BackdropScI from "../../../../../services/BackdropScI";
import MultiVoucher from "./MultiVoucher";
import dataF from "../../../../../Server/dataF.json";
import useFetchURL from "../../../../../components/Hooks/useFetchURL";
import { accountsURL } from "../../../../../api/EReceipt";

export function getReFormatingAccount(data) {
  const newData = data.map((row, i) => {
    return {
      id: row.Code,
      name: row.AName,
      acc_omlat: row?.omlat?.map((om) => {
        return om.omla_id;
      }),
    };
  });
  return newData;
}

function MultiVoucherItem(props) {
  const tttb = {
    wait: true,
    other: true,
    Stocks: true, // Error // out
    Banks: true,
    salesmen: true,
    costcenters: true,
    CheckStatus: true,
    omlat: true,
    PermissionsElemnts: true,
    /*params*/ // out
    parms: {
      ibranchid: sto.branchi,
      iempid: sto.empi,
      iuserid: sto.idi,
      scurrenciestype: "I",
      branch: sto.branchi,
      tname: props.isReceipt ? "sandmq" : "sandms",
      withOmlat: true,
      iformid: dataF[66].id,
    },
    autos: {
      Safes: true,
      Stocks: true,
      Banks: true,
      salesmens: true,
      costcenters: true,
      CheckStatus: true,
      omlat: true,
    },
  };

  useEffect(() => {
    console.log("hello ", data);
  });

  const url = uri + "Res";

  const {
    object: AutoOptions,
    fetching: fetchinData,
    fetch: fetchURL,
  } = useFetchObjectByPost(url, tttb);
  const {
    data,
    fetching,
    fetchURL: fetchURL1,
  } = useFetchURL({
    ...{
      url: accountsURL,
      mapFunction: getReFormatingAccount,
    },
  });
  if (fetchinData || !AutoOptions?.Stocks || !data || fetching)
    return <BackdropScI />;
  return (
    <MultiVoucher
      {...{
        AutoOptions: AutoOptions?.Stocks
          ? { ...AutoOptions, Customers: data }
          : [],
        fetchURL,
        prmis: AutoOptions?.permisElm ?? insh.permiElm,
        ...props,
      }}
    />
  );
}

export default function MReceiptVoucherCon() {
  return (
    <MultiVoucherItem
      {...{
        isDialog: false,
        isReceipt: true,
      }}
    />
  );
}

export function MExchangVoucherCon() {
  return (
    <MultiVoucherItem
      {...{
        isDialog: false,
        isReceipt: false,
      }}
    />
  );
}

export function MultiVoucherLink(props) {
  const { data: item, onClose } = props;

  return (
    <MultiVoucherItem
      {...{
        isDialog: true,
        isReceipt: true,
        item,
        onClose,
      }}
    />
  );
}
