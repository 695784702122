import moment from "moment";
import { sto } from "../Context/InshData";

export const invoicePayTypes = [
  { id: -1, name: "الكل" },
  { id: 1, name: "كاش" },
  { id: 2, name: "شبكة" },
];
let today = new Date();

export const initialSearchObject = {
  PcIdIvPc: "",
  refno: "",
  isAdmin: sto.User_typei == 1,
  branch: { id: sto.branchi, name: "الفرع الحالي" },
  sales_emp: { id: -1, name: "المستخدم الحالي", emp: sto.empi },
  bill_no: "",
  customer: { id: -1, name: "الكل" },
  bank: { id: -1, name: "الكل" },
  cust_mobile: "",
  inv_type: { id: -1, name: "الكل" },
  pay_type: { id: -1, name: "الكل" },
  dateFrom: moment(today.setDate(today.getDate() - 1)).format("YYYY-MM-DD"),
  timeFrom: moment(new Date()).startOf("day").format("HH:mm"),
  dateTo: moment(today.setDate(today.getDate() + 1)).format("YYYY-MM-DD"),
  timeTo: moment(new Date()).endOf("day").format("HH:mm"),
};
