import React from "react";
import Container from "@material-ui/core/Container";
import { makeStyles, styled } from "@material-ui/core/styles";
import { Chip, colors } from "@material-ui/core";
import LocalAtmIocn from "@material-ui/icons/AttachMoney";
import TransformIcon from "@material-ui/icons/TransformOutlined";
import PaymentsIcont from "@material-ui/icons/PaymentTwoTone";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  chip: {
    paddingLeft: "10px",
    paddingRight: "20px",
    marginLeft: "20px",
    // color:colors.orange[800],
    fontSize: "16px",
  },
}));

export const paymentType = [
  { id: "1", name: "cash", icon: <LocalAtmIocn /> },
  { id: "2", name: "check", icon: <PaymentsIcont /> },
  { id: "3", name: "translate", icon: <TransformIcon /> },
];

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const ReceiptVoucherType = (props) => {
  const { t } = useTranslation(["forms"]);

  const { type, onChange, isMulti = false } = props;
  const classes = useStyles();
  const payments = isMulti
    ? paymentType?.filter((x) => x.id != "3")
    : paymentType;
  return (
    <Container
      style={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        listStyle: "none",
        padding: "10px",
        margin: "0px",
      }}
      component="ul"
    >
      {payments.map((x) => {
        return (
          <ListItem key={x.id}>
            {x.id == type ? (
              <Chip
                className={classes.chip}
                label={t(x.name)}
                icon={x.icon}
                // color="secondary"
                style={{ backgroundColor: colors.grey[500], color: "#fff" }}
                clickable
              />
            ) : (
              <Chip
                className={classes.chip}
                label={t(x.name)}
                icon={x.icon}
                variant="outlined"
                // color="secondary"
                style={{ color: colors.grey[800] }}
                onClick={() => {
                  onChange(null, { name: "type", value: x.id });
                }}
              />
            )}
          </ListItem>
        );
      })}
    </Container>
  );
};

export default ReceiptVoucherType;
