import React, { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import { useTranslation } from "react-i18next";
const types = [
  { id: 0, name: "حسب سعر شراء الصنف" },
  { id: 1, name: "حسب مخزون اول المدة والمشتريات" },
];
export default function ProfitMarginForm(props) {
  const [type, setType] = useState(types[0]);
  const { t } = useTranslation(["forms"]);

  const {
    rptParams,
    onChangeData,
    AutoOptions,
    CustomerOptions,
    onChangeAutoData,
    printHandle,
  } = props;

  return (
    <React.Fragment>
      <Grid container style={{ padding: 5 }} spacing={1}>
        <Grid item lg={2} md={2} sm={2} xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            id="datef"
            label={t("date-from")}
            type="date"
            format="YYYY-MM-DD"
            name="datef"
            value={rptParams.dateFrom}
            onChange={onChangeData("dateFrom")}
          />
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            id="dateT"
            label={t("date-to")}
            type="date"
            format="YYYY-MM-DD"
            name="datet"
            value={rptParams.dateTo}
            onChange={onChangeData("dateTo")}
          />
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={6}>
          <Autocomplete
            value={rptParams.safe}
            onChange={(event, newValue) => {
              onChangeAutoData("safe", newValue);
            }}
            id="combo-box-demo"
            size="small"
            options={AutoOptions.Safes}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            style={{}}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                label={t("safe")}
                variant="outlined"
              />
            )}
          />
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={6}>
          <Autocomplete
            value={rptParams.cust}
            onChange={(event, newValue) => {
              onChangeAutoData("cust", newValue);
            }}
            id="combo-box-demo"
            size="small"
            options={CustomerOptions}
            //getOptionLabel={(option) => option.name}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            style={{}}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                label={t("customer")}
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={6}>
          <Autocomplete
            value={rptParams.user}
            onChange={(event, newValue) => {
              onChangeAutoData("user", newValue);
            }}
            id="combo-box-demo"
            size="small"
            options={AutoOptions.Users}
            //getOptionLabel={(option) => option.name}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            style={{}}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                label={t("user")}
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={6}>
          <Autocomplete
            value={rptParams.stock}
            onChange={(event, newValue) => {
              onChangeAutoData("stock", newValue);
            }}
            id="combo-box-demo"
            size="small"
            options={AutoOptions.Stocks}
            //getOptionLabel={(option) => option.name}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            style={{}}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                label={t("stock")}
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={6}>
          <Autocomplete
            value={type}
            onChange={(event, newValue) => {
              setType(newValue);
              onChangeAutoData("type_cost", newValue?.id);
            }}
            id="combo-box-demo"
            size="small"
            options={types}
            //getOptionLabel={(option) => option.name}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            style={{}}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                label={t("calc-average")}
                variant="outlined"
              />
            )}
          />
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={6}>
          <Autocomplete
            value={rptParams.group}
            onChange={(event, newValue) => {
              onChangeAutoData("group", newValue);
            }}
            id="combo-box-demo"
            size="small"
            options={AutoOptions.groups}
            //getOptionLabel={(option) => option.name}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            style={{}}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                label={t("group")}
                variant="outlined"
              />
            )}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
