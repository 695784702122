import React from "react";
// import Container from '@material-ui/core/Container';
import CssBaseline from "@material-ui/core/CssBaseline";
import Swipeable from "../../../../../Fronts/Main/Swipeable";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

import Body from "./Body";

export default function ReportActivity() {
  return (
    <React.Fragment>
      <Body />
      <Typography component="div">
        {" "}
        <Swipeable />{" "}
      </Typography>
    </React.Fragment>
  );
}
