import React, { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { salesmanCommTypes } from "../../../../../services/saleAnalysis-services";

export default function SaleManCommForm(props) {
  const { rptParams, onChangeData, SalesMens, onChangeAutoData } = props;

  const { t } = useTranslation(["forms"]);

  return (
    <React.Fragment>
      <Grid container style={{ padding: 5 }} spacing={1}>
        <Grid item lg={3} md={3} sm={4} xs={6}>
          <Autocomplete
            value={rptParams.salesMan}
            onChange={(event, newValue) => {
              onChangeAutoData("salesMan", newValue);
            }}
            id="combo-box-demo"
            size="small"
            options={SalesMens}
            //getOptionLabel={(option) => option.name}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            style={{}}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                label={t("salesman")}
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            id="datef"
            label={t("date-from")}
            type="date"
            format="YYYY-MM-DD"
            name="datef"
            value={rptParams.dateFrom}
            onChange={onChangeData("dateFrom")}
          />
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            id="dateT"
            label={t("date-to")}
            type="date"
            format="YYYY-MM-DD"
            name="datet"
            value={rptParams.dateTo}
            onChange={onChangeData("dateTo")}
          />
        </Grid>

        <Grid item lg={3} md={3} sm={4} xs={6}>
          <Autocomplete
            value={rptParams.CommType}
            onChange={(event, newValue) => {
              onChangeAutoData("CommType", newValue);
            }}
            id="combo-box-demo"
            size="small"
            options={salesmanCommTypes}
            //getOptionLabel={(option) => option.name}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            style={{}}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                label={t("نوع العمولة")}
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={6}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  fullWidth
                  checked={rptParams.CommWithVat}
                  onChange={(e) =>
                    onChangeAutoData("CommWithVat", e.target.checked)
                  }
                  name="chkVat"
                />
              }
              size="small"
              color="primary"
              fontSize="small"
              label={t("عمولة مع ضريبة")}
            />
          </FormGroup>
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={6}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  fullWidth
                  checked={rptParams.CommWithProfit}
                  onChange={(e) =>
                    onChangeAutoData("CommWithProfit", e.target.checked)
                  }
                  name="CommWithProfit"
                />
              }
              size="small"
              color="primary"
              fontSize="small"
              label={t("عمولة مع الربح")}
            />
          </FormGroup>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
