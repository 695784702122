import React, { Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Delete as DeleteIcon } from "@material-ui/icons";
import { colors, Container, Paper } from "@material-ui/core";
import { cellS, headerS } from "../../../../../St/comps/cStyle";
import { useTranslation } from "react-i18next";

export default function ORestSubTable(props) {
  const { rows, selectRow, deleteRow, loading } = props;
  const { t } = useTranslation("forms");

  var columns = [
    { title: "id", field: "id", hidden: true },
    { title: t("account-code"), field: "acc_no" },
    { title: t("account"), field: "name" },
    { title: "دائن", field: "credit" },
    { title: "مدين", field: "dept" },
    { title: t("دائن محلي"), field: "credit_loc" },
    { title: t("مدين محلي"), field: "dept_loc" },
    { title: t("currency"), field: "acc_omla.name" },
    { title: t("curr-exchange"), field: "acc_omla.exchange" },
    { title: t("مركز الكلفة"), field: "center.name" },
    { title: t("note"), field: "notes" },
  ];

  return (
    <Grid item container>
      <Grid item xs={12}>
        {loading ? (
          <CircularProgress />
        ) : (
          <MaterialTable
            style={{ boxShadow: "none", direction: "rtl" }}
            options={{
              pageSize: 7,
              //showTitle:false,
              toolbar: false,
              sorting: false,
              showTitle: false,
              paging: false,

              headerStyle: {
                ...headerS,
                backgroundColor: colors.grey[700],
                color: "#fff",
              },
              cellStyle: cellS,
              maxBodyHeight: "50vh",
              minBodyHeight: "30vh",
            }}
            localization={{
              // body: { emptyDataSourceMessage: <h3 style={{ color: '#000' }} >لا توجد اصناف</h3>, },
              body: {
                emptyDataSourceMessage: (
                  <h3
                    style={{
                      marginTop: "20vh",
                      border: "solid 0px",
                      boxShadow: "none",
                    }}
                  ></h3>
                ),
              },
              toolbar: { searchPlaceholder: "" },
            }}
            onRowClick={(event, rowData) => {
              selectRow(rowData);
              // showData(rowData);
              event.stopPropagation();
            }}
            columns={columns}
            data={rows}
            icons={"tableIcons"}
            actions={[
              {
                // icon: "Add",
                icon: () => <DeleteIcon style={{ color: "red" }} />,
                iconProps: {
                  style: {
                    justifyContent: "center",
                    fontSize: "60px",
                    color: "red",
                  },
                },
                tooltip: "حذف",

                onClick: (e, rowData) => {
                  deleteRow(rowData.id);
                },
              },
            ]}
          />
        )}
      </Grid>
    </Grid>
  );
}
