import React, { useState, useEffect, Fragment } from "react";

import Grid from "@material-ui/core/Grid";
import { sto } from "../../../Context/InshData";
import { useInvAF } from "../../../St/Reviews/UseStyle";
import { ButtonBase, Container, makeStyles } from "@material-ui/core";
//import {useStylesHed} from '../../../St/comps/UseStyle';
import { txtA, txtE } from "../../../Label/Lbl";
import InvBarcode from "./Bardode";
import { useBody, useHeader } from "./style";

function Footer(props) {
  const { data, InvForFatora, found } = props;
  const clas = useInvAF();
  const calssBody = useBody();
  const classHeader = useHeader();

  // useEffect(() => {
  //   setTimeout(async () => {
  //     console.log("child footer");
  //     // onUnmountComplete();
  //   }, 0);
  // }, []);

  const sty = {
    cntr: { textAlign: "center", padding: 10, fontFamily: "Amiri-Regular" },
    lft: { textAlign: "left", padingLeft: 5 },
    rit: { textAlign: "right", padingRight: 5 },
    dirR: { direction: "rtl", color: "black" },
    dirL: { direction: "ltr", padding: 10 },
  };
  return (
    <Fragment>
      <Container style={{ direction: "rtl" }}>
        <Grid
          item
          container
          className={clas.BdytotSub}
          // style={{ paddingBottom: "2rem" }}
        >
          <Grid
            item
            xs={7}
            container
            className={calssBody.totalItem}
            spacing={1}
          >
            <Grid item xs={4} className={calssBody.BdytotTitle}>
              {(data.tot.tot_net - data.tot.amountOfVat).toFixed(2)}
            </Grid>
            <Grid item xs={7}>
              {" الإجمالي  " + " " + txtE.totalAmount}
            </Grid>
            <Grid item xs={4} className={calssBody.BdytotTitle}>
              {data.tot.discount.toFixed(2)}
            </Grid>
            <Grid item xs={7}>
              {txtA.discount + " " + txtE.discount}
            </Grid>
            <Grid item xs={4} className={calssBody.BdytotTitle}>
              {data.tot.amountOfVat.toFixed(2)}
            </Grid>
            <Grid item xs={7}>
              {txtA.amountOfVat + " " + txtE.amountOfVat}
            </Grid>
            <Grid item xs={4} className={calssBody.BdytotTitle}>
              {data.tot.tot_net.toFixed(2)}
            </Grid>
            <Grid item xs={7}>
              {" الإجمالي مع الضريبة  " + " " + "Total Amount With Vat"}
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}></Grid>
          </Grid>
          <Grid item xs={5} container>
            <InvBarcode InvForFatora={InvForFatora} />
          </Grid>
          <Grid
            item
            container
            xs={12}
            spacing={1}
            style={{ paddingTop: 20, fontWeight: "bold", textAlign: "center" }}
          >
            <Grid item xs={5}>
              {" "}
              {" توفيع البائع" + "     " + " Salesmans Sign "}
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={5}>
              {"توفيع المستلم" + "     " + " Receivrs Sign "}
            </Grid>
            <Grid item xs={5}>
              ____________________
            </Grid>
            <Grid item xs={2}>
              <div>
                <ButtonBase>
                  {" "}
                  <img
                    src={`data:image/jpeg;base64,${found?.footer_img}`}
                    className={clas.Hedlogo}
                    alt={found.nameA}
                  />{" "}
                </ButtonBase>
              </div>
            </Grid>
            <Grid item xs={5}>
              ____________________
            </Grid>
          </Grid>
        </Grid>
        <Grid item container style={sty.cntr}>
          <Grid item container>
            <Grid item xs={12}>
              {/* {found.BillNotes} */}
              {InvForFatora.notes}
            </Grid>
            <br />
            <Grid item xs={1}></Grid>

            {/* <Grid
            item
            xs={10}
            style={{ border: "solid 0.1px", padding: 10, direction: "rtl" }}
          >
            {found.BillNotes}
          </Grid> */}
            <Grid item xs={1}></Grid>
          </Grid>
        </Grid>
      </Container>
      <Grid
        item
        container
        className={calssBody.footerContainer}
        style={{
          padding: 5,
          fontWeight: "bold",
          border: "solid 0.1px #31b835",
          direction: "ltr",
        }}
      >
        <Grid
          item
          xs={5}
          className={classHeader.rightHeader}
          style={{ textAlign: "left" }}
        >
          {/* <Typography variant="h6" gutterBottom>
              {info.nameE}
            </Typography> */}
          <div>{found.Disc1_footer_en}</div>
          <div>{found.Disc2_footer_en}</div>
          <div>{found.Disc3_footer_en}</div> <div>{found.Disc4_footer_en}</div>
        </Grid>

        <Grid item xs={2}></Grid>

        <Grid
          item
          xs={5}
          className={classHeader.rightHeader}
          style={{ textAlign: "right" }}
        >
          {/* <Typography style={styf} variant="h5" gutterBottom>
            {found.nameA}
          </Typography> */}
          <div>{found.Disc1_footer_ar}</div> <div>{found.Disc2_footer_ar}</div>
          <div>{found.Disc3_footer_ar}</div> <div>{found.Disc4_footer_ar}</div>
        </Grid>
      </Grid>
    </Fragment>
  );
}
export default Footer;
