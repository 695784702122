import React, { useState, useEffect } from "react";

import { SAdefaultValues } from "../../../../services/saleAnalysis-services";
import {
  Button,
  CardActionArea,
  CardContent,
  colors,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import CostCenterRptForm from "./CostCenterRpt-form";
import CostCenterRptTable from "./CostCenterRpt-table";
import useFetchURL from "../../../../components/Hooks/useFetchURL";
import { uri } from "../../../../help/Api";
import TotalCard from "../../../../components/TotalCard";
import { useHistory } from "react-router-dom";
import {
  useStylesHed,
  useStylesSpDial,
  useStylesUiElemment,
} from "../../../../St/comps/UseStyle";
import { insh as inshst } from "../../../../Context/InshSt";
import ScannerIcon from "@material-ui/icons/Scanner";
import KeyboardReturnSharpIcon from "@material-ui/icons/KeyboardReturnSharp";
import PrintIcon from "@material-ui/icons/Print";
import { useTranslation } from "react-i18next";
import BeatLoader from "react-spinners/BeatLoader";
import i18next from "i18next";
import { defaultParams } from "../../../../services/CostCenterRpt-services";
import axios from "axios";
import toast from "react-hot-toast";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(2),
  },
  itemW: {
    // display:'flex',
    width: "90%",
  },
  headerTitle: {
    backgroundColor: colors.amber[50],
    color: "#000",
    textAlign: "center",
    fontSize: 20,
    width: "100%",
  },
  root3: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: colors.red[300],
    direction: "rtl",
    marginTop: 5,
    padding: 5,
    // boxShadow:'box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;',
    boxShadow:
      "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;",
  },
}));

const paramsContain = (params, id) => {
  const index = params.findIndex((x) => x.id == id);
  return index != -1;
};
export default function CostCenterRpt(props) {
  const { AutoOptions, accounts, branch, setBranch } = props;
  const defaultP = {
    ...defaultParams,
    center: AutoOptions.centers[0],
  };
  const [rptParams, setRptParams] = React.useState(defaultP);
  const classes = useStyles();
  const clasui = useStylesUiElemment();
  const clasSp = useStylesSpDial();
  const { t } = useTranslation(["forms", "translation"]);
  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(false);
  const viewHandle = async () => {
    setFetching(true);
    let data = {};
    await axios
      .get(
        uri +
          `AccountReports/centerBalance?acc_code=${rptParams.accounts}
    &&dateFrom=${rptParams.dateFrom}
    &&dateTo=${rptParams.dateTo}
    &&branch=${rptParams.branch?.id}
    &&center=${rptParams.center?.id}
    &&openBalance=${paramsContain(rptParams?.parameters, 3)}
    &&oldBalance=${paramsContain(rptParams?.parameters, 4)}
    &&orderType=${-1}
    &&docType=${-1}
    &&omla=${rptParams?.omla.id}
    &&restSubType=${paramsContain(rptParams?.parameters, 5)}
    &&arbah=${paramsContain(rptParams?.parameters, 1)}
    &&mezania=${paramsContain(rptParams?.parameters, 2)}`
      )
      .then((res) => {
        setData(res.data);
        console.log(" the data is", res.data);
        setFetching(false);
      })
      .catch((err) => {
        setFetching(false);

        console.log(err);
        toast.err("يوجد خطأ في الجلب");
        setData([]);
      });
  };

  const viewSumHandle = async () => {
    setFetching(true);
    await axios
      .get(
        uri +
          `AccountReports/centerBalanceSum?acc_code=${rptParams.accounts}
    &&dateFrom=${rptParams.dateFrom}
    &&dateTo=${rptParams.dateTo}
    &&branch=${rptParams.branch?.id}
    &&center=${rptParams.center?.id}
    &&openBalance=${paramsContain(rptParams?.parameters, 3)}
    &&oldBalance=${paramsContain(rptParams?.parameters, 4)}
    &&orderType=${-1}
    &&docType=${-1}
    &&omla=${rptParams?.omla.id}
    &&restSubType=${paramsContain(rptParams?.parameters, 5)}
    &&arbah=${paramsContain(rptParams?.parameters, 1)}
    &&mezania=${paramsContain(rptParams?.parameters, 2)}`
      )
      .then((res) => {
        setData(res.data);
        console.log(" the data is", res.data);
        setFetching(false);
      })
      .catch((err) => {
        setFetching(false);

        console.log(err);
        toast.err("يوجد خطأ في الجلب");
        setData([]);
      });
  };

  const his = useHistory();
  useEffect(() => {
    // console.log(" the data is", data);
  });
  const printHandle = () => {
    const info = {
      ...rptParams,
      data: data?.CostCenterRpt,
      totals: data?.totals,
    };

    his.push({ pathname: "/", state: info });

    window.location.reload(false);
  };

  const onChangeData = (propName) => (event) => {
    setRptParams({ ...rptParams, [propName]: event.target.value });
  };
  const onChangeAutoData = (propName, newValue) => {
    setRptParams({ ...rptParams, [propName]: newValue });
  };
  return (
    <div className={classes.root}>
      <Grid container item xs={12}>
        <Typography
          size="small"
          className={classes.headerTitle}
          color="primary"
        >
          {t("تقرير تحليل مراكز التكلفة")}
        </Typography>
      </Grid>
      <Typography
        component="div"
        style={{ direction: i18next.language == "ar" ? "rtl" : "ltr" }}
      >
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
            paddingBottom: "0px",
            borderBottomColor: colors.red[300],
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
          }}
        >
          {/* <SelectTypesFilter {...{ rptParams, setRptParams }} /> */}
          <CostCenterRptForm
            {...{
              AutoOptions,
              accounts,
              rptParams,
              setRptParams,
              onChangeData,
              onChangeAutoData,
              printHandle,
              branch,
              setBranch,
            }}
          />
          <Grid
            container
            justifyContent="center"
            lg={12}
            md={12}
            sm={12}
            xs={12}
            spacing={1}
          >
            <Grid item>
              <Button
                disabled={fetching}
                variant="contained"
                startIcon={<ScannerIcon />}
                size="large"
                style={{ ...inshst.bascData.all.iconBtn, borderRadius: 0 }}
                className={[clasui.button]}
                onClick={() => viewHandle()}
              >
                {t("forms:t-detailed")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={fetching}
                variant="contained"
                startIcon={<ScannerIcon />}
                size="large"
                style={{ ...inshst.bascData.all.iconBtn, borderRadius: 0 }}
                className={[clasui.button]}
                onClick={() => viewSumHandle()}
              >
                {t("forms:t-total")}
              </Button>
            </Grid>
            {/* <Grid item>
              <Button
                disabled={fetching}
                variant="contained"
                startIcon={<PrintIcon />}
                color="primary"
                size="large"
                className={[clasui.button, clasSp.clSec]}
                style={{ ...inshst.bascData.all.iconBtn, borderRadius: 0 }}
                onClick={printHandle}
              >
                {t("forms:print")}
              </Button>
            </Grid> */}
            <Grid item>
              <Button
                variant="contained"
                startIcon={<KeyboardReturnSharpIcon />}
                color="secondary"
                size="large"
                className={[clasui.button, clasSp.clcan]}
                style={{ ...inshst.bascData.all.iconBtn, borderRadius: 0 }}
                onClick={() => his.goBack()}
              >
                {t("forms:exit")}
              </Button>
            </Grid>
          </Grid>
        </Container>
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
          }}
        >
          <Grid container justifyContent="center">
            <Grid item container justifyContent="center" xs={12}>
              <BeatLoader loading={fetching} color={colors.green[300]} />
            </Grid>
          </Grid>
          {!fetching && (
            <CostCenterRptTable
              {...{
                data: data?.costBalance ? data.costBalance : [],
              }}
            />
          )}
        </Container>
      </Typography>
      <Grid className={classes.root3} item container>
        <Grid
          item
          lg={2}
          md={2}
          sm={2}
          xs={5}

          // style={styTitle}
        >
          <CardActionArea>
            <CardContent>
              <Typography color="textSecondary" component="p">
                {/* {data.length} */}
                0.00
              </Typography>
            </CardContent>
          </CardActionArea>
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={5}>
          <CardActionArea>
            <CardContent>
              <Typography color="textSecondary" component="p">
                {/* {data.length} */}
                0.00
              </Typography>
            </CardContent>
          </CardActionArea>
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={7}>
          <CardActionArea>
            <CardContent>
              <Typography component="p">إجمالي الفترة المحددة </Typography>
            </CardContent>
          </CardActionArea>
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={5}>
          {" "}
          <CardActionArea>
            <CardContent>
              <Typography color="textSecondary" component="p">
                {/* {!fetching &&
                  data &&
                  data
                    ?.reduce((a, v) => (a = a + Number(v.sumCost_item)), 0)
                    ?.toFixed(2)} */}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Grid>

        <Grid
          item
          lg={2}
          md={2}
          sm={2}
          xs={7}

          // style={styTitle}
        >
          <CardActionArea>
            <CardContent>
              <Typography color="textSecondary" component="p"></Typography>
            </CardContent>
          </CardActionArea>
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={5}>
          {" "}
          <CardActionArea>
            <CardContent>
              <Typography color="textSecondary" component="p">
                {/* {!fetching &&
                  data &&
                  data
                    ?.reduce((a, v) => (a = a + Number(v.Outcome)), 0)
                    ?.toFixed(2)} */}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Grid>
      </Grid>
    </div>
  );
}
