import React, { useEffect, useState } from "react";
import axios from "axios";
import clsx from "clsx";
import { uri } from "../../help/Api";
import { insh, sto } from "../../Context/InshData";
import BackdropScI from "../../services/BackdropScI";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import SaveIcon from "@material-ui/icons/Save";
import CheckIcon from "@material-ui/icons/Check";
import DeleteIcon from "@material-ui/icons/Delete";
import Fab from "@material-ui/core/Fab";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ReplyIcon from "@material-ui/icons/Reply";
import PrintIcon from "@material-ui/icons/Print";
import { useHistory, useLocation } from "react-router-dom";
import MaterialTable from "material-table";
import TablePagination from "@material-ui/core/TablePagination";
import { TableIcons as tableIcons } from "../../Elemnts/TableIcons";
import Container from "@material-ui/core/Container";
import {
  useStylesContainers,
  useStylesHed,
  useStylesTF,
  useStylesTD,
  useStylesBA,
  useStylesCAB,
} from "../../St/comps/UseStyle";
import { cellS, headerS, action, stElemnt } from "../../St/comps/cStyle";
import toast, { Toaster } from "react-hot-toast";
import VatCalc from "./vat-tapkh-calc";

//=====================================================================

export default function SimpleData() {
  const loca = useLocation();
  let his = useHistory();

  const clsConts = useStylesContainers();
  const clashd = useStylesHed();
  const clasBA = useStylesBA();
  const clasTF = useStylesTF();
  const clasTD = useStylesTD();
  const clasCAB = useStylesCAB();
  const [showSc, setShowSc] = useState(true);

  const [loading, setLoading] = useState(insh.LoadAndSucc);
  const [success, setSuccess] = useState(insh.LoadAndSucc);
  const timer = React.useRef();

  const [front, setFront] = useState({ elmnts: [], col: [], title: "" });
  const [setting, setSetting] = useState({
    screen: "",
    tb: { parms: {}, autos: {} },
  });
  const [style, setStyle] = useState({});

  const buttonClassnameNew = clsx({ [clasCAB.buttonSuccessCAB]: success.new });
  const buttonClassnameSave = clsx({
    [clasCAB.buttonSuccessCAB]: success.save,
  });
  const buttonClassnameDelete = clsx({
    [clasCAB.buttonSuccessCAB]: success.delete,
  });

  const [uiElement, setUiElement] = useState({});

  const [prevent, setPrevent] = useState(insh.prevents);
  const [posts, setPosts] = useState({});
  const [gets, setGets] = useState(insh.tbname);
  const [valAuto, setvalAuto] = useState([]);
  const [valTextF, setvalTextF] = useState([]);
  const [optionAuto, setoptionAuto] = useState([]);

  const makeValElmnts = (elmnts) => {
    // alert('makeValElmnts')
    let el = { auto: [] },
      c = 0;
    el = elmnts;
    for (let m = 0; m < el.length; m++) {
      valTextF.push({ value: "" });
      for (let s = 0; s < el[m].auto.length; s++) {
        valAuto.push({ id: -1, name: "" });
        c++;
      }
    }
  };

  const createOptionAuto = (data) => {
    let dt = {
      Countries: [],
      Managements: [],
      Cities: [],
      Cities: [],
      Acts: [],
    };
    dt = data;

    try {
      if (dt.Countries.length > 0) {
        optionAuto.push(dt.Countries);
      }
    } catch (error) {}
    try {
      if (dt.Managements.length > 0) {
        optionAuto.push(dt.Managements);
      }
    } catch (error) {}
    try {
      if (dt.Cities.length > 0) {
        optionAuto.push(dt.Cities);
      }
    } catch (error) {}
    try {
      if (dt.Acts.length > 0) {
        optionAuto.push(dt.Acts);
      }
    } catch (error) {}
  };

  const inshvalAuto = () => {
    const revalAuto = valAuto;
    if (valAuto.length > 0) {
      // alert('= valAuto.length')
      for (let m = 0; m < valAuto.length; m++) {
        revalAuto[m] = { id: -1, name: "" };
      }
      setvalAuto(revalAuto);
    }
  };
  const [openModal, setOpenModel] = useState(false);
  const inshvalTextF = () => {
    const revalTextF = [...valTextF];
    for (let m = 0; m < valTextF.length; m++) {
      revalTextF[m] = { value: "" };
    }
    setvalTextF([...revalTextF]);
  };

  const reloadGets = async (screen) => {
    // alert('re_load')
    const tb = {
      wait: true,
      [screen]: true,
      parms: { tree: "sub" },
      autos: {},
    };
    // await axios.post('http://localhost:8184/api/'+'Res',tb)
    await axios
      .post(uri + "Res", tb)
      .then((res) => {
        // console.log('res.data'); console.log(res.data)
        setGets({ ...gets, resData: res.data.table.resData });
      })
      .catch((err) => {});
  };

  const loadGets = async (sett) => {
    // alert('load')
    const tb = sett.tb;
    // console.log('_tb_');console.log(tb);
    // await axios.post('http://localhost:8184/api/'+'Res',tb)
    await axios
      .post(uri + "Res", tb)
      .then((res) => {
        //  console.log('_Res data_');console.log(res.data);
        setGets(res.data.table);
        createOptionAuto(res.data.auto);
        setShowSc(res.data.wait.state);
      })
      .catch((err) => {});
  };

  const fhs = () => {
    // console.log('prev'); console.log(prevent)
    // console.log('posts'); console.log(posts)
    console.log("valText");
    console.log(valTextF);
    // console.log('valText.length'); console.log(valTextF.length)
    console.log("valAuto");
    console.log(valAuto);
    // console.log('valAuto.length'); console.log(valAuto.length)
  };

  useEffect(() => {
    setUiElement(insh.UiElement);
    makeValElmnts(loca.state.front.elmnts);
    // makeValTextF(loca.state.front.elmnts);
    setPosts(loca.state.idata);
    setFront(loca.state.front);
    setSetting(loca.state.setting);
    setStyle(loca.state.style);
    loadGets(loca.state.setting);
  }, []);

  const noThink = () => {};

  const changTField = (e, i) => {
    const reVal = valTextF;
    reVal[i] = { value: e.target.value };
    setvalTextF(reVal);
    setPosts({ ...posts, [e.target.name]: e.target.value });
  };

  const changTFieldTax = (val, i, name) => {
    const reVal = valTextF;
    reVal[i] = { value: val };
    setvalTextF(reVal);
    setPosts({ ...posts, [name]: val });
  };

  const changAutos = (i, prop, value) => {
    const reVal = valAuto;
    reVal[i] = value;
    setvalAuto(reVal);
    setPosts({ ...posts, [prop]: value.id });
  };
  const ButtonSuccess = (prop, timr, state) => {
    timer.current = window.setTimeout(() => {
      setLoading({ ...loading, [prop]: false });
      setSuccess({ ...success, [prop]: true });

      prop != "new" && state == true ? reloadGets(setting.screen) : noThink();
      setPosts(loca.state.idata);
      setPrevent(true);
      inshvalAuto();
      inshvalTextF();
    }, timr);
  };

  const ButtonLoading = (prop) => setLoading({ ...loading, [prop]: true });

  const handNewScreen = (prop, timr) => {
    ButtonLoading(prop);
    ButtonSuccess(prop, timr, false);
  };

  const will = () => {
    let ob = { msg: "قم بإضافة ", txt: "" };
    for (let m = 0; m < valTextF.length; m++)
      if (valTextF[m].value == "") ob.txt = "الحقول الفارغة";
    for (let m = 0; m < valAuto.length; m++)
      if (valAuto[m].name == "") ob.txt = "الحقول الفارغة";
    ob.msg += ob.txt;
    return ob;
  };

  const addToBS1 = () => {
    let action = will();
    console.log(action);
    console.log("items");
    //  console.log(items);
  };

  const addToBS = (screen) => {
    console.log("screesn ins", screen);
    let action = will();
    if (action.txt == "") {
      ButtonLoading("save");
      const tb = {
        [screen]: posts,
        tbstate: { [screen]: true, parms: { tname: screen } },
      };
      console.log(prevent);
      console.log(tb);

      prevent
        ? // axios.post('http://localhost:8184/api/BasicData', tb)
          axios
            .post(uri + "BasicData", tb)
            .then((res) => {
              ButtonSuccess("save", 2000, true);
              toast.success("تم الاضافة");
            })
            .catch((err) => {
              toast.error("لم تتم عملية الاضافة ");
              ButtonSuccess("save", 2000, true);
            })
        : // : axios.put('http://localhost:8184/api/BasicData', tb)
          axios
            .put(uri + "BasicData", tb)
            .then((res) => {
              ButtonSuccess("save", 2000, true);
              toast.success("تم التعديل");
            })
            .catch((err) => {});
    } else {
      alert(action.msg);
    }
  };

  const reshowItem = (row) => {
    // dt = data;
    const revalTF = [],
      revalAuto = [];

    if (setting.screen == "Units") {
      revalTF[0] = { value: row.name };
    } else if (setting.screen == "tax_groups") {
      revalTF[0] = { value: row.nameAR };
      revalTF[1] = { value: row.nameEN };
      revalTF[2] = { value: row.value };
    } else if (setting.screen == "costcenters") {
      revalTF[0] = { value: row.Name };
    } else if (setting.screen == "AccountBooks") {
      revalTF[0] = { value: row.book_id };
      revalTF[1] = { value: row.Name };
    } else if (setting.screen == "Acts") {
      revalTF[0] = { value: row.name };
      optionAuto[0].map((x, i) => {
        if (x.id == row.type) {
          revalAuto[0] = x;
        }
      });
    } else if (setting.screen == "Countries") {
      revalTF[0] = { value: row.name };
      revalTF[1] = { value: row.nationality };
    } else if (setting.screen == "Cities") {
      revalTF[0] = { value: row.name };
      optionAuto[0].map((x, i) => {
        if (x.id == row.country) revalAuto[i] = x;
      });
    } else if (setting.screen == "Areas") {
      revalTF[0] = { value: row.name };
      optionAuto[0].map((x, i) => {
        if (x.id == row.city) revalAuto[i] = x;
      });
    } else if (setting.screen == "Managements") {
      revalTF[0] = { value: row.name };
    } else if (setting.screen == "Departments") {
      revalTF[0] = { value: row.name };
      optionAuto[0].map((x, i) => {
        if (x.id == row.manag_id) revalAuto[i] = x;
      });
    } else if (setting.screen == "jobs") {
      revalTF[0] = { value: row.name };
    } else if (setting.screen == "CardTypes") {
      revalTF[0] = { value: row.nameA };
    }

    if (revalAuto.length > 0) setvalAuto([...revalAuto]);
    setvalTextF([...revalTF]);
  };

  const actionItem = (row) => {
    // alert(JSON.stringify(row))
    reshowItem(row);

    // if(setting.screen == 'AccountBooks') setPosts({...posts,ID:row.ID})
    setPosts(row);

    setPrevent(false);
    setUiElement({ ...uiElement, DeleIcon: false });
    timer.current = window.setTimeout(
      () => setUiElement({ ...uiElement, DeleIcon: true }),
      300
    );
  };

  const hRowDelete = (id) => {
    let reResData = [...gets.resData];
    reResData = reResData.filter((t) => t.id !== id);
    setGets({ ...gets, resData: reResData });
  };

  const deleteToSD = async (e, id, screen) => {
    await e.preventDefault();
    ButtonLoading("delete");
    // console.log(screen); console.log(id); console.log(e)
    // axios.delete(`http://localhost:8184/api/BasicData?id=${id}&&tb=${screen}`)
    axios
      .delete(uri + `BasicData?id=${id}&&tb=${screen}`)
      .then((res) => {
        console.log("res.data");
        console.log(res.data);
        res.data != -1
          ? hRowDelete(id)
          : alert("لا يمكنك الحذف توجد عمليات مرتبطه");
        ButtonSuccess("delete", 500, true);
      })
      .catch((err) => {
        ButtonSuccess("delete", 500, false);
        alert("لم يتم الحذف");
      });
  };

  const backHome = () => {
    his.push({ pathname: "/" }); /*console.log('stooping')*/
  };
  const back = () => his.goBack();

  const styheader = {
    textAlign: "center",
    backgroundColor: "#fff",
    height: "100vh",
    direction: "rtl",
  };

  const frontHeader = () => {
    return (
      <Grid item xs={12}>
        <Chip
          size="small"
          className={clashd.ChipHdSale}
          label={front.title}
          color="primary"
        />
      </Grid>
    );
  };

  const frontCenterUp = () => {
    return (
      <Grid item container lg={12} md={12} className={[clsConts.rootup]}>
        <Grid
          item
          container
          spacing={1}
          xs={12}
          className={[clsConts.subUp, clsConts.subUpC]}
        >
          {front.elmnts.map((m) => (
            <React.Fragment>
              <Grid item xs={m.xs}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  id={m.prop}
                  label={m.lbl}
                  className={clasTF.rootSale}
                  InputProps={{ className: clasTD.input }}
                  type="text"
                  name={m.prop}
                  value={valTextF[m.tin].value}
                  onChange={(event) => changTField(event, m.tin)}
                />
              </Grid>

              {m.auto.map((s) => (
                <Grid item xs={s.xs}>
                  <Autocomplete // classes={clasAo}
                    value={valAuto[s.ain]}
                    onChange={(event, newValue) => {
                      changAutos(s.ain, s.prop, newValue);
                    }}
                    id="controllable-states-dem"
                    size="small"
                    options={optionAuto[s.ain]}
                    getOptionLabel={(option) =>
                      typeof option === "string" ? option : option.name
                    }
                    //style={{  }}  // disabled={}
                    //disabled={statu Dis.pricePay}
                    disableClearable
                    renderInput={(params) => (
                      <TextField
                        id="valuePricePay"
                        {...params}
                        label={s.lbl}
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                </Grid>
              ))}
            </React.Fragment>
          ))}
        </Grid>
        {setting.screen == "tax_groups" && (
          <Grid item xs={12}>
            <VatCalc
              open={openModal}
              onClose={(val) => {
                changTFieldTax(val, 2, "value");
                setOpenModel(false);
              }}
            />
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => setOpenModel(true)}
            >
              حساب ضريبة شاملة رسوم التبغ
            </Button>
          </Grid>
        )}
      </Grid>
    );
  };

  const frontCenterDown = () => {
    return (
      <Grid item container lg={12} md={12} className={[clsConts.rootdown]}>
        <Grid
          item
          container
          xs={12}
          className={[clsConts.subDown, clsConts.subDownC]}
        >
          <Grid item xs={12}>
            <MaterialTable
              style={{ boxShadow: "none" }}
              title=""
              // variant="outlined"
              options={{
                pageSize: 7,
                //showTitle:false,
                toolbar: false,
                sorting: false,
                showTitle: false,
                paging: false,

                headerStyle: headerS,
                cellStyle: cellS,

                //tableLayout: 'fixed',
                //  maxBodyHeight: '50vh',
                maxBodyHeight: "65vh",
                minBodyHeight: "65vh",
              }}
              localization={{
                body: {
                  emptyDataSourceMessage: (
                    <h3
                      style={{
                        marginTop: "20vh",
                        border: "solid 0px",
                        boxShadow: "none",
                      }}
                    ></h3>
                  ),
                },
              }}
              //  columns={[BtnTableDelete(),BtnTableEdit(),...front.col]}
              columns={front.col}
              data={gets.resData}
              icons={tableIcons}
              onRowClick={(event, rowData) => {
                // showData(rowData);
                actionItem(rowData);
                event.stopPropagation();
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const frontAppBar = () => {
    return (
      <React.Fragment>
        <CssBaseline />
        <AppBar
          position="fixed"
          color="primary"
          style={{ ...style.bgImg, ...stElemnt.all.appBarBA, direction: "ltr" }}
        >
          <Toolbar className={clasBA.ToolbarBA} variant="dense">
            <Hidden smUp={["sm"]}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={() => backHome()}
              >
                <CloseIcon />
              </IconButton>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={() => back()}
              >
                <ReplyIcon />
              </IconButton>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={() => handNewScreen("new", 1000)}
              >
                <PrintIcon />
              </IconButton>
            </Hidden>

            <Hidden only={["xs"]}>
              <Button
                style={{ color: "#fff" }}
                onClick={() => backHome()}
                startIcon={<CloseIcon />}
              >
                خروج
              </Button>
              <Button
                style={{ color: "#fff" }}
                onClick={() => back()}
                startIcon={<ReplyIcon />}
              >
                رجوع
              </Button>
              {/* <Button style={{color:'#fff'}} onClick={()=>backHome()} startIcon={<PrintIcon />}>طباعة كبير</Button> */}
            </Hidden>

            <div className={clasBA.growBA} />

            <Hidden smUp={["sm"]}>
              {/* ico left  */}
              <div className={clasCAB.wrapperCAB}>
                <Fab
                  size="medium"
                  color="primary" /*disabled={permis.Sa}*/ //  onClick={toWhere}
                  style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                  className={buttonClassnameSave}
                >
                  {success.save ? <CheckIcon /> : <SaveIcon />}
                </Fab>
                {loading.save && (
                  <CircularProgress
                    size={56}
                    className={clasCAB.fabProgressCAB}
                  />
                )}
              </div>

              <div className={clasCAB.wrapperCAB}>
                <Fab
                  size="medium"
                  color="primary" /*disabled={permis.Sa}*/ //  onClick={toWhere}
                  style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                  className={buttonClassnameSave}
                >
                  {success.save ? <CheckIcon /> : <AddIcon />}
                </Fab>
                {loading.save && (
                  <CircularProgress
                    size={56}
                    className={clasCAB.fabProgressCAB}
                  />
                )}
              </div>
            </Hidden>

            <Hidden only={["xs"]}>
              {uiElement.DeleIcon == true ? (
                <div className={clasCAB.wrapperCAB}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={buttonClassnameDelete}
                    startIcon={<DeleteIcon />}
                    style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                    disabled={loading.delete}
                    onClick={(e) => deleteToSD(e, posts.id, setting.screen)}
                  >
                    حـذف
                  </Button>
                  {loading.delete && (
                    <CircularProgress
                      size={24}
                      className={clasCAB.buttonProgressCAB}
                    />
                  )}
                </div>
              ) : null}

              <div className={clasCAB.wrapperCAB}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                  className={buttonClassnameSave}
                  disabled={loading.save}
                  onClick={() => addToBS(setting.screen)}
                  startIcon={<SaveIcon />}
                >
                  {" "}
                  {"حـفـظ"}{" "}
                </Button>
                {loading.save && (
                  <CircularProgress
                    size={24}
                    className={clasCAB.buttonProgressCAB}
                  />
                )}
              </div>

              <div className={clasCAB.wrapperCAB}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{ ...style.iconBtn, ...stElemnt.all.iconBtn }}
                  className={buttonClassnameNew}
                  disabled={loading.new}
                  onClick={() => handNewScreen("new", 1000)}
                  startIcon={<AddIcon />}
                >
                  {" "}
                  {"جـديـد"}{" "}
                </Button>
                {loading.new && (
                  <CircularProgress
                    size={24}
                    className={clasCAB.buttonProgressCAB}
                  />
                )}
              </div>

              {/* <div className={clasCAB.wrapperCAB}>
      <Button variant="contained" color="primary" size="large"
        style={{...style.iconBtn,...stElemnt.all.iconBtn}} onClick={fhs}> {'فحص'} </Button>
    </div> */}
            </Hidden>

            {/* </div> */}
          </Toolbar>
        </AppBar>
      </React.Fragment>
    );
  };

  return showSc ? (
    <BackdropScI />
  ) : (
    <React.Fragment>
      <Toaster />
      <CssBaseline />
      <Container maxWidth="xs">
        <Typography component="div" style={styheader}>
          <Grid item container>
            {/* S front Header */} {frontHeader()} {/* E front Header */}
            {/* S front Center */}
            <Grid container xs={12}>
              {/* S front up C */} {frontCenterUp()} {/* E front up C */}
              {/* S front down C */} {frontCenterDown()} {/* E front down C */}
            </Grid>
            {/* E front Center */}
          </Grid>
          {/* S front fixed */} {frontAppBar()} {/* E front fixes */}
        </Typography>
      </Container>
    </React.Fragment>
  );
}
