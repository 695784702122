import _ from "lodash";

export const selectTypes = [
  { Form_id: 40002, id: 1, name: "sales", label: "المبيعات" },
  { Form_id: 40002, id: 2, name: "POS", label: "نقاط البيع" },

  { Form_id: 10004, id: 3, name: "purches", label: "البيانات الأساسية" },
];

export const defaultValues = {
  user: { id: 0, name: null },
  user_id: 0,
  Form_id: 0,
  IS_New: false,
  Is_Save: false,
  IS_Delete: false,
  IS_Search: false,
  IS_Print: false,
  Automatic_Insert: false,
  PrintAfter_Save: false,
  SaleDiscPers: 0,
  SaleDiscValue: 0,
  purchAutomatic_Insert: false,
  PurchPrintAfter_Save: false,
  purchDiscPers: 0,
  purchDiscValue: 0,
  HidePurchPrice: false,
  ShowQtyScreen: false,
  SaleInMinus: false,
  invtype: 0,
  NoRepeatItem: 0,
  UpdateSalePrice: 0,
  UpdateSaleDate: 0,
  SalePointOnly: 0,
  StockMinus: 0,
  smallPrinter: "",
  largePrinter: "",
  costWithVat: 0,
  saleVatUpdate: 0,
  purchVatUpdate: 0,
  Disc_saleOnItem: 0,
  Disc_saleOnBills: 0,
  update_price_first_stock: 0,
  update_price_pursh_bill: 0,
  show_ItemExpired_Alert: 0,
  allow_salePrice_zero: 0,
  modify_documents_no: 0,
  add_new_after_save: 0,
  rest_show_image: 0,
  smallPrinter_custom: "",
  largePrinter_custom: "",
  custom_detail: "",
  no_copies: 0,
  IS_Update: false,
  Return_whith_bill_no: 0,
  empty_paid: 0,
  inv_post_unlock: 0,
  allow_zero_cost: 0,
  add_new_after_open: 0,
  custom_smallPrinter: 0,
  custom_largePrinter: 0,
  cacel_double_enter: 0,
  cancel_move_barcode: 0,
  IS_Preview: false,
  update_price_currencies: 0,
  custom2_smallPrinter: 0,
  smallPrinter_custome2: "",
  custom2_largerPrinter: 0,
  largePrinter_custom2: "",
  apply_discount: 0,
  allow_discount: 0,
  allow_return_Without_bill: 0,
  allow_replace_Without_bil: 0,
  PurchInMinus: 0,
  update_price_sale_bill: 0,
  UpdatePurchPrice: 0,
  UpdatePurchDate: false,
  use_price_without_vat: 0,
  usePriceBeforeVat: 0,
  show_unit_on_bills: 0,
  show_price_on_bills: 0,
  is_saleprice_less_purch: 0,
  type_sale_price: 0,
  direct_print: 0,
  hide_item_balance: 0,
  disable_type_sale_price: 0,
  search_enter: 0,
  to_unit: 0,
  add_cover_letter: 0,
  sale_no_less_than_purch: 0,
  Purch_than_less_Item: 0,
};

export const prepateBasicPerms = (values) => {
  return {
    ...values,
    NoRepeatItem: values?.NoRepeatItem ? 1 : 0,
    costWithVat: values?.costWithVat ? 1 : 0,
    update_price_first_stock: values?.update_price_first_stock ? 1 : 0,
    update_price_pursh_bill: values?.update_price_pursh_bill ? 1 : 0,
    show_ItemExpired_Alert: values?.show_ItemExpired_Alert ? 1 : 0,
    update_price_currencies: values?.update_price_currencies ? 1 : 0,
    show_unit_on_bills: values.show_unit_on_bills ? 1 : 0,
    show_price_on_bills: values?.show_price_on_bills ? 1 : 0,
    is_saleprice_less_purch: values?.is_saleprice_less_purch ? 1 : 0,
    usePriceBeforVat: values?.usePriceBeforVat ? 1 : 0,
    hide_item_balance: values?.hide_item_balance ? 1 : 0,
    disable_type_sale_price: values?.disable_type_sale_price ? 1 : 0,
    UpdateSalePrice: values?.UpdateSalePrice ? 1 : 0,
    sale_no_less_than_purch: values?.sale_no_less_than_purch ? 1 : 0,
    UpdateSaleDate: values?.UpdateSaleDate ? 1 : 0,
    saleVatUpdate: values?.saleVatUpdate ? 1 : 0,
    allow_salePrice_zero: values?.allow_salePrice_zero ? 1 : 0,
    allow_return_Without_bill: values?.allow_return_Without_bill ? 1 : 0,
    allow_replace_Without_bill: values?.allow_replace_Without_bill ? 1 : 0,
    inv_post_unlock: values?.inv_post_unlock ? 1 : 0,
  };
};

// let subForm = [
//   {
//     id: 1,
//     name: "حفظ",
//   },
//   { id: 2, name: "تعديل" },
// ];
const getSubForm = (frm) => {
  let subForm = [];
  if (frm.IS_New_Visible)
    subForm.push({ id: frm.id.toString() + "-1", name: "اضافة" });
  if (frm.IS_Save_Visible)
    subForm.push({ id: frm.id.toString() + "-2", name: "حفظ" });
  if (frm.IS_Delete_Visible)
    subForm.push({ id: frm.id.toString() + "-3", name: "حذف" });
  if (frm.IS_Search_Visible)
    subForm.push({ id: frm.id.toString() + "-4", name: "بحث" });
  if (frm.IS_Print_Visible)
    subForm.push({ id: frm.id.toString() + "-5", name: "طباعة" });
  if (frm.IS_Update_Visible)
    subForm.push({ id: frm.id.toString() + "-6", name: "تعديل" });
  if (frm.IS_Preview_Visible)
    subForm.push({ id: frm.id.toString() + "-7", name: "معاينة" });

  return subForm;
};
export const convertToTreeData = (pData) => {
  const res = [];
  for (let frm of pData) {
    if (frm.IS_Parent) {
      res.push(frm);
    } else {
      const parent = _.find(pData, { id: parseInt(frm?.Parent_id) });
      if (!parent?.hasOwnProperty("children"))
        _.assign(parent, { children: [] });
      _.assign(frm, { children: [] });
      frm.children = getSubForm(frm);
      parent?.children.push(frm);
    }
    _.assign(frm, { label: frm.name });
  }
  return res;
};
export const getCheckKeys = (data) => {
  const res = [];
  for (let frm of data) {
    if (frm.IS_New) res.push(frm.Form_id.toString() + "-1");
    if (frm.IS_Save) res.push(frm.Form_id.toString() + "-2");
    if (frm.IS_Delete) res.push(frm.Form_id.toString() + "-3");
    if (frm.IS_Search) res.push(frm.Form_id.toString() + "-4");
    if (frm.IS_Print) res.push(frm.Form_id.toString() + "-5");
    if (frm.IS_Update) res.push(frm.Form_id.toString() + "-6");
    if (frm.IS_Preview) res.push(frm.Form_id.toString() + "-7");
  }
  return res;
};

export const prepareUserPerms = (forms, checkedKeys, userId) => {
  let rCheckData = checkedKeys
    ?.filter((ch) => ch.toString().includes("-"))
    ?.map((f) => {
      const strs = f?.split("-");
      return strs[0];
    });

  let nData = _.uniq(rCheckData).map((item) => {
    return {
      Form_id: item,
      user_id: userId,
      IS_New: _.includes(checkedKeys, item.toString() + "-1"),
      IS_Save: _.includes(checkedKeys, item.toString() + "-2"),
      IS_Delete: _.includes(checkedKeys, item.toString() + "-3"),
      IS_Search: _.includes(checkedKeys, item.toString() + "-4"),
      IS_Print: _.includes(checkedKeys, item.toString() + "-5"),
      IS_Update: _.includes(checkedKeys, item.toString() + "-6"),
      IS_Preview: _.includes(checkedKeys, item.toString() + "-7"),
    };
  });

  return nData;
};
