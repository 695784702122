import axios from "axios";
import { uri } from "../help/Api";

export const URL = uri + "BasicData";

export function list() {
  return axios.get(URL);
}
 

export function create(data) {
  return axios.post(URL, data);
}

export function update(data) {
  return axios.put(URL, data);
}
export function removeSafe(id) {
  return axios.delete(URL + `?id=${id}&&tb=${"Safes"}`);
}
export function removeStock(id) {
  return axios.delete(URL + `?id=${id}&&tb=${"Stocks"}`);
}
export function removeCost(id) {
  return axios.delete(URL + `?id=${id}&&tb=${"costcenters"}`);
}

export default { list, create, update, removeSafe, removeStock, removeCost };
