import React, {useEffect, useState, Fragment} from 'react';
import axios from 'axios';
import clsx from 'clsx';
import {uri}  from '../../help/Api';
import {insh,sto} from '../../Context/InshData';
import BackdropScI from '../../services/BackdropScI';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import SaveIcon from '@material-ui/icons/Save';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
// import Delete from '@material-ui/icons/Delete';
import Fab from '@material-ui/core/Fab';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PostAddIcon from '@material-ui/icons/PostAdd';
import ReplyIcon from '@material-ui/icons/Reply';
import PrintIcon from '@material-ui/icons/Print';
import { useHistory, useLocation } from 'react-router-dom';
import MaterialTable from "material-table";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import {TableIcons as tableIcons} from "../../Elemnts/TableIcons";
import Container from '@material-ui/core/Container';
import {useStylesContainers,useStylesHed,useStylesAuto,useStylesTF,useStylesTD,
  useStylesBA,useStylesLbl,useStylesUiElemment,useStylesCAB,useStylesSpDial,
  useStylesDPM,} from '../../St/comps/UseStyle';
import {cellS,headerS,stElemnt} from '../../St/comps/cStyle'

//=====================================================================
function TransitionUp(props) { return <Slide {...props} direction="up" />; }

export default function NormalData(){
  const loca = useLocation();
  let his = useHistory();
  // let postsEmps = []

  const clsConts = useStylesContainers();
  const clashd = useStylesHed();
  const clasBA = useStylesBA();
  const clasAo = useStylesAuto();
  const clasTF = useStylesTF();
  const clasTD = useStylesTD();
  const claslbl = useStylesLbl();
  const clasCAB = useStylesCAB();
  const clasui = useStylesUiElemment();
  const clasSp = useStylesSpDial();
  const clasPM = useStylesDPM();

  const [transition, setTransition] = React.useState(undefined);

  const [showSc, setShowSc] = useState(true);
  
  const [loading, setLoading] = useState(insh.LoadAndSucc);
  const [success, setSuccess] = useState(insh.LoadAndSucc);
  const timer = React.useRef();

  const [front, setFront] = useState({title:'',titleD:'', elmnts:[], col:[], show:{}});
  const [setting, setSetting] = useState({screen:'', moretb:'', tb:{ parms:{},autos:{},}, });
  const [style, setStyle] = useState({});
  
  const buttonClassnameNew = clsx({ [clasCAB.buttonSuccessCAB]: success.new, });
  const buttonClassnameSave = clsx({ [clasCAB.buttonSuccessCAB]: success.save, });
  const buttonClassnameDelete = clsx({ [clasCAB.buttonSuccessCAB]: success.delete, });

  const [posts, setPosts] = useState({});
  const [postsEmps, setPostsEmps] = useState([]);
// let  = []

const [gets, setGets] = useState(insh.tbname);
const [valAuto, setvalAuto] = useState([]);
const [valTextF, setvalTextF] = useState([]);
const [optionAuto, setoptionAuto] = useState([]);

const makeValElmnts = (elmnts) => {
  let el = {auto:[]},c = 0; el = elmnts;
    for(let m = 0; m < el.length; m++) 
    {
      valTextF.push({value:''})
      for(let s = 0; s < el[m].auto.length; s++)
      { valAuto.push({id:-1,name:''}); c++; }
    }
}

const createOptionAuto = (data) => {
  // console.log('__data__'); console.log(data);
  let dt = {Managements:[],Countries:[],Cities:[],Areas:[],Acts:[],Employees:[],
    Branches:[],States:[],AccountType:[],equalOmlat:[],stateOmlat:[]}; 
  dt = data;
  
  try { if(dt.AccountType.length > 0) {optionAuto.push(dt.AccountType)} } catch (error) {}
  try { if(dt.Countries.length > 0) {optionAuto.push(dt.Countries)} } catch (error) {}
  try { if(dt.Managements.length > 0) {optionAuto.push(dt.Managements)} } catch (error) {}
  try { if(dt.Cities.length > 0) {optionAuto.push(dt.Cities)} } catch (error) {}
  try { if(dt.Areas.length > 0) {optionAuto.push(dt.Areas)} } catch (error) {}
  try { if(dt.Acts.length > 0) {optionAuto.push(dt.Acts)} } catch (error) {}
  try { if(dt.Branches.length > 0) {optionAuto.push(dt.Branches); } } catch (error) {}
  try { if(dt.States.length > 0) {optionAuto.push(dt.States); } } catch (error) {}
  try { if(dt.Employees.length > 0) {optionAuto.push(dt.Employees)} } catch (error) {}
  try { if(dt.equalOmlat.length > 0) {optionAuto.push(dt.equalOmlat)} } catch (error) {}
  try { if(dt.stateOmlat.length > 0) {optionAuto.push(dt.stateOmlat)} } catch (error) {}
}

const inshValueAuto = () => {
  const revalAuto = valAuto;
  for(let m = 0; m < valAuto.length; m++) { revalAuto[m] = {id:-1,name:''}; }
    setvalAuto(revalAuto);
}

const inshvalTextF = () => {
  const reVal = valTextF;
  for(let m = 0; m < valTextF.length; m++) { valTextF[m] = {value:''}; }
    setvalTextF(reVal);
}

const reloadGets = async (screen) => {
  const tb = { wait:true, [screen]:true, parms:{tree:"sub"}, autos:{}, }
  await axios.post(uri+'Res',tb)
  .then(res => {
    // console.log('res.data.table.resData')
    // console.log(res.data.table.resData)
    setGets({...gets, resData:res.data.table.resData})
    
    }).catch(err => {})
}

const changFirst = (ind,val,prop) => {
  const reVal = valTextF; reVal[ind] = {value:val}; setvalTextF(reVal);
  setPosts({ ...posts, [prop]: val });
}

const loadGets = async (sett) => {
  const tb = sett.tb
  // console.log('_tb_');console.log(tb);
  // await axios.post('http://localhost:8184/api/Res',tb)
  await axios.post(uri+'Res',tb)
      .then(res => {
         console.log('_Res data_');console.log(res.data);
        setGets(res.data.table); 
        createOptionAuto(res.data.auto); 
        setShowSc(res.data.wait.state);
      
        sett.screen == 'Banks' ? changFirst(2,'32156','acc_no') : noFunc()


      })
        // console.log('sett.screen')
        // console.log(sett.screen)
        // if(sett.screen == 'Banks' ) changFirst(2,'32156','acc_no')
        // sett.screen == 'Banks' ? changFirst(2,'32156','acc_no') : noFunc()
      .catch(err => { })
}

const fhs = () => {
  // console.log('posts'); console.log(posts)
  // console.log('valText F'); console.log(valTextF)
  // console.log('valAuto'); console.log(valAuto)
  // console.log('postsEmps'); console.log(postsEmps)
}
      
useEffect( () => {
  makeValElmnts(loca.state.front.elmnts);
  // makeValTextF(loca.state.front.elmnts);
  setPosts(loca.state.idata);
  setFront(loca.state.front);
  setSetting(loca.state.setting);
  setStyle(loca.state.style);
  loadGets(loca.state.setting);
}, []);

const noFunc = () => { }
const noElmnt = () => {return (<></>)}

const changTField = (e,i) => {
    const reVal = valTextF; reVal[i] = {value:e.target.value}; setvalTextF(reVal);
    setPosts({ ...posts, [e.target.name]: e.target.value });
}

const changAutos = (i,prop,value) =>
{
  const reVal = valAuto; reVal[i] = value; setvalAuto(reVal);
  setPosts({ ...posts, [prop]: value.id });
}

const changAutosMulti = (e,value) =>
  {
  // console.log(e.target.outerText)
  // console.log(e)
  // console.log('e.target.__reactFiber$zutnc1pzg6f.index')
  // console.log(e.target.__reactFiber$zutnc1pzg6f.index)
  // console.log(value[e.target.__reactFiber$zutnc1pzg6f.index].id)
  
  // let obSto ={stock_id:null,emp_id:value.id}
  // let obSaf ={safe_id:0,emp_id:value.id}
  // // postsEmps.push(obSaf)
  // if(e.target.outerText != undefined)
  // {setting.screen == 'Safes'? postsEmps.push(obSaf) : postsEmps.push(obSto)}
  // else postsEmps.pop(value.id)
  
  //   safe_id
  // emp_id

  // stock_id
  // emp_id
    // postsEmps = value
}

 const ButtonSuccess = (prop,timr) => {
    timer.current = window.setTimeout(() => { 
     setLoading({...loading, [prop]:false}); setSuccess({...success, [prop]:true});
    prop != 'new' ? reloadGets(setting.screen) : noFunc();
    setPosts(loca.state.idata);
    inshValueAuto();
    inshvalTextF();
    openPMD == true ? setOpenPMD(!openPMD) : noElmnt();
  }, timr); 
};

const ButtonLoading = (prop) => setLoading({...loading, [prop]:true}); 

const will = () => {
  let ob={msg:'قم بإضافة ',txt:''};
  for (let m = 0; m < valTextF.length; m++)
   if(valTextF[m].value == '') ob.txt='الحقول الفارغة';
  for (let m = 0; m < valAuto.length; m++)
   if(valAuto[m].name == '') ob.txt='الحقول الفارغة';
  ob.msg+=ob.txt; return ob;
}

const addToBS = (screen) => {

  let action = will();
  if(action.txt == '')
  {
    ButtonLoading('save');
    const tb = {
        [screen]:posts,
        tbstate:{ [screen]:true, parms:{tname:screen} }
      }
      console.log('tb'); console.log(tb); console.log('tb')
      // axios.post('http://localhost:8184/api/BasicData', tb)
      axios.post(uri+'BasicData', tb)
      .then(res => {
           ButtonSuccess('save',2000)
      }).catch(err => {  })
  }
  else { alert(action.msg); }
}

const actionItem = (row,state) => {

}

const hRowDelete = (id) => {
  let reResData = [...gets.resData];
    reResData = reResData.filter(t => t.id !== id);
  setGets({...gets,resData:reResData});
};

const deleteTos = async (e,id,screen) => {
  await e.preventDefault();
  if(setting.screen != 'Branches' ){
        axios.delete(uri+`BasicData?id=${id}&&tb=${screen}`)
    .then(res => { hRowDelete(id)})
    .catch(err => { alert('لم يتم الحذف') })
  }
    // axios.delete(`http://localhost:8184/api/BasicData?id=${id}&&tb=${screen}`)

}

  
// const showData = (row) => { 
//   console.log(row)
//   setPosts({...posts,id:row.id})
// }

const handNewScreen = (prop,timr) => {
  ButtonLoading(prop); 
  ButtonSuccess(prop,timr); 
}

const backHome = () => { his.push({ pathname: '/', }); /*console.log('stooping')*/ }
const back = () => his.goBack(); 

  const styheader = 
  {textAlign:'center', backgroundColor: '#fff', height: '100vh',direction:'rtl' }

  /*
  const frontLeftUp = () => { return(
    <Grid item container lg={12} md={12} className={[clsConts.rootup,]}>
    <Grid item container xs={12} className={[clsConts.subUp,clsConts.subUpL,]}>
      <Grid item lg={8} md={6} sm={4} xs={6}>
      <TextField fullWidth variant="outlined" size="small" id="td" label="بـحـث"
      className={clasTF.rootSale} InputProps={{ className: clasTD.input }}
      type="text" disabled
      name="td" value={''} onChange={changTField('dateTo')}
      /></Grid> 
      <Grid item lg={4} md={6} sm={4} xs={6}>
      <Autocomplete classes={clasAo} disabled
                value={valueAutos.groupSearch} 
                labelPlacement=""
                onChange={(event, newValue) => { changAutos('groupSearch',newValue) }}
                id="controllable-states-demo" size="small"
                options={gets.groups}
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                // style={{  }}
                disableClearable
                renderInput={(params) => <TextField {...params} label="المجموعة" variant="outlined" size="small" 
                InputLabelProps={{className:claslbl.clrSale}} />}
              />
      </Grid> 
    </Grid>
    </Grid>
  )}
*/
  
const frontHeader = () => { return(
  <Grid item xs={12}>
  <Chip size="small" className={clashd.ChipHdSale} label={front.title} color="primary" />
  </Grid>
)}

const frontLeftDown = () => { return(
    <Grid item container lg={12} md={12} className={[clsConts.rootdown,]}>
    <Grid item container xs={12} className={[clsConts.subDown,clsConts.subDownL,]}>
      <Grid item xs={12}>
           <MaterialTable style={{boxShadow:'none'}}
                title='بحث عن الفواتير'
            // variant="outlined"
              options={{
              pageSize: 7,
              //showTitle:false,
              toolbar: false,
              sorting: false,
              showTitle: false,
              actionsColumnIndex: -1,
              // selection: true,
     // showTextRowsSelected: false,
              
               // act ionsCellStyle: -1,
                paging: false,
           
                headerStyle: headerS,
                cellStyle: cellS,

               //tableLayout: 'fixed',
              //  maxBodyHeight: '50vh',
              maxBodyHeight: '70vh',
              minBodyHeight: '70vh',
             }}
       
             localization={{
              header: {actions: 'حذف'},
              body:{
                emptyDataSourceMessage:<h3 
                style={{ marginTop:'20vh', border:'solid 0px', boxShadow:'none'}}
                ></h3> } }}

            actions={[
              {
                // icon: "Add",
                icon: () => <DeleteIcon style={{color:'red'}} />,
                // icon: () => <Delete />,
                // icon: () => <Hidden smUp={['sm']}><DeleteIcon /></Hidden>,
                // disabled:true,
                iconProps: {
                   style: { justifyContent: 'center', fontSize: "60px", color: "red" } 
                  },
                tooltip: "حذف",
                onClick: (e,rowData) => deleteTos(e,rowData.id,setting.screen)
              }
            ]}

             columns={front.col}
             data={gets.resData}
             icons={tableIcons}
             
             onRowClick={(event, rowData) => {
              // actionItem(rowData);
              event.stopPropagation();
            }}/>
      </Grid>    
    </Grid>
    </Grid>
  )}

const frontRightUp = () => { return(
    <Grid item container lg={12} md={12} className={[clsConts.rootup,]}>
    <Grid item container spacing={1} xs={12} className={[clsConts.subUp,
     setting.screen == 'Branches' ? clsConts.subUpRBranch : null,
     setting.screen == 'groups' ? clsConts.subUpRgroups : null,
     setting.screen == 'Safes' ? clsConts.subUpRSafes : null,
     setting.screen == 'Stocks' ? clsConts.subUpRStocks : null,
     setting.screen == 'Banks' ? clsConts.subUpRBanks : null,
     setting.screen == 'omlat' ? clsConts.subUpRomlat : null,
    ]}>
    {
        front.elmnts.map(m =>
          <React.Fragment>

          <Grid item lg={m.lg} md={m.md} sm={m.sm} xs={m.xs}>
          <TextField fullWidth variant="outlined" size="small" id={m.prop} label={m.lbl}
          className={clasTF.rootSale} 
          InputProps={{ className: clasTD.input,readOnly: m.chang, }}
          type="text" name={m.prop} value={valTextF[m.tin].value} 
          onChange={(event)=>changTField(event,m.tin)}
          // onClick={()=>actionItem('',false)}
           /></Grid> 

        { m.auto.map(s => 
        <Grid item lg={s.lg} md={s.md} sm={s.sm} xs={s.xs} >
        <Autocomplete // classes={clasAo}
            value={valAuto[s.ain]}
            onChange={(event, newValue) => { changAutos(s.ain,s.prop,newValue) }}
            id="controllable-states-dem" size="small"
            options={optionAuto[s.ain]}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
            //style={{  }}  // disabled={}
            //disabled={statu Dis.pricePay}
            disableClearable
            renderInput={(params) => <TextField id="valuePricePay" {...params} label={s.lbl} 
            variant="outlined" size="small" 
                  />}
                />
        </Grid>
        )}
          </React.Fragment>
        )}
    </Grid></Grid>
  )}

const frontRightDown = () => { return (
    <Grid item container lg={12} md={12} className={[clsConts.rootdown,]}>
    <Grid item container xs={12} className={[clsConts.subDown,clsConts.subDownR,
     setting.screen == 'Safes' ? clsConts.subDownRSafes : null,
     setting.screen == 'Stocks' ? clsConts.subDownRStocks : null,
    ]}>
    <Grid item lg={12} md={12} sm={12} xs={12} 
      style={{paddingBottom:'2rem',textAlign:'center'}}>
      {'مسئولي '+front.titleD}
    </Grid>

    <Grid item lg={12} md={12} sm={12} xs={12}>
        <Autocomplete multiple // classes={clasAo}
            // value={valAuto[2]}
            onChange={(event, newValue) => { changAutosMulti(event, newValue) }}
            id="controllable-states-dem" size="small"
            options={optionAuto[2]}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
            style={{ direction:'ltr' }}  // disabled={}
            //disabled={statu Dis.pricePay}
            disableClearable
            renderInput={(params) => <TextField id="valuePricePay" {...params} 
            label={'الموظف المسئول'} 
            variant="outlined" size="small" />}
                />
        </Grid>

    </Grid>
    </Grid>
  )}
    
const handOpenDw = (Transition) => {
    setTransition(() => Transition);
    setOpenPMD(true);
  };

const frontAppBar = () => { return(
    <React.Fragment>
    <CssBaseline />
    <AppBar position="fixed" color="primary"
     style={{...style.bgImg,...stElemnt.all.appBarBA,direction:'ltr'}}
    >
      <Toolbar className={clasBA.ToolbarBA} variant="dense">

      <Hidden smUp={['sm']}>
        <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={()=>backHome()}>
          <CloseIcon />
        </IconButton>
        <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={()=>back()}>
          <ReplyIcon />
        </IconButton>
        <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={()=>handNewScreen('new',1000)}>
          <PrintIcon />
        </IconButton>
      </Hidden>

      <Hidden only={['xs']}>
        <Button style={{color:'#fff'}} onClick={()=>backHome()} startIcon={<CloseIcon />} >خروج</Button>
        <Button style={{color:'#fff'}} onClick={()=>back()} startIcon={<ReplyIcon />} >رجوع</Button>
        {/* <Button style={{color:'#fff'}} onClick={()=>backHome()} startIcon={<PrintIcon />}>طباعة كبير</Button> */}
      </Hidden>

      <Hidden mdUp={['md']}>
          <Fab color="primary" aria-label="add" 
          style={{...style.fabButtonBA,...stElemnt.all.fabButtonBA}}
          onClick={() => handOpenDw(TransitionUp)}>
          <PostAddIcon /> </Fab>
        </Hidden>

        <div className={clasBA.growBA} />

    <Hidden smDown={['sm']}>
  
    {frontBtnAction()}

    {/* <div className={clasCAB.wrapperCAB}>
      <Button variant="contained" color="primary" size="large"
        style={{...style.iconBtn,...stElemnt.all.iconBtn}} onClick={fhs}> {'فحص'} </Button>
    </div> */}
    
    </Hidden>
  {/* </div> */}

      </Toolbar>
    </AppBar>
  </React.Fragment>
  )}

const frontBtnAction = () => {return(
   <Fragment>
  <div className={clasCAB.wrapperCAB}>
  <Button variant="contained" color="primary" size="large"
    style={{...style.iconBtn,...stElemnt.all.iconBtn}}
    className={buttonClassnameSave} disabled={loading.save} 
    onClick={()=>addToBS(setting.screen)}
      startIcon={<SaveIcon />}> {'حـفـظ'} </Button>
  {loading.save && <CircularProgress size={24} className={clasCAB.buttonProgressCAB} />}
</div>

<div className={clasCAB.wrapperCAB}>
  <Button variant="contained" color="primary" size="large"
    style={{...style.iconBtn,...stElemnt.all.iconBtn}}
    className={buttonClassnameNew} disabled={loading.new} 
    onClick={()=>handNewScreen('new',1000)}
      startIcon={<AddIcon />}> {'جـديـد'} </Button>
  {loading.new && <CircularProgress size={24} className={clasCAB.buttonProgressCAB} />}
</div>
</Fragment>
 )}

/* s PMD */
const [openPMD, setOpenPMD] = useState(false)

const handClosePMD = async (state) => { 
  // if(state==='cancle') await restPayMent(); 
  // if(state==='cancle') await restPM(state); 
  await setOpenPMD(false); 
}
/* e PMD */

  {/* s PMD */}
const frontDialogForm = () => { return(
    <div>
      <Dialog fullScreen className={clasPM.rootPM} open={openPMD}
        onClose={handClosePMD} aria-labelledby="form-dialog-title"
        TransitionComponent={transition} key={transition ? transition.name : ''}
      >

    <DialogTitle id="form-dialog-title"  className={claslbl.clor}>ادخل البيانات</DialogTitle>
    <DialogContent className={[clasPM.dialogContentPM,clasPM.diaCPMBasicDa]}>
     {frontRightUp()} 
     {front.show.rightDown == true ? frontRightDown() : noElmnt()}
    </DialogContent>
    <DialogActions className={clasPM.dirRPM} >


<div className={clasCAB.wrapperCAB}>
  <Button variant="outlined" color="" size="large"
    style={{...stElemnt.all.iconBtn}}
    className={buttonClassnameNew} disabled={loading.new} 
    onClick={()=>handClosePMD('cancle')}
      // startIcon={<CloseIcon />}
      > {'إلـغـاء'} </Button>
  {loading.new && <CircularProgress size={24} className={clasCAB.buttonProgressCAB} />}
</div>

<div className={clasBA.growBA} />

{frontBtnAction()}


            </DialogActions>
          </Dialog>
    </div>
  )}
{/* e PMD */}


return (
showSc?<BackdropScI/>:
<React.Fragment>
<CssBaseline />
<Container maxWidth="xl">
 <Typography component="div" style={styheader}>
  <Grid item container> 

    {/* S front Header */} {frontHeader()} {/* E front Header */} 
    {/* S front Dialog */} 
     <Hidden mdUp={['md']}> {frontDialogForm()} </Hidden>
    {/* E front Dialog */} 

    {/* S front Right */} 
     <Grid container lg={5} md={5}>
       
      {/* S front up R */}
       <Hidden smDown={['sm']}> {frontRightUp()} </Hidden>
      {/* E front up R */}
     


      {/* S front down R */}
       {/* <Hidden smDown={['sm']}> */}
        <Hidden smDown={['sm']}> 
         {front.show.rightDown == true ? frontRightDown() : noElmnt()}
        </Hidden>
       {/* </Hidden> */}
      {/* E front down R */}

     </Grid>
    {/* E front Right */}

    {/* S front Left */} 
     <Grid container lg={7} md={7}>

      {/* S front up L */} 
      {/* {frontLeftUp()}  */}
      {/* E front up L */}

      {/* S front down L */} {frontLeftDown()} {/* E front down L */}

     </Grid>
    {/* E front Left */} 

    {/* S front down R */} 
     <Hidden xsUp={['xs']}> {frontRightDown()} </Hidden>
    {/* E front down R */}

  </Grid>

    {/* S front fixed */} {frontAppBar()} {/* E front fixes */}

    </Typography>
  </Container>

</React.Fragment>
 );
 
}