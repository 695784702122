import React, { useState, useEffect } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import ButtonBase from "@material-ui/core/ButtonBase";
import { uri } from "../../help/Api";
import { Typography } from "@material-ui/core";
import Swipeable from "../../Fronts/Main/Swipeable";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    direction: "rtl",
    margin: 10,
  },
  textField: {
    //width:80,
    margin: 10,
    marginRight: 5,
  },
  buttons: {
    marginRight: 20,
    marginLeft: 5,
    marginTop: 10,
    direction: "ltr",
  },
  input: {
    display: "none",
  },
  imageLogo: {
    marginRight: 20,
    marginTop: 5,
    marginBottom: 10,
    textAlign: "center",
  },
  textFields: {
    //width:80,
    marginRight: 20,
  },
  textField3: {
    //width:80,
    direction: "rtl",
    marginBottom: 5,
  },
  GridButton: {
    marginBottom: 40,
  },
}));

export default function Foundation() {
  const clasess = useStyles();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [data, setData] = useState({
    id: null,
    nameA: "",
    nameE: "",
    FieldA: "",
    FieldE: "",
    bsn_no: "",
    licence_no: "",
    Address: "",
    Tel: "",
    Mobile: "",
    Fax: "",
    Email: "",
    website: "",
    Logo: "",
    footer_img: "",
    Exchangeval: null,
    tax: null,
    port: "",
    tax_no: "",
    BillNotes: "",
    BillNotes1: "",
    BillNotes2: "",
    BillNotes3: "",
    Disc1_header_ar: "",
    Disc2_header_ar: "",
    Disc3_header_ar: "",
    Disc4_header_ar: "",
    Disc1_header_en: "",
    Disc2_header_en: "",
    Disc3_header_en: "",
    Disc4_header_en: "",
    Disc1_footer_ar: "",
    Disc2_footer_ar: "",
    Disc3_footer_ar: "",
    Disc4_footer_ar: "",
    Disc1_footer_en: "",
    Disc2_footer_en: "",
    Disc3_footer_en: "",
    Disc4_footer_en: "",
    MailBox: "",
    tax_ats: null,
    invval_ats: "",
    actv_elec: null,
    CustDisc: "",
    finyear_date1: null,
    finyear_date2: null,
    showimg: null,
    showvat: null,
    showdisc: null,
    showafterdisc: null,
    showtax: null,
    bank1: "",
    acc1: "",
    bank2: "",
    acc2: "",
    bank3: "",
    acc3: "",
    AddressEn: "",
    chkitemquan: null,
    msg: "",
    offer_type: null,
    VAT_Type: null,
    is_tab3: null,
    tab3val: null,
    consume_type: null,
    img: "",
    cnt: "",
    img2: "",
    contract_no: "",
    activity_name: "",
    city: "",
    branchName: "",
    sales_man: "",
    StartWorkDate: "",
    EndWorkDate: "",
    CStartWorkDate: "",
    CEndWorkDate: "",
    firstAlertDate: "",
    SecondAlertDate: "",
    ThirdAlertDate: "",
    StopWorkingDate: "",
    CStopWorkingDate: "",
    is$sys: "",
    is$$dessys: "",
    is$$$dy: "",
    server_name: "",
    point_name: "",
    status_type: "",
    computer_type: "",
    tax_no_build: "",
    tax_name_street: "",
    tax_name_district: "",
    tax_name_city: "",
    tax_name_country: "",
    tax_zip_code: "",
    tax_additional_address_no: "",
    tax_vat_registration_no: "",
  });

  let typeImg = "jpeg";
  const [fileImg, setFileImg] = useState({
    base64URL: "",
    code64: "data:image/" + typeImg + ";base64,",
    code8: "",
    baseURL: false,

    // file:null
  });
  const [fileFooterImg, setFileFooterImg] = useState({
    base64URL: "",
    code64: "data:image/" + typeImg + ";base64,",
    code8: "",
    baseURL: false,

    // file:null
  });

  const changetf = (e) => {
    //  let img8 = decodeURIComponent(escape((e.target.value)));
    // console.log(img8)
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const getFoundation = async () => {
    await axios
      .get(uri + "Foundation")
      .then((res) => {
        setData(res.data);
        console.log("foundation is", res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getFoundation();
  }, []);

  const showData = (rowData) => {
    setData(rowData);
  };

  const updataFoundation = async () => {
    await axios
      .put(uri + "Foundation", data)
      .then((res) => {
        getFoundation();
        setData({});
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const sty = {
    marginLeft: "150px",
    marginRight: "150px",
    marginBottom: "6px",
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        //  console.log("Called", reader);
        baseURL = reader.result;
        // console.log(baseURL);
        resolve(baseURL);
      };
      // console.log(fileInfo);
    });
  };

  const handleFileInputChange = (e) => {
    let file = null; //= data.Logo;

    file = e.target.files[0];

    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        let n = 23,
          str;
        if (file.type != "image/jpeg") n = 22;

        str = file.base64.substring(n);
        // console.log(file.type);

        setData({
          ...data,
          Logo: str,
          // file
        });
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const handleFooterFileInputChange = (e) => {
    let file = null; //= data.Logo;

    file = e.target.files[0];

    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        let n = 23,
          str;
        if (file.type != "image/jpeg") n = 22;

        str = file.base64.substring(n);
        // console.log(file.type);

        setData({
          ...data,
          footer_img: str,
          // file
        });
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  // function TabPanel(props) {
  //   const { children, value, index, ...other } = props;

  //   return (
  //     <div
  //       role="tabpanel"
  //       hidden={value !== index}
  //       id={`simple-tabpanel-${index}`}
  //       aria-labelledby={`simple-tab-${index}`}
  //       {...other}
  //     >
  //       {value === index && (
  //         <Box p={3}>
  //           <Typography>{children}</Typography>
  //         </Box>
  //       )}
  //     </div>
  //   );
  // }

  // TabPanel.propTypes = {
  //   children: PropTypes.node,
  //   index: PropTypes.any.isRequired,
  //   value: PropTypes.any.isRequired,
  // };

  // function a11yProps(index) {
  //   return {
  //     id: `simple-tab-${index}`,
  //     'aria-controls': `simple-tabpanel-${index}`,
  //   };
  // }

  return (
    <div className={clasess.root} style={{ margin: 10 }}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="بيانات الشركة" {...a11yProps(0)} />
          <Tab label="بيانات اضافية " {...a11yProps(1)} />
          <Tab label="رأس وتذييل الصفحة" {...a11yProps(2)} />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        <b>تعريف المؤسسة</b>
        <Grid item container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="nameA"
              label=" اسم الموسسة عربي"
              className={clasess.textField}
              name="nameA"
              value={data.nameA}
              InputLabelProps={{ shrink: true }}
              onChange={changetf}
            />
          </Grid>

          <Grid item lg={6} md={12} sm={12} xs={12}>
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="stay"
              label=" اسم الموسسة انجليزي"
              className={clasess.textField}
              name="nameE"
              value={data.nameE}
              InputLabelProps={{ shrink: true }}
              onChange={changetf}
            />
          </Grid>

          <Grid item lg={6} md={12} sm={12} xs={12}>
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="stay"
              label=" النشاط التجاري  "
              className={clasess.textField}
              name="FieldA"
              value={data.FieldA}
              InputLabelProps={{ shrink: true }}
              onChange={changetf}
            />
          </Grid>

          <Grid item lg={6} md={12} sm={12} xs={12}>
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="stay"
              label=" النشاط بالانجليزي  "
              className={clasess.textField}
              name="FieldE"
              value={data.FieldE}
              InputLabelProps={{ shrink: true }}
              onChange={changetf}
            />
          </Grid>

          <Grid item lg={6} md={12} sm={12} xs={12}>
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="stay"
              label=" رقم السجل التجاري "
              className={clasess.textField}
              name="bsn_no"
              value={data.bsn_no}
              InputLabelProps={{ shrink: true }}
              onChange={changetf}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={6}>
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="stay"
              label=" رقم  الهاتف "
              className={clasess.textField}
              name="Tel"
              value={data.Tel}
              InputLabelProps={{ shrink: true }}
              onChange={changetf}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={6}>
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="stay"
              label=" رقم  الفاكس "
              className={clasess.textField}
              name="Fax"
              value={data.Fax}
              InputLabelProps={{ shrink: true }}
              onChange={changetf}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={6}>
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="stay"
              label=" البريد الالكتروني"
              className={clasess.textField}
              name="Email"
              value={data.Email}
              InputLabelProps={{ shrink: true }}
              onChange={changetf}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={6}>
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="stay"
              label="  رقم الجوال "
              className={clasess.textField}
              name="Mobile"
              value={data.Mobile}
              InputLabelProps={{ shrink: true }}
              onChange={changetf}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={6}>
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="stay"
              label="  الرقم الضريبي"
              className={clasess.textField}
              name="tax_no"
              value={data.tax_no}
              InputLabelProps={{ shrink: true }}
              onChange={changetf}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={6}>
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="stay"
              label="  العنوان "
              className={clasess.textField}
              name="Address"
              value={data.Address}
              InputLabelProps={{ shrink: true }}
              onChange={changetf}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="stay"
              label="  ملاحضات اسفل الفاتورة "
              className={clasess.textField}
              name="BillNotes"
              value={data.BillNotes}
              InputLabelProps={{ shrink: true }}
              onChange={changetf}
            />
          </Grid>

          <Grid item container>
            <Grid item lg={9} md={9} sm={9} xs={8}>
              <div>
                <TextField
                  type="text"
                  fullWidth
                  variant="outlined"
                  size="small"
                  id="stay"
                  label="  الموقع الالكتروني "
                  className={clasess.textField}
                  name="website"
                  value={data.website}
                  InputLabelProps={{ shrink: true }}
                  onChange={changetf}
                />

                <TextField
                  type="text"
                  fullWidth
                  variant="outlined"
                  size="small"
                  id="stay"
                  label="  صندوق البريد "
                  className={clasess.textField}
                  name="MailBox"
                  value={data.MailBox}
                  InputLabelProps={{ shrink: true }}
                  onChange={changetf}
                />
              </div>
            </Grid>

            <Grid
              className={clasess.imageLogo}
              item
              lg={2}
              md={2}
              sm={2}
              xs={2}
            >
              <input
                accept="image/*"
                className={clasess.input}
                id="contained-button-file"
                multiple
                name="Logo" //value={data.Logo.toString()}
                type="file"
                onChange={handleFileInputChange}
              />
              <label htmlFor="contained-button-file">
                <ButtonBase
                  style={{ border: "solid 1px" }}
                  variant="contained"
                  color="primary"
                  component="span"
                >
                  <img
                    src={` ${fileImg.baseURL ? fileImg.code8 : fileImg.code64}
                  ${data.Logo}
                  `}
                    style={{ height: 100, width: 100 }}
                    alt={data.nameA}
                  />
                </ButtonBase>
              </label>
            </Grid>
          </Grid>

          <Typography component="div">
            {" "}
            <Swipeable />{" "}
          </Typography>
        </Grid>

        <Grid className={clasess.GridButton} item lg={1} md={1} sm={1} xs={2}>
          <Button
            className={clasess.buttons}
            variant="contained"
            color="primary"
            fontSize="large"
            size="medium"
            onClick={updataFoundation}
            startIcon={<SaveIcon />}
          >
            حـفـظ
          </Button>
        </Grid>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <b>بيانات اضافية</b>
        <Grid item container className={clasess.textField}>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="stay"
              label="رقم المبنى"
              className={clasess.textField}
              name="tax_no_build"
              value={data.tax_no_build}
              InputLabelProps={{ shrink: true }}
              onChange={changetf}
            />
          </Grid>

          <Grid item lg={6} md={12} sm={12} xs={12}>
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="stay"
              label=" اسم الشارع"
              className={clasess.textField}
              name="tax_name_street"
              value={data.tax_name_street}
              InputLabelProps={{ shrink: true }}
              onChange={changetf}
            />
          </Grid>

          <Grid item lg={6} md={12} sm={12} xs={12}>
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="stay"
              label="  الحي"
              className={clasess.textField}
              name="tax_name_district"
              value={data.tax_name_district}
              InputLabelProps={{ shrink: true }}
              onChange={changetf}
            />
          </Grid>

          <Grid item lg={6} md={12} sm={12} xs={12}>
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="stay"
              label="  المدينة"
              className={clasess.textField}
              name="tax_name_city"
              value={data.tax_name_city}
              InputLabelProps={{ shrink: true }}
              onChange={changetf}
            />
          </Grid>

          <Grid item lg={6} md={12} sm={12} xs={12}>
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="stay"
              label="  البلد"
              className={clasess.textField}
              name="tax_name_country"
              value={data.tax_name_country}
              InputLabelProps={{ shrink: true }}
              onChange={changetf}
            />
          </Grid>

          <Grid item lg={6} md={12} sm={12} xs={12}>
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="stay"
              label="  الرمز البريدي"
              className={clasess.textField}
              name="tax_zip_code"
              value={data.tax_zip_code}
              InputLabelProps={{ shrink: true }}
              onChange={changetf}
            />
          </Grid>

          <Grid item lg={6} md={12} sm={12} xs={12}>
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="stay"
              label="  الرقم الضريبي للعنوان"
              className={clasess.textField}
              name="tax_additional_address_no"
              value={data.tax_additional_address_no}
              InputLabelProps={{ shrink: true }}
              onChange={changetf}
            />
          </Grid>

          <Grid item lg={6} md={12} sm={12} xs={12}>
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="stay"
              label="  رقم تسجيل القيمة المضافة"
              className={clasess.textField}
              name="tax_vat_registration_no"
              value={data.tax_vat_registration_no}
              InputLabelProps={{ shrink: true }}
              onChange={changetf}
            />
          </Grid>

          <Typography component="div">
            {" "}
            <Swipeable />{" "}
          </Typography>
        </Grid>
        <Grid className={clasess.GridButton} item lg={1} md={1} sm={1} xs={2}>
          <Button
            className={clasess.buttons}
            variant="contained"
            color="primary"
            fontSize="large"
            size="medium"
            onClick={updataFoundation}
            startIcon={<SaveIcon />}
          >
            حـفـظ
          </Button>
        </Grid>
      </TabPanel>

      <TabPanel value={value} index={2}>
        <b>رأس وتذييل الصفحة</b>

        <Grid item container className={clasess.textField}>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="stay"
              label=" الاسم"
              className={clasess.textField}
              name="nameA"
              value={data.nameA}
              InputLabelProps={{ shrink: true }}
              onChange={changetf}
            />
          </Grid>

          <Grid item lg={6} md={12} sm={12} xs={12}>
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="stay"
              label=" الاسم لاتيني"
              className={clasess.textField}
              name="nameE"
              value={data.nameE}
              InputLabelProps={{ shrink: true }}
              onChange={changetf}
            />
          </Grid>

          <Grid item lg={6} md={12} sm={12} xs={12}>
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="stay"
              label=" النشاط بالعربي"
              className={clasess.textField}
              name="FieldA"
              value={data.FieldA}
              InputLabelProps={{ shrink: true }}
              onChange={changetf}
            />
          </Grid>

          <Grid item lg={6} md={12} sm={12} xs={12}>
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="stay"
              label=" النشاط بالانجليزي"
              className={clasess.textField}
              name="FieldE"
              value={data.FieldE}
              InputLabelProps={{ shrink: true }}
              onChange={changetf}
            />
          </Grid>

          <Grid
            className={clasess.imageLogo}
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
          >
            <input
              accept="image/*"
              className={clasess.input}
              id="contained-button-file"
              multiple
              name="Logo" //value={data.Logo.toString()}
              type="file"
              onChange={handleFileInputChange}
            />
            <label htmlFor="contained-button-file">
              <ButtonBase
                style={{ border: "solid 1px" }}
                variant="contained"
                color="primary"
                component="span"
              >
                <img
                  src={` ${fileImg.baseURL ? fileImg.code8 : fileImg.code64}
           ${data.Logo}`}
                  style={{ height: 100, width: 100 }}
                  alt={data.nameA}
                />
              </ButtonBase>
            </label>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography color="textSecondary" component="p" style={sty}>
              <b>راس الصفحة</b>
            </Typography>
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={6}>
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="stay"
              label="وصف 1"
              className={clasess.textField}
              name="Disc1_header_ar"
              value={data.Disc1_header_ar}
              InputLabelProps={{ shrink: true }}
              onChange={changetf}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={6}>
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="stay"
              label=" "
              className={clasess.textField}
              name="Disc1_header_en"
              value={data.Disc1_header_en}
              InputLabelProps={{ shrink: true }}
              onChange={changetf}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={6}>
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="stay"
              label="وصف 2"
              className={clasess.textField}
              name="Disc2_header_ar"
              value={data.Disc2_header_ar}
              InputLabelProps={{ shrink: true }}
              onChange={changetf}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={6}>
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="stay"
              label=" "
              className={clasess.textField}
              name="Disc2_header_en"
              value={data.Disc2_header_en}
              InputLabelProps={{ shrink: true }}
              onChange={changetf}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={6}>
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="stay"
              label="وصف 3"
              className={clasess.textField}
              name="Disc3_header_ar"
              value={data.Disc3_header_ar}
              InputLabelProps={{ shrink: true }}
              onChange={changetf}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={6}>
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="stay"
              label=" "
              className={clasess.textField}
              name="Disc3_header_en"
              value={data.Disc3_header_en}
              InputLabelProps={{ shrink: true }}
              onChange={changetf}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={6}>
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="stay"
              label="وصف 4"
              className={clasess.textField}
              name="Disc4_header_ar"
              value={data.Disc4_header_ar}
              InputLabelProps={{ shrink: true }}
              onChange={changetf}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={6}>
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="stay"
              label=" "
              className={clasess.textField}
              name="Disc4_header_en"
              value={data.Disc4_header_en}
              InputLabelProps={{ shrink: true }}
              onChange={changetf}
            />
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography color="textSecondary" component="p" style={sty}>
              <b>ذيل الصفحة</b>
            </Typography>
          </Grid>

          <Grid
            className={clasess.imageLogo}
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
          >
            <input
              accept="image/*"
              className={clasess.input}
              id="contained-button-file1"
              multiple
              name="footer_image" //value={data.Logo.toString()}
              type="file"
              onChange={handleFooterFileInputChange}
            />
            <label htmlFor="contained-button-file1">
              <ButtonBase
                style={{ border: "solid 1px" }}
                variant="contained"
                color="primary"
                component="span"
              >
                <img
                  src={`${
                    fileFooterImg.baseURL
                      ? fileFooterImg.code8
                      : fileFooterImg.code64
                  }
           ${data.footer_img}`}
                  style={{ height: 100, width: 100 }}
                  alt={data.nameA}
                />
              </ButtonBase>
            </label>
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={6}>
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="stay"
              label="وصف 1"
              className={clasess.textField}
              name="Disc1_footer_ar"
              value={data.Disc1_footer_ar}
              InputLabelProps={{ shrink: true }}
              onChange={changetf}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={6}>
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="stay"
              label=" "
              className={clasess.textField}
              name="isc1_footer_en"
              value={data.isc1_footer_en}
              InputLabelProps={{ shrink: true }}
              onChange={changetf}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={6}>
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="stay"
              label="وصف 2"
              className={clasess.textField}
              name="Disc2_footer_ar"
              value={data.Disc2_footer_ar}
              InputLabelProps={{ shrink: true }}
              onChange={changetf}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={6}>
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="stay"
              label=" "
              className={clasess.textField}
              name="Disc2_footer_en"
              value={data.Disc2_footer_en}
              InputLabelProps={{ shrink: true }}
              onChange={changetf}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={6}>
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="stay"
              label="وصف 3"
              className={clasess.textField}
              name="Disc3_footer_ar"
              value={data.Disc3_footer_ar}
              InputLabelProps={{ shrink: true }}
              onChange={changetf}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={6}>
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="stay"
              label=" "
              className={clasess.textField}
              name="Disc3_footer_en"
              value={data.Disc3_footer_en}
              InputLabelProps={{ shrink: true }}
              onChange={changetf}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={6}>
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="stay"
              label="وصف 4"
              className={clasess.textField}
              name="Disc4_footer_ar"
              value={data.Disc4_footer_ar}
              InputLabelProps={{ shrink: true }}
              onChange={changetf}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={6}>
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              id="stay"
              label=" "
              className={clasess.textField}
              name="Disc4_footer_en"
              value={data.Disc4_footer_en}
              InputLabelProps={{ shrink: true }}
              onChange={changetf}
            />
          </Grid>

          <Typography component="div" direction="ltr">
            {" "}
            <Swipeable />{" "}
          </Typography>
        </Grid>

        <Grid className={clasess.GridButton} item lg={1} md={1} sm={1} xs={2}>
          <Button
            className={clasess.buttons}
            variant="contained"
            color="primary"
            fontSize="large"
            size="medium"
            onClick={updataFoundation}
            startIcon={<SaveIcon />}
          >
            حـفـظ
          </Button>
        </Grid>
      </TabPanel>
    </div>
  );
}
