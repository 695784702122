import React, { useState, useRef, Component } from "react";
import ReactToPrint from "react-to-print";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import PrintIcon from "@material-ui/icons/Print";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import HomeIcon from "@material-ui/icons/Home";
import HeaderA from "./A4/HeaderA";
import BodyA from "./A4/BodyA";
import FooterA from "./A4/FooterA";
// import Loader from '../Loader';

import { useStylesSpDial } from "../../../St/Reviews/UseStyle";
import { stElemnt } from "../../../St/Reviews/cStyle";
import { Grid } from "@material-ui/core";

class ComponentToPrint extends Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    const tyeprints = JSON.parse(localStorage.getItem("typPrnt")) || {};

    return (
      <Typography component="div" style={stElemnt.AF.Reviws.Typogmain}>
        <Typography component="div" style={stElemnt.AF.Reviws.Typogsub}>
          <HeaderA />
          <BodyA />
          <FooterA />
        </Typography>
      </Typography>
    );
  }
}

const SaleAnalysisPrint = () => {
  const componentRef = useRef();
  const clasSp = useStylesSpDial();

  const [open, setOpen] = useState(true);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(true);
  };
  let his = useHistory();

  const back = (path) => {
    path ? his.push({ pathname: "/" }) : his.goBack();
  };

  const styleCont = {
    backgroundImage: "linear-gradient(60deg, #4e9e84 , #2688ba)",
  };
  const actions = [
    {
      icon: (
        <ReactToPrint
          trigger={() => <PrintIcon />}
          content={() => componentRef.current}
        />
      ),
      name: "طباعـة",
    },
    {
      icon: (
        <ArrowBackIcon className={clasSp.ico} onClick={() => back(false)} />
      ),
      name: "رجـوع",
    },
  ];

  return (
    <div className={clasSp.root}>
      <Grid item container justifyContent="center" style={styleCont}>
        <Grid
          item
          style={{ backgroundColor: "#fff", width: "22cm", overflow: "scroll" }}
        >
          <CssBaseline />
          <ComponentToPrint ref={componentRef} />
        </Grid>
      </Grid>

      <SpeedDial
        ariaLabel="SpeedDial openIcon example"
        className={clasSp.speedDial}
        hidden={false}
        icon={
          <SpeedDialIcon openIcon={<HomeIcon />} onClick={() => back(true)} />
        }
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action, i) => (
          <SpeedDialAction
            key={i}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={handleClose}
          />
        ))}
      </SpeedDial>
    </div>
  );
};

export default SaleAnalysisPrint;
