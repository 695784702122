import React, { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import { useTranslation } from "react-i18next";
export default function CurrencySalesProfitForm(props) {
  const { rptParams, onChangeData, AutoOptions, onChangeAutoData } = props;

  const { t } = useTranslation(["forms"]);
  return (
    <React.Fragment>
      <Grid container style={{ padding: 5 }} spacing={1}>
        <Grid item lg={2} md={2} sm={2} xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            id="datef"
            label={t("date-from")}
            type="date"
            format="YYYY-MM-DD"
            name="datef"
            value={rptParams.dateFrom}
            onChange={onChangeData("dateFrom")}
          />
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            id="dateT"
            label={t("date-to")}
            type="date"
            format="YYYY-MM-DD"
            name="datet"
            value={rptParams.dateTo}
            onChange={onChangeData("dateTo")}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={6}>
          <Autocomplete
            value={rptParams.currency}
            onChange={(event, newValue) => {
              onChangeAutoData("currency", newValue);
            }}
            id="combo-box-demo"
            size="small"
            options={AutoOptions.items}
            //getOptionLabel={(option) => option.name}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            style={{}}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                label={t("item")}
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={6}>
          <Autocomplete
            value={rptParams.safe}
            onChange={(event, newValue) => {
              onChangeAutoData("safe", newValue);
            }}
            id="combo-box-demo"
            size="small"
            options={AutoOptions.Safes}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            style={{}}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                label={t("safe")}
                variant="outlined"
              />
            )}
          />
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={6}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  fullWidth
                  checked={rptParams.chk_withvat}
                  onChange={(e) =>
                    onChangeAutoData("chk_withvat", e.target.checked)
                  }
                  name="chkVat"
                />
              }
              size="small"
              color="primary"
              fontSize="small"
              label={t("withtax")}
            />
          </FormGroup>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
