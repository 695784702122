import axios from 'axios'
import { uri } from '../help/Api'

export const URL =uri+'SandSSKh_R_RS_';

export function list(){
    return axios.get(URL);
}

export function create(data){
    return axios.post(URL, data);
}

export function update(data){
    return axios.put(URL, data)
}
export function remove(id){
    return axios.delete(URL+`/${id}`)
}

export default{URL,list, create, update, remove}