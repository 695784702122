import moment from "moment/moment";
import { locRem, locSto } from "../Context/Locstor";
import * as yup from "yup";
import { getAccountOmlat } from "./omlat-services";

export const subDefaultValues = {
  res_id: "",
  acc_no: "",
  name: "",
  account: { id: "", name: "" },
  acc_omlat: [],
  acc_omla: { id: "-1", name: "", exchange: 1 },
  credit: "",
  dept: 0,
  refno: "",
  value: "",
  notes: "",
};

export const MRVdefaultValues = {
  id: "",
  date: moment(new Date()).format("YYYY-MM-DD"),
  acc_no: "",
  Val: "",
  tot_val: 0,
  taxperc: 0,
  taxval: 0,
  type: "1",
  safe_bank_id: -1,
  sales_emp: null,
  salesmen: null,
  rows: [],
  bank: null,
  stock: null,
  check_no: "",
  check_date: moment(new Date()).format("YYYY-MM-DD"),
  notes: "",
  rest_id: "",
  branch: locRem.branchi,
  center: { id: "-1", name: "" },
  ref_no: "",
};

export const EExchangeValidateSchema = yup.object().shape({
  val: yup.string().required("يجب ادخال المبلغ"),
  cust_id: yup.string().required("يجب اختيار العميل"),
});

export function PrepareDataWithOmla(data, isReceipt) {
  let omlaTemp = data.type == "1" ? data?.stock_omla : data?.bank_omla;
  const total = data?.rows?.reduce((a, v) => (a = a + v.credit), 0);

  let _Data = data?.rows?.map((row) => {
    return {
      dept: isReceipt ? row.dept : row.credit,
      credit: isReceipt ? row.credit : row.dept,
      acc_no: row.acc_no,
      notes: row.notes,
      branch: data.branch,
      refno: row.refno,
      omla: row?.acc_omla?.id,
      omlaval: row?.acc_omla?.exchange,
    };
  });
  let nData = {
    id: data?.id,
    omla: omlaTemp?.id,
    omlaval: omlaTemp?.exchange,
    sales_emp: data?.salesmen ? data.salesmen?.id : "",
    center: data.center.id,
    safe_bank_id: data.type == "1" ? data.stock.id : data.bank.id,
    check_state: data?.check_state ? data.check_state.id : "",
    check_no: data?.check_no,
    check_date: data?.check_date,
    notes: data?.notes,
    rest_id: data?.rest_id,
    date: data.date,
    branch: data.branch,
    taxperc: data?.taxperc,
    taxval: total * (data.taxperc / 100),
  };
  let rData = {
    ...nData,
    Type: data?.type,
    Val: total,
  };
  let eData = {
    ...nData,
    type: data?.type,
    val: total,
  };

  return {
    rcpt: isReceipt ? rData : eData,
    acc_no: data?.type == "1" ? data?.stock?.acc_no : data?.bank?.acc_no,
    _Data: _Data,
  };
}

export function GetItemFromOptions(id, options) {
  const index = options?.findIndex((op) => op.id == id);
  return options[index];
}

function reFormatingRows(rows, AutoOptions, isReceipt) {
  const accounts = AutoOptions?.Customers;
  var id = 1;
  let nRows = rows?.map((r) => {
    const account = GetItemFromOptions(r?.acc_no, accounts);
    const omla = GetItemFromOptions(r?.omla, AutoOptions.omlats);
    const cd = isReceipt ? r.credit : r.dept;
    const dp = isReceipt ? r.dept : r.credit;
    return {
      ...r,
      id: id++,
      name: account?.name,
      credit: cd,
      dept: dp,
      value: cd / r.omlaval,
      account: account,
      acc_omla: { ...omla, exchange: r.omlaval },
      acc_omlat: getAccountOmlat(account?.acc_omlat, AutoOptions.omlats),
    };
  });
  return nRows;
}
export function ReFormatingData(data, AutoOptions, isReceipt) {
  let salesmen = "",
    bank = "",
    stock = "",
    StockOmlat = [],
    BankOmlat = [];
  const rcpt = data?.rcpt;
  let omla = GetItemFromOptions(rcpt.omla, AutoOptions.omlats);
  salesmen = GetItemFromOptions(rcpt.sales_emp, AutoOptions.salesmen);
  const stock_omla = { ...omla, exchange: rcpt?.omlaval };
  const type = isReceipt ? rcpt?.Type : rcpt?.type;

  if (type == "1") {
    stock = GetItemFromOptions(rcpt?.safe_bank_id, AutoOptions.Stocks);
    bank = AutoOptions.Banks[0];
  } else {
    bank = GetItemFromOptions(rcpt.safe_bank_id, AutoOptions.Banks);
    stock = AutoOptions.Stocks[0];
  }
  BankOmlat = getAccountOmlat(bank?.acc_omlat, AutoOptions.omlats);
  StockOmlat = getAccountOmlat(stock?.acc_omlat, AutoOptions.omlats);

  let nData = {
    ...rcpt,
    rows: reFormatingRows(data?._Data, AutoOptions, isReceipt),
    type: type,
    stock_omlat: StockOmlat,
    stock_omla: stock_omla,
    bank_omlat: BankOmlat,
    bank_omla: stock_omla,
    center:
      rcpt.center === -1
        ? MRVdefaultValues.center
        : GetItemFromOptions(rcpt.center, AutoOptions.costcenters),
    stock: stock,
    date: moment(rcpt.date).format("YYYY-MM-DD"),
    salesmen: salesmen,
    bank: bank,
  };
  if (!isReceipt) {
    nData = { ...nData, Val: nData.val };
  }

  return nData;
}

export const subMultiReceiptSchema = yup.object().shape({
  acc_no: yup.string().required("قم بتحديد الحساب"),
  value: yup.string().required("أدخل المبلغ"),
});
