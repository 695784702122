import React,{useState,useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import axios from 'axios';
import {uri}  from '../../../../help/Api';
import {sto} from '../../../../Context/InshData';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from "material-table";
import { TableIcons as tableIcons } from "../../../../Elemnts/TableIcons";
import  Swipeable  from '../../../../Fronts/Main/Swipeable';
import { cellS, headerS, stElemnt } from '../../../../St/comps/cStyle';
import Button from '@material-ui/core/Button';
import PrintIcon from '@material-ui/icons/Print';
import { CssBaseline } from '@material-ui/core';
// import Page from '../../../Page';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import { useHistory } from 'react-router-dom';



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    direction: 'rtl',
    marginTop: 10,
    padding:5,
    // boxShadow:'box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;',
    boxShadow:'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;',
  },
  textField: {
    //width:80,
    margin: 10,
    marginRight: 10,
    marginLeft: 10
  },
  imageLogo: {
    marginRight: 20,
    marginTop: 5,
    marginBottom: 10,
    textAlign: 'center'
  },
  rootup:{
    // border:'solid 0.1px',
    // marginTop:'-1rem',
    [theme.breakpoints.only('lg')]: {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.only('md')]: {
      padding: theme.spacing(0.5),
    },
    direction: 'rtl',
    padding:3
  },
  upsub:{
    // border:'solid 0.1px red',
    borderRadius:5,
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    [theme.breakpoints.only('xs')]: {
       marginLeft:'0rem',marginRight:'0rem'
    },
  },
  upsubR:{
   // boxShadow: `0px 0px 1.5px 0.5px ${insh.colors.primary},
  //  0px 0px 1.5px 0.5px ${insh.colors.secndry}`,

    alignItems: 'center',
    [theme.breakpoints.only('lg')]: {
      padding:'3rem 1rem'
    },
    [theme.breakpoints.only('md')]: {
      padding:'4rem 0.1rem'
    },
  },
  root2: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    direction: 'rtl',
  },
  root3: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    direction: 'rtl',
    marginTop: 5,
    padding:5,
    // boxShadow:'box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;',
    boxShadow:'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;',
  },
  root4: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    direction: 'rtl',
    marginTop: 5,
    padding:5,
    marginBottom:20,
    // boxShadow:'box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;',
    boxShadow:'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;',
  },
}));

const sty ={
  marginLeft: '-6px',
  marginRight: '-6px',
  marginTop: '-6px',
  marginBottom: '-6px',
  textAlign:'center'
}
const styTitle ={
  textAlign: '-webkit-center',
  // backgroundColor:'red'
}

// for select
let prmvTaqeem=1
let prmvSafe=-1
let countCurr=0
let prmStateCheckSafe=true;


const ReportSafesJard = () => {
  const classes = useStyles();

let viewPrm = `View_SAMER_jard?`; // uri

// ================= For Auto ======================   
  //Taqeem
  const options = [
  { name: 'متوسط سعر التكلفة', id: 1 },
  { name: 'أخر سعر شراء', id: 2 },
  { name: 'اخر سعر توريد', id: 3 },
];
const [crossTaqeem,setcrossTaqeem] = useState(options[0].name);

  const [optionsTaqeem] = useState(options);
  const [valueTaqeem, setValueTaqeem] = useState(options[0]);
  //Safe
  const [optionsSafe, setOptionsSafes] = useState([]);
  const [valueSafe, setValueSafe] = useState({});
  

    const [totval, seTtotval] = useState(0); // الإجمالي
    const [totTaqlfa, seTtotTaqlfa] = useState(0);  // اجمالي التكلفة
    const [totSale, seTtotSale] = useState(0); // اجمالي البيع


  let sumToto_val=0,sumToto_taqlfa=0,sumToto_sale=0;



  const getAllSumTot = async (the_length) => {

    for (let i = 0; i < the_length; i++){
      sumToto_val+=idata[i].val;
      sumToto_taqlfa+=idata[i].totPurch;
      sumToto_sale+=idata[i].totsale;
    }

    seTtotval(sumToto_val); // الإجمالي 
    seTtotTaqlfa(sumToto_taqlfa);  // اجمالي التكلفة
    seTtotSale(sumToto_sale); // اجمالي البيع


    setData(idata);
  }


   const GetDataWhenChangeDate = async () => {  

     setViewPrms(viewPrm);

      await axios.get(uri+viewPrm).then(res => {
        setData(res.data);

         idata=res.data;
         countCurr=res.data.length
         getAllSumTot(countCurr);

       }).catch(error => { })
      
      };

   const GetDataWhennoChange = async (val) => {  
      viewPrm += `safe=${val}&&typeJard=${options[0].id}`;
      // viewPrm += `typeJard=${options[0].id}`;
      // setViewPrms(viewPrm);
      await axios.get(uri+viewPrm).then(res => {
         idata=res.data; countCurr=res.data.length; getAllSumTot(countCurr); }).catch(error => { })
   }



         const changAutoSafe = (newValue) => 
         { 
          setValueSafe(newValue);
          setcrossTaqeem(newValue.name); 
          getIdSelect(prmStateCheckSafe=false,prmvSafe=newValue.id);
          // GetDataWhenChangeDate(); 

         }

         const getAutoSafes = async (i) => {
          await axios.get(uri+'Auto'+'/safe?id='+sto.empi+'&&b='+sto.branchi).then(res => {
            prmStateCheckSafe=true; //viewPrm += `typeJard=${prmvTaqeem}`;

             setOptionsSafes(res.data); 
              setValueSafe(res.data[i]);
              for(let m=0; m<res.data.length;m++) { 
                if(res.data[m].IS_Default === true && res.data[m].IS_Deleted === false) {}
                // { setValueSafe(res.data[m]); break; }
                // GetDataWhenChangeDate(); 
              }
              GetDataWhennoChange(res.data[i].id)
            }).catch(error => { })
          }


  const [data, setData] = useState([]);
  const [viewPrms, setViewPrms] = useState('');
let idata=null
  useEffect(() => {   
    const GetData = async () => { 
  // for Table  
  getAutoSafes(0);

  };
  
    GetData();  
  }, []);

 
  const getIdSelect = (chek,all) => {
    // const [crossTaqeem,setcrossTaqeem] = useState(options[0].name);
    setcrossTaqeem(options[prmvTaqeem-1].name);
    if(prmStateCheckSafe===true)
    { viewPrm += `typeJard=${prmvTaqeem}`; }
    else
    {viewPrm += `safe=${prmvSafe}&&typeJard=${prmvTaqeem}`;}

   GetDataWhenChangeDate();
  }


  var columns = [
    {title: "رقم الباركود", field: "barcode", },
    {title: "الصنف", field: "symbol",},
    {title: "الرصيد", field: "val",},
    {title: crossTaqeem, field: "purch_price",},
    {title: "إجمالي التكلفة", field: "totPurch",},
    {title: "سعر البيع", field: "sale_price",},
    {title: "إجمالي البيع", field: "totsale",},
  ];


  let his = useHistory();
  const toPosh = () => {
    const info = {
    columns:columns,
    // rout:viewPrms,
    title:'كشف جرد المخازن',
    data:data,
    auto:{
      safe:valueSafe.name,
      user:sto.usernames,
      crossTaqeem:valueTaqeem.name,
    },
    tot:{
      totSale:totSale,
      totTaqlfa:totTaqlfa,
      totval:totval,
    },
    nDate:moment(new Date()).format('YYYY-MM-DD'),
     ElmntShow:false,
    }
    //console.log(info)
    localStorage.setItem('iInfos',  JSON.stringify(info)); 
    his.push({ pathname: '/ReviewJard', });
    window.location.reload(false);
  }


  return (
    <React.Fragment>
    <CssBaseline />
    
       <Grid className={classes.root} item container>
        <Grid item lg={3} md={3} sm={3} xs={6}>
        <Autocomplete value={valueTaqeem}
          onChange={(event, newValue) => { setValueTaqeem(newValue); getIdSelect(null,prmvTaqeem=newValue.id);}}
          id="combo-box-demo" size="small"
          options={optionsTaqeem} 
          //getOptionLabel={(option) => option.name}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
          style={{  }}
          disableClearable
          renderInput={(params) => <TextField {...params}  InputLabelProps={{shrink: true}} label="طريقة التقييم" variant="outlined" />}   
        />

        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={6}>
        <Autocomplete value={valueSafe}
          onChange={(event, newValue) => { changAutoSafe(newValue) }}
       
          id="combo-box-demo" size="small"
          options={optionsSafe} 
          //getOptionLabel={(option) => option.name}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
          style={{  }}
          disableClearable
          renderInput={(params) => <TextField {...params}  InputLabelProps={{shrink: true}} label="المخزن" variant="outlined" />}   
        />
        </Grid>

         <Grid className={classes.textField} item lg={2} md={2} sm={2} xs={4}>
          <Typography component="h6" variant="h6">
          عدد الاصناف : 
          </Typography>
        </Grid>
        <Grid className={classes.textField} item lg={2} md={2} sm={3} xs={5}>
          <Typography component="h6" variant="h6">
          {countCurr}
          </Typography>
        </Grid>


      </Grid> 


      <Grid  item container className={classes.root3}>

 <Grid item lg={12} md={12} sm={12} xs={12}>
        <MaterialTable style={{ boxShadow: 'none' }}
          title='بحث عن الفواتير'
          // variant="outlined"
          options={{
            pageSize: 7,
            //showTitle:false,
            toolbar: false,
            sorting: false,
            showTitle: false,
            paging: false,

            headerStyle: headerS,
            cellStyle: cellS,
            maxBodyHeight: '50vh',
            minBodyHeight: '60vh',

          }}

          localization={{

            body: {
              emptyDataSourceMessage: <h3
                style={{ marginTop: '20vh', border: 'solid 0px', boxShadow: 'none' }}
              ></h3>
            }
          }}
          columns={columns}
          data={data}
          icons={tableIcons}

          onRowClick={(event, rowData) => {
            event.stopPropagation();
          }} />
      </Grid> 

    </Grid>

      <Grid className={classes.root3} item container >
        <Grid item lg={2} md={2} sm={2} xs={7}>
          <CardActionArea><CardContent>
            <Typography color="textSecondary" component="p" style={sty}  >
              الإجمالي
            </Typography></CardContent></CardActionArea></Grid>
        <Grid item lg={2} md={2} sm={2} xs={5} className={classes.iitemS} style={styTitle}><CardActionArea><CardContent>
          <Typography color="textSecondary" component="p" style={sty} >
          {totval}
          </Typography></CardContent></CardActionArea></Grid>

        <Grid item lg={2} md={2} sm={2} xs={7} className={classes.iitemS} style={styTitle}><CardActionArea><CardContent>
          <Typography color="textSecondary" component="p" style={sty}  >
            إجمالي التكلفة
          </Typography></CardContent></CardActionArea></Grid>

        <Grid item lg={2} md={2} sm={2} xs={5} className={classes.iitemS}> <CardActionArea><CardContent>
          <Typography color="textSecondary" component="p" style={sty} >
          {totTaqlfa} 
          </Typography></CardContent></CardActionArea></Grid>

        <Grid item lg={2} md={2} sm={2} xs={7} className={classes.iitemS} style={styTitle}><CardActionArea><CardContent>
          <Typography color="textSecondary" component="p" style={sty}  >
            الإجمالي البيع
          </Typography></CardContent></CardActionArea></Grid>

        <Grid item lg={2} md={2} sm={2} xs={5} className={classes.iitemS}> <CardActionArea><CardContent>
          <Typography color="textSecondary" component="p" style={sty} >
          {totSale}
          </Typography></CardContent></CardActionArea>
        </Grid>
      </Grid> 

      <div style={{textAlign:'center',marginBottom:55,marginTop:10}}>
        <Button variant="contained"  color="primary" className={classes.button}
        onClick={toPosh} startIcon={<PrintIcon/>}> معاينة </Button>
      </div>

      <Typography component="div" direction='ltr'> <Swipeable /> </Typography>


</React.Fragment>
  );

/*
  return (
    <>


      <Typography component="div" style={{ backgroundColor: '#eee', direction:'rtl' }} >

      <Container maxWidth={false}>
        <Grid container spacing={3}>
        <Grid container component={Paper} style={{marginTop:10}} spacing={1}>


        <Grid item sm={2} xs={6}>
        <Autocomplete value={valueTaqeem}
          onChange={(event, newValue) => { setValueTaqeem(newValue); getIdSelect(null,prmvTaqeem=newValue.id);}}
          id="combo-box-demo" size="small"
          options={optionsTaqeem} 
          //getOptionLabel={(option) => option.name}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
          style={{  }}
          disableClearable
          renderInput={(params) => <TextField {...params}  InputLabelProps={{shrink: true}} label="طريقة التقييم" variant="outlined" />}   
        />
        </Grid>

        <Grid item sm={2} xs={6}>
        <Autocomplete value={valueSafe}
          onChange={(event, newValue) => { changAutoSafe(newValue) }}
       
          id="combo-box-demo" size="small"
          options={optionsSafe} 
          //getOptionLabel={(option) => option.name}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
          style={{  }}
          disableClearable
          renderInput={(params) => <TextField {...params}  InputLabelProps={{shrink: true}} label="المخزن" variant="outlined" />}   
        /></Grid>


<Grid item 
// className={classes.iitem}
  xs={6} sm={2} className={classes.iitemS} style={styTitle}><CardActionArea><CardContent>
          <Typography color="textSecondary" component="p" style={sty}  >     
          عدد الاصناف
          </Typography></CardContent></CardActionArea></Grid>

      <Grid item 
      // className={classes.iitem}
        xs={6} sm={2} className={classes.iitemS}> <CardActionArea><CardContent>
          <Typography color="textSecondary" component="p" style={sty} >     
          {countCurr}
          </Typography></CardContent></CardActionArea></Grid>

</Grid>  
          
        </Grid>
       </Container> 
       

 
<br/>
<br/>
      <Container maxWidth={false}>
        <Grid container spacing={3}>
      
      <TableContainer component={Paper}>
       <Table className={classes.table} size="small" aria-label="a dense table">
         <TableHead>
           <TableRow>
             <TableCell align="center">رقم الباركود</TableCell>
             <TableCell align="center">الصنف</TableCell>
             <TableCell align="center">الرصيد</TableCell>
             <TableCell align="center">{crossTaqeem}</TableCell>
             <TableCell align="center">إجمالي التكلفة</TableCell>
             <TableCell align="center">سعر البيع</TableCell>
             <TableCell align="center">إجمالي البيع</TableCell>
           </TableRow>
         </TableHead>
         <TableBody>
           {data.map((row) => (
            //  <TableRow key={row.id}>
             <TableRow>
               <TableCell align="center">{row.barcode}</TableCell>
               <TableCell align="center">{row.symbol}</TableCell>
               <TableCell align="center">{row.val}</TableCell>
               <TableCell align="center">{row.purch_price}</TableCell>
               <TableCell align="center">{row.totPurch}</TableCell>
               <TableCell align="center">{row.sale_price}</TableCell>
               <TableCell align="center">{row.totsale}</TableCell>
             </TableRow>
           ))}
         </TableBody>
       </Table>
     </TableContainer>

        </Grid>
      </Container>

      <br/>
      <br/>

      <Container maxWidth={false}>
         <Grid container spacing={3}>
         <Grid container component={Paper}>

      <Grid item 
      // className={classes.iitem}
        xs={2} sm={2} className={classes.iitemS} style={styTitle}><CardActionArea><CardContent>
          <Typography color="textSecondary" component="p" style={sty}  >     
          الإجمالي
          </Typography></CardContent></CardActionArea></Grid>

      <Grid item 
      // className={classes.iitem}
        xs={2} sm={2} className={classes.iitemS}> <CardActionArea><CardContent>
          <Typography color="textSecondary" component="p" style={sty} >     
          {totval}
          </Typography></CardContent></CardActionArea></Grid>

      <Grid item 
      // className={classes.iitem}
        xs={3} sm={2} className={classes.iitemS} style={styTitle}><CardActionArea><CardContent>
          <Typography color="textSecondary" component="p" style={sty}  >     
      إجمالي التكلفة
          </Typography></CardContent></CardActionArea></Grid>

      <Grid item 
      // className={classes.iitem}
        xs={2} sm={2} className={classes.iitemS}> <CardActionArea><CardContent>
          <Typography color="textSecondary" component="p" style={sty} >     
          {totTaqlfa} 
          </Typography></CardContent></CardActionArea></Grid>


      <Grid item 
      // className={classes.iitem}
        xs={3} sm={2} className={classes.iitemS} style={styTitle}><CardActionArea><CardContent>
      <Typography color="textSecondary" component="p" style={sty}  >     
      الإجمالي البيع
      </Typography></CardContent></CardActionArea></Grid>

  <Grid item 
    // className={classes.iitem}
    xs={2} sm={2} className={classes.iitemS}> <CardActionArea><CardContent>
      <Typography color="textSecondary" component="p" style={sty} >     
      {totSale} 
      </Typography></CardContent></CardActionArea></Grid>

         </Grid>  
         </Grid>
         </Container>
 

 <br/>

      </Typography>

  <div style={{textAlign:'center  '}}>
    <Button variant="contained"  color="primary" className={classes.button}
     onClick={toPosh} startIcon={<PrintIcon/>}> معاينة </Button>
  </div>

  <br/>
  <br/>

       <Typography component="div"> <Swipeable/> </Typography>

  </>
  );
  */

};

 export default ReportSafesJard;

