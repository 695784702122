import React, { useState, useEffect } from "react";
import { Rept, sto } from "../../../../Context/InshData";
import Grid from "@material-ui/core/Grid";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";
import { useRptAF } from "../../../../St/Reviews/UseStyle";

function HeaderA({ found }) {
  const cls = useRptAF();

  return (
    <Grid item container className={cls.root}>
      <Grid item xs={5} className={cls.rootsub}>
        <Typography style={{ fontFamily: "Amiri-Regular" }}>
          {found.nameE}
        </Typography>
        <Typography style={{ fontFamily: "Amiri-Regular" }}>
          {found.FieldE}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <ButtonBase>
          <img
            src={`data:image/jpeg;base64,${found.Logo}`}
            className={cls.logo}
            alt={found.nameA}
          />
        </ButtonBase>
      </Grid>
      <Grid item xs={5}>
        <Typography style={{ fontFamily: "Amiri-Regular" }}>
          {found.nameA}
        </Typography>
        <Typography style={{ fontFamily: "Amiri-Regular" }}>
          {found.FieldA}
        </Typography>
      </Grid>

      <Grid item xs={12} className={[cls.title, cls.titleM]}>
        <Typography style={{ fontFamily: "Amiri-Regular" }}>
          {Rept.title}
        </Typography>
      </Grid>

      <Grid item container className={[cls.title, cls.titleS]}>
        <Grid item xs={12} container>
          <Grid item xs={2}>
            {Rept.from} /{" "}
          </Grid>

          <Grid item xs={10} style={{ textAlign: "right" }}>
            {Rept.account.name}
          </Grid>
        </Grid>

        <Grid item container className={cls.titLin2}>
          <Grid item xs={3}>
            {"من تاريخ"}
          </Grid>
          <Grid item xs={3}>
            {Rept.fDate}
          </Grid>
          <Grid item xs={3}>
            {"إلى تاريخ"}
          </Grid>
          <Grid item xs={3}>
            {Rept.tDate}
          </Grid>
        </Grid>

        <Grid item container className={cls.titLin3}>
          <Grid item xs={2}>
            {"المستخدم"}
          </Grid>
          <Grid item xs={3}>
            {"------"}
          </Grid>
          <Grid item xs={2}>
            {"تاريخ الطباعة"}
          </Grid>
          <Grid item xs={3}>
            {Rept.nDate}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default HeaderA;
