import axios from 'axios'
import { sto } from '../Context/InshData';
import { uri } from '../help/Api'
import dataF from '../Server/dataF.json'
export const URL =uri+'SandSD';
export const accountsURL =uri+`accounts-index/ByDoc?docType=${8}`;

export const requestData ={
    /*other:true,*/ // out
    wait:true,
    other:true,
    Users:true,
    Stocks:true, // Error // out
    Banks:true,
    CustomersWTypes: true,
    AccountBooks: true,
    // salesmen:true,
    costcenters:true,
    Employees:true,
    CheckStatus:true,
    PermissionsElemnts:true,
    /*params*/ // out
    parms:{
      ibranchid: sto.branchi, iempid: sto.empi,
      iuserid: sto.idi, 
      icustmtype: 1,
      scurrenciestype: 'I',
      branch: sto.branchi,
      tname:"sandsd",
      iformid: dataF[69].id,
    },
    autos:{
      AccountBooks: true,
      Users:true,
      Safes:true,
      Stocks:true,
      Banks:true,
      Employees:true,
    //   salesmens:true,
      costcenters: true,
      CheckStatus: true,
    }
  }


export function list(){
    return axios.get(URL);
}

export function create(data){
    return axios.post(URL, data);
}

export function update(data){
    return axios.put(URL, data)
}
export function remove(id){
    return axios.delete(URL+`/${id}`)
}

export default{URL,list, create, update, remove}