import React, { useState, useEffect } from 'react';
import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from '@material-ui/lab';
import { useContext } from 'react';
import GlobalContext from '../../../../../Context/GlobalContext';
import { Button, CircularProgress, colors } from '@material-ui/core';
import axios from 'axios';
import { uri } from '../../../../../help/Api';
import { sto } from '../../../../../Context/InshData';
import toast from 'react-hot-toast';
import { getFormatSTransferData } from './footer';


const useStyles = makeStyles((theme) => ({
  main:{
    padding: theme.spacing(1),

  },

  textField3: {
    // margin: 10,
    // margin: theme.spacing(1),
    width: '100%',
    marginBottom:5,
    marginTop:5    
  },
  textField: {
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": { color: 'navy', fontSize: '0.8rem', },
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      color: 'navy', fontSize: '0.7rem',
      lineHeight: '0.5rem',

    }
    , "& .MuiOutlinedInput-inputMarginDense":
      { paddingLeft: 1, paddingRight: 1, paddingTop: 10, paddingBottom: 10, textAlign: 'center', fontSize: '1rem' }, 
  },
  
  root: {
    direction: 'rtl',
    marginBottom: theme.spacing(1),
    boxShadow: 'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;',
    border: 'solid 1px #009688',
    padding:5,
  },
}));


export default function ReceiveSTForm(props) {
  const {object: data, newId,
     formType, safes, branches,onShow } = props;
  const clasess = useStyles();

  const { costCenters, groups} = useContext(GlobalContext);
  const [submitting, setSubmitting] = useState(false);
  const onChangeData = (prop) => (event) =>{

      props.onChange(event, {name: prop, value: event.target.value});
      // setData({ ...data, [prop]: event.target.value })
  } 
  const importSTHandle= async(id)=>{
    setSubmitting(true)
     await axios.get(uri+`StoreTransfer/import?transfer_id=${id}
          &&branch=${sto.branchi}`).then(res=>{
             
              if(res.data?.importState== -1)
                toast.error("رقم تحويل غير موجود")
              else if(res.data?.importState == 0)
                toast.error("تم استخدام رقم التحويل من قبل")
              else{
                 const newData = getFormatSTransferData( {...res.data?.transferData, transfer_no:id, notes:"",}, res.data?.transferItems, branches);
                onShow(newData, 'new');
              }


            }).catch (err=>{
              console.log(err);
              toast.error("لم يتم جلب البيانات")
            });
            setSubmitting(false)
  }

  return (

    <container className={clasess.main}>
      <Grid item className={clasess.root} container  spacing={1}  lg={12} md={12} sm={12} xs={12}>
      <Grid  className={clasess.textField} item lg={2} md={2} sm={2} xs={4}>        
      <TextField type="text" fullWidth variant="outlined"
            size="small" id="stay" label="الرقم "
            name="no" value={formType=='edit'? data.no: newId} InputLabelProps={{ shrink: true }}
            onChange={onChangeData('no')}
            disabled={true}
            />        
            </Grid>

        <Grid className={clasess.textField} item lg={2} md={2} sm={2} xs={4}>
          <form  noValidate>
            <TextField fullWidth variant="outlined"
              id="date"
              label="التاريخ"
              type="date"
              size="small"
              value={data.date}
              onChange={onChangeData('date')}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </form>
        </Grid>
        <Grid className={clasess.textField} item lg={2} md={2} sm={2} xs={4}>        
        <TextField type="text" fullWidth variant="outlined"
            size="small" id="refno" label="رقم المرجع"
            name="refno" value={data.refno}
            onChange={onChangeData('refno')} InputLabelProps={{ shrink: true }}
            />
        </Grid>
        <Grid className={clasess.textField} item lg={2} md={2} sm={2} xs={4}>
          <Autocomplete
            value={data.safe_to}
            onChange={(event, newValue) => {
              // props.onChange(newValue, {name:'safe_to', value: newValue})
              props.onShow({...data, safe_to:newValue,
                rows:[]})
                console.log("object is", data)

            }}

            id="controllable-states-1" size="small"
            options={safes}
            disableClearable
            getOptionLabel={(option)=>option? option.name: ''}
            renderInput={(params) => <TextField  {...params} label="  المخزن " variant="outlined" size="small" />}
          />
        </Grid>
        <Grid className={clasess.textField} item lg={2} md={2} sm={2} xs={4}>
          <Autocomplete
            value={data.safe_from}
            onChange={(event, newValue) => {
              props.onChange(newValue, {name:'safe_from', value: newValue})
            }}

            id="combo-box-demo1" size="small"
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}

            options={safes}
            disableClearable
           
            renderInput={(params) => <TextField  {...params} label="  المخزن المحول منه " variant="outlined" size="small" />}
          />
        </Grid>
        <Grid className={clasess.textField} item lg={2} md={2} sm={2} xs={4}>
          <Autocomplete
            value={data.branch}
            onChange={(event, newValue) => {
              props.onChange(newValue, {name:'branch', value: newValue})
            }}
            id="combo-box-demo1" size="small"
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
            options={branches}
            disableClearable
            renderInput={(params) => <TextField  {...params} label="الفرع" variant="outlined" size="small" />}
          />
        </Grid>

        <Grid className={clasess.textField} item lg={2} md={2} sm={2} xs={4}>
          <Autocomplete
            value={data.center}
            onChange={(event, newValue) => {
               props.onChange(newValue, {name:'center', value: newValue})
            }}
            id="controllable-states-2" size="small"
            options={costCenters || []}
            getOptionLabel={(Option)=>Option? Option.name: ''}

            renderInput={(params) => <TextField  {...params} label=" مركز الكلفة" variant="outlined" size="small" />}
          />
        </Grid>
        <Grid container  spacing={1} item lg={4} md={4} sm={4} xs={4}>    
            <Grid  className={clasess.textField} item lg={8} md={8} sm={8} xs={8}>
              <TextField type="text" fullWidth variant="outlined"
                size="small" id="transfer_no" label="رقم التحويل"
                name="transfer_no" value={data.transfer_no}
                style={{backgroundColor: colors.pink[50],}}
                onChange={onChangeData('transfer_no')} InputLabelProps={{ shrink: true }}

                />
            </Grid>
        
            <Grid className={clasess.textField} item lg={4} md={4} sm={4} xs={4}>        
              <Button  variant="contained" 
                    color="primary" 
                    fontSize="large" size="medium"
                    onClick={()=>importSTHandle(data?.transfer_no)}
                    disabled={submitting} 
                    // startIcon={<SaveeIcon/>}
                    >
                        جلب التحويل 
                       {submitting && <CircularProgress size={20} className={clasess.buttonProgressCAB} /> }
                    </Button>
        </Grid>
        </Grid>
        <Grid className={clasess.textField} item lg={4} md={4} sm={6} xs={12}>        
        <TextField type="text" fullWidth variant="outlined"
            size="small" id="stay" label=" البيان"
            name="notes" value={data.notes} InputLabelProps={{ shrink: true }}
            onChange={onChangeData('notes')}
            />
        </Grid>
      </Grid>
    </container>
  );


};