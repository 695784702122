import React, { useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { colors } from "@material-ui/core";
import { useStylesTF, useStylesLbl } from "../../../../../St/comps/UseStyle";
import ReceiptVoucherType from "../components/ReceiptVoucher-typeFilter";
import GlobleOmlatForm from "../../../../../components/controls/OmlatForm";
import { useTranslation } from "react-i18next";

export default function MultiVoucherForm(props) {
  const {
    onChange,
    AutoOptions,
    changAutoBanks,
    changAutoStocks,
    object,
    onShow,
    formType,
  } = props;
  const clasTF = useStylesTF();
  const claslbl = useStylesLbl();
  const { t } = useTranslation(["forms"]);

  return (
    <React.Fragment>
      <Grid container style={{ padding: 10 }} spacing={2}>
        <Grid item lg={3} md={3} sm={3} xs={4}>
          <TextField
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            id="id"
            label={t("voucher-id")}
            className={clasTF.rootEDS}
            InputProps={{ className: clasTF.input, readOnly: true }}
            name="id"
            value={object.id}
          />
        </Grid>

        <Grid item lg={4} md={4} sm={6} xs={8}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            id="date"
            label={t("date")}
            className={clasTF.rootEDS}
            type="date"
            format="YYYY-MM-DD"
            InputLabelProps={{ shrink: true }}
            name="date"
            value={object.date}
            onChange={(e) =>
              onChange(null, { name: "date", value: e.target.value })
            }
          />
        </Grid>
        <Grid item lg={5} md={5} sm={6} xs={6}>
          <Autocomplete
            value={object.center}
            onChange={(event, newValue) => {
              onChange(null, { name: "center", value: newValue });
            }}
            id="controllable-states-demo"
            size="small"
            options={AutoOptions.costcenters}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            // style={{  }}
            disableClearable
            renderInput={(params) => (
              <TextField
                id="valueCostcenter"
                {...params}
                label={t("center")}
                variant="outlined"
                size="small"
                InputLabelProps={{ className: claslbl.clor }}
              />
            )}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <Autocomplete
            value={object.salesmen}
            onChange={(event, newValue) => {
              onChange(null, { name: "salesmen", value: newValue });
            }}
            id="controllable-states-demo4"
            size="small"
            options={AutoOptions.salesmen}
            // getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            disableClearable
            renderInput={(params) => (
              <TextField
                id="valueSalesmen"
                {...params}
                label={t("delegate")}
                variant="outlined"
                size="small"
                InputLabelProps={{ className: claslbl.clor }}
              />
            )}
          />
        </Grid>

        <Grid item lg={12} md={12} sm={12} sx={12}>
          <ReceiptVoucherType
            {...{
              type: object.type,
              onChange,
              isMulti: true,
            }}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          {object.type == "1" ? (
            <Autocomplete
              value={object.stock}
              onChange={(event, newValue) => {
                changAutoStocks(newValue);
              }}
              id="controllable-states-demoew"
              size="small"
              options={AutoOptions.Stocks}
              style={{ backgroundColor: colors.blueGrey[50] }}
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option.name
              }
              // style={{  }}

              disableClearable
              renderInput={(params) => (
                <TextField
                  id="valueCostcenter"
                  {...params}
                  label={t("stock")}
                  variant="outlined"
                  size="small"
                  InputLabelProps={{ className: claslbl.clor }}
                />
              )}
            />
          ) : (
            <Autocomplete
              value={object.bank}
              onChange={(event, newValue) => {
                changAutoBanks(newValue);
              }}
              id="controllable-states-demo67"
              size="small"
              options={AutoOptions.Banks}
              style={{ backgroundColor: colors.orange[50] }}
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option.name
              }
              // style={{  }}
              disableClearable
              renderInput={(params) => (
                <TextField
                  id="valueCostcenter"
                  {...params}
                  label={t("bank")}
                  variant="outlined"
                  size="small"
                  InputLabelProps={{ className: claslbl.clor }}
                />
              )}
            />
          )}
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <TextField
            type="number"
            fullWidth
            variant="outlined"
            size="small"
            id="check_no"
            label={t("check-no")}
            className={object.type == "1" ? null : clasTF.rootEDS}
            onChange={(e) =>
              onChange(null, { name: "check_no", value: e.target.value })
            }
            name="check_no"
            value={object.check_no}
            disabled={object.type == "1"}
          />
        </Grid>

        <Grid item lg={6} md={6} sm={6} xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            id="check_date"
            label={t("check-date")}
            className={object.type == "1" ? null : clasTF.rootEDS}
            type="date"
            format="YYYY-MM-DD"
            InputLabelProps={{ shrink: true }}
            name="check_date"
            value={object.check_date}
            onChange={(e) =>
              onChange(null, { name: "check_date", value: e.target.value })
            }
            disabled={object.type == "1"}
          />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          {object.type == "1" ? (
            <GlobleOmlatForm
              {...{
                omlat: object.stock_omlat,
                omla: object.stock_omla,
                disabled: false,
                onExchangeChange: (value) => {
                  let newObject = {
                    ...object,
                    stock_omla: { ...object.stock_omla, exchange: value },
                    tot_val1: object.val1 * value,
                  };
                  onShow(newObject, formType);
                },
                onChange: (newValue) => {
                  let newObject = {
                    ...object,
                    stock_omla: newValue,
                    tot_val1: object.val1 * newValue.exchange,
                  };
                  onShow(newObject, formType);
                },
                amount: object.val1,
              }}
            />
          ) : (
            <GlobleOmlatForm
              {...{
                omlat: object.bank_omlat,
                omla: object.bank_omla,
                disabled: false,
                onExchangeChange: (value) => {
                  let newObject = {
                    ...object,
                    bank_omla: { ...object.bank_omla, exchange: value },
                    tot_val1: object.val1 * value,
                  };
                  onShow(newObject, formType);
                },
                onChange: (newValue) => {
                  let newObject = {
                    ...object,
                    bank_omla: newValue,
                    tot_val1: object.val1 * newValue.exchange,
                  };
                  onShow(newObject, formType);
                },
                amount: object.val1,
              }}
            />
          )}
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            id="notes"
            label={t("note")}
            className={clasTF.rootEDS}
            InputProps={{ className: clasTF.input }}
            name="notes"
            value={object.notes}
            onChange={(e) =>
              onChange(null, { name: "notes", value: e.target.value })
            }
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
