import React, { useState, useEffect } from "react";

import axios from "axios";
import { uri } from "../../../../help/Api";
import { Rept, sto } from "../../../../Context/InshData";
import Grid from "@material-ui/core/Grid";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";
import { useRptAF } from "../../../../St/Reviews/UseStyle";

function HeaderA() {
  const cls = useRptAF();
  const iInfo = JSON.parse(localStorage.getItem("iInfos")) || {};
  const [state, setState] = useState({});
  const getInfo = async () => {
    axios
      .get(uri + `Foundation?branch=${sto.branchi}`)
      .then((res) => {
        setState(res.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <Grid item container className={cls.root}>
      <Grid item xs={5} className={cls.rootsub}>
        <Typography>{state.nameE}</Typography>
        <Typography>{state.FieldE}</Typography>
      </Grid>
      <Grid item xs={2}>
        <ButtonBase>
          <img
            src={`data:image/jpeg;base64,${state.Logo}`}
            className={cls.logo}
            alt={state.nameA}
          />
        </ButtonBase>
      </Grid>
      <Grid item xs={5} className={cls.rootsub}>
        <Typography>{state.nameA}</Typography>
        <Typography>{state.FieldA}</Typography>
      </Grid>

      <Grid item xs={12} className={[cls.title, cls.titleM]}>
        <Typography>{iInfo.title}</Typography>
      </Grid>

      <Grid item container className={[cls.title, cls.titleS]}>
        <Grid item container className={cls.titLin1}>
          <Grid item xs={3}></Grid>
          <Grid item xs={3}>
            {"طريقة الدفع"}
          </Grid>
          <Grid item xs={1}>
            {" "}
            /{" "}
          </Grid>
          <Grid item xs={3} className={cls.tlR}>
            {Rept.auto}
          </Grid>
        </Grid>

        <Grid item container className={cls.titLin2}>
          <Grid item xs={3}>
            {"من تاريخ"}
          </Grid>
          <Grid item xs={3}>
            {Rept.fDate}
          </Grid>
          <Grid item xs={3}>
            {"إلى تاريخ"}
          </Grid>
          <Grid item xs={3}>
            {Rept.tDate}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  // return (

  // <Grid item container justifyContent='center' spacing={0}>

  //   <Grid item container className="">

  //   </Grid>

  //   <Grid item container className="">
  //  </Grid>

  //   <Grid item container className="">
  //  </Grid>
  // </Grid>

  // </Grid>
  // );
}
export default HeaderA;
