import { Button, Chip, colors, Grid, Paper } from "@material-ui/core";
import React from "react";

import { useEffect } from "react";
import ProductCard from "./item-card";
import { useState } from "react";
import axios from "axios";
import { uri } from "../../help/Api";
import { BeatLoader } from "react-spinners";
import { useStylesSpDial, useStylesUiElemment } from "../../St/comps/UseStyle";
import { sto } from "../../Context/InshData";

function ProductsGrid(props) {
  const { groupId, values } = props;
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadmore, setLoadmore] = useState(true);
  const clasui = useStylesUiElemment();
  const clasSp = useStylesSpDial();

  const loadItems = async (pg) => {
    if (pg == 0) setData([]);
    setLoading(true);
    const tb = {
      Currencies: true, // wait:true,
      parms: {
        scurrenciestype: "E",
        iempid: sto.empi,
        safe: -1,
        currShow: "M",
        currType: 2,
        igroupid: groupId,
        takei: 50,
        page: pg * 50,
        sSearch: "",
      },
      autos: {},
    };
    await axios
      .post(uri + "Res", tb)
      .then((res) => {
        let oldData = pg == 0 ? [] : data;
        setData([...oldData, ...res.data.Currencies]);
        console.log("the currenciesi ", res.data.Currencies);
        console.log("no", tb);
        setPage(pg + 1);
        console.log("page", pg);
        setLoadmore(res.data.Currencies.length == 50);

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    const load = async () => {
      await loadItems(0);
    };

    load();
  }, [groupId]);

  return (
    <Paper style={{ padding: "8px" }}>
      <Grid container spacing={1}>
        {data?.map((item, i) => {
          const index = values.findIndex((x) => x.barcode == item.barcode);
          if (index != -1) console.log("the value", values[index].val1);
          const count = index == -1 ? 0 : values[index].val1;
          return (
            <Grid item key={i} lg={2} md={3} sm={3} xs={6}>
              <ProductCard
                item={item}
                onClick={props.onSelectItem}
                count={count}
              />
            </Grid>
          );
        })}
        <Grid xs={12} container justifyContent="center">
          <BeatLoader loading={loading} color={colors.green[300]} />
        </Grid>
        {loadmore && (
          <Grid xs={12} container justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={[clasui.button, clasSp.clSec]}
              disabled={loading}
              // style={{ ...inshst.sal.iconBtnRpt, borderRadius: 0 }}
              onClick={() => loadItems(page)}
            >
              تحميل المزيد
            </Button>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
}

export default ProductsGrid;
