import React, { useState, useEffect } from "react";

import { SAdefaultValues } from "../../../../../services/saleAnalysis-services";
import {
  Button,
  Chip,
  colors,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import ProfitMarginForm from "./ProfitMargin-form";
import ProfitMarginTable from "./ProfitMargin-table";
import useFetchURL from "../../../../../components/Hooks/useFetchURL";
import { uri } from "../../../../../help/Api";
import TotalCard from "../../../../../components/TotalCard";
import { useHistory } from "react-router-dom";
import {
  useStylesHed,
  useStylesSpDial,
  useStylesUiElemment,
} from "../../../../../St/comps/UseStyle";
import { insh as inshst } from "../../../../../Context/InshSt";
import ScannerIcon from "@material-ui/icons/Scanner";
import KeyboardReturnSharpIcon from "@material-ui/icons/KeyboardReturnSharp";
import PrintIcon from "@material-ui/icons/Print";
import { useTranslation } from "react-i18next";
import BeatLoader from "react-spinners/BeatLoader";
import LoadingPage from "../../../../../services/LoadingPage";
import i18next from "i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
  },
  itemW: {
    // display:'flex',
    width: "90%",
  },
  headerTitle: {
    backgroundColor: "#fff",
    padding: 5,
    textAlign: "center",
    fontSize: 24,
    width: "100%",
  },

  allWidth: {
    minWidth: 650,
  },
  checStyl: {
    marginTop: "17px",
    fontSize: "16px",
    padding: "-1px",
  },
}));

export default function ProfitMargin(props) {
  const { AutoOptions, CustomerOptions } = props;
  const [rptParams, setRptParams] = React.useState(SAdefaultValues);
  const classes = useStyles();
  const clasui = useStylesUiElemment();
  const clasSp = useStylesSpDial();
  const { t } = useTranslation(["forms", "translation"]);
  const { data, fetching, fetchURL } = useFetchURL({
    ...{
      url:
        uri +
        `RptSales/SalesAnalysis?type_Select=${"Currencies"}
    &&branch=${-1}
    &&dateFrom=${rptParams.dateFrom}
    &&dateTo=${rptParams.dateTo}
    &&stock=${rptParams.stock.id}
    &&cust=${rptParams.cust.id}
    &&safe=${rptParams.safe.id}
    &&sales_man=${-1}
    &&groups=${rptParams.group.id}
    &&user=${rptParams.user.id}
    &&chk_withvat=${rptParams.chk_withvat}
    &&type_cost=${0}
    &&isPurch=${false}`,
      dependencyArray: [],
    },
  });

  const his = useHistory();

  const printHandle = () => {
    const info = {
      ...rptParams,
      data: data?.saleAnalysis,
      totals: data?.totals,
    };

    his.push({ pathname: "/AnalysisPrint", state: info });

    window.location.reload(false);
  };

  const onChangeData = (propName) => (event) => {
    setRptParams({ ...rptParams, [propName]: event.target.value });
  };
  const onChangeAutoData = (propName, newValue) => {
    setRptParams({ ...rptParams, [propName]: newValue });
  };
  return (
    <div className={classes.root}>
      <Grid container item xs={12}>
        <Typography
          size="small"
          className={classes.headerTitle}
          color="primary"
        >
          {t("translation:profit-margin")}
        </Typography>
      </Grid>
      <Typography
        component="div"
        style={{ direction: i18next.language == "ar" ? "rtl" : "ltr" }}
      >
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
            paddingBottom: "0px",
            borderBottomColor: colors.indigo[300],
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
          }}
        >
          <ProfitMarginForm
            {...{
              AutoOptions,
              CustomerOptions,
              rptParams,
              onChangeData,
              onChangeAutoData,
              printHandle,
            }}
          />
          <Grid
            container
            justifyContent="center"
            lg={12}
            md={12}
            sm={12}
            xs={12}
            spacing={1}
          >
            <Grid item>
              <Button
                disabled={fetching}
                variant="contained"
                startIcon={<ScannerIcon />}
                size="large"
                style={{ ...inshst.sal.iconBtnRpt, borderRadius: 0 }}
                className={[clasui.button, clasSp.clSec]}
                onClick={() => fetchURL()}
              >
                {t("forms:view")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                startIcon={<PrintIcon />}
                color="primary"
                size="large"
                className={[clasui.button, clasSp.clSec]}
                disabled={true}
                style={{ ...inshst.sal.iconBtnRpt, borderRadius: 0 }}
                onClick={printHandle}
              >
                {t("forms:print")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                startIcon={<KeyboardReturnSharpIcon />}
                color="secondary"
                size="large"
                className={[clasui.button, clasSp.clcan]}
                style={{ ...inshst.sal.iconBtnRpt, borderRadius: 0 }}
                onClick={() => {
                  his.push({ pathname: "/" });
                }}
              >
                {t("forms:exit")}
              </Button>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth={true} style={{ padding: "4px" }}>
          <Grid container spacing={1} style={{ paddingTop: "10px" }}>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  title: t("forms:net-quantity"),
                  value: data?.totals?.qtyTotal.toFixed(2),
                }}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  title: t("forms:net-total"),
                  value: data?.totals?.totNetTotal.toFixed(2),
                }}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  title: t("forms:net-discount"),
                  value: data?.totals?.minusTotal.toFixed(2),
                }}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  title: t("forms:net-cost"),
                  value: data?.totals?.costTotal.toFixed(2),
                }}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  title: t("forms:net-cost"),
                  value: data?.totals?.vatTotal.toFixed(2),
                }}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  title: t("forms:net-profit"),
                  value: data?.totals?.profitTotal.toFixed(2),
                }}
              />
            </Grid>
          </Grid>
        </Container>
        <br />
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
          }}
        >
          <Grid container justifyContent="center">
            <Grid item container justifyContent="center" xs={12}>
              <BeatLoader loading={fetching} color={colors.indigo[300]} />
              {!fetching && (
                <ProfitMarginTable
                  {...{
                    data: data?.saleAnalysis ? data.saleAnalysis : [],
                    title: rptParams.selectType.label,
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Container>
      </Typography>
    </div>
  );
}
