import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ReplyIcon from "@material-ui/icons/Reply";

import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Container from "@material-ui/core/Container";
import Hidden from "@material-ui/core/Hidden";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  useStylesHed,
  useStylesBA,
  useStylesUp,
} from "../../../../../St/comps/UseStyle";

import { LinearProgress, Typography } from "@material-ui/core";

import useFetchURL from "../../../../../components/Hooks/useFetchURL";
import { uri } from "../../../../../help/Api";
import { useEffect } from "react";
import SearchInvForm from "./search-form";
import SearchInvTable from "./search-table";
import { initialSearchObject } from "../../../../../services/invoice-service";

export default function SearchInvoiceModal(props) {
  const {
    openDw,
    handCloseDw,
    transition,
    showChosedInv,
    invsShow,
    stElemnt,
    style,
    gets,
    setOr,
    or,
    optionsCustomers,
    PcIdIvPc,
    setting,
  } = props;

  const [searchPrms, setSearchParms] = useState(initialSearchObject);
  const [customers, setCustomers] = useState(() => {
    return [{ id: -1, name: "الكل" }, ...optionsCustomers];
  });

  const banks = [{ id: -1, name: "الكل" }, ...gets.Banks];
  const branches = [{ id: -1, name: "الكل" }, ...gets.Branches];
  const users = [{ id: -1, name: "الكل", emp: -1 }, ...gets.Users];

  const { data, fetching, fetchURL } = useFetchURL({
    ...{
      url:
        uri +
        `FullSearch?PcIdIvPc=${PcIdIvPc}
        &&branch=${searchPrms.branch?.id}
        &&BillNo=${searchPrms.bill_no}
        &&dateFrom=${searchPrms.dateFrom + " " + searchPrms?.timeFrom}
        &&dateTo=${searchPrms.dateTo + " " + searchPrms?.timeTo}
        &&refNo=${searchPrms.refno}
        &&inv_type=${searchPrms.inv_type?.id}
        &&pay_type=${searchPrms.pay_type?.id}
        &&cust_id=${searchPrms.customer?.id}
        &&cust_mobile=${searchPrms?.cust_mobile}
        &&sales_emp=${searchPrms?.sales_emp?.emp}
        &&bank=${searchPrms.bank?.id}`,
      dependencyArray: [],
    },
  });

  // useEffect(() => {
  //   console.log("the data is", gets);
  // });

  const clasup = useStylesUp();
  const clashd = useStylesHed();
  const clasBA = useStylesBA();

  const onChangeData = (propName) => (event) => {
    setSearchParms({ ...searchPrms, [propName]: event.target.value });
  };
  const onChangeAutoData = (propName, newValue) => {
    setSearchParms({ ...searchPrms, [propName]: newValue });
  };

  return (
    <Dialog
      fullScreen
      open={openDw}
      onClose={handCloseDw}
      TransitionComponent={transition}
      key={transition ? transition.name : ""}
    >
      <div className={clasBA.continarBASer}>
        <React.Fragment>
          <Grid item container spacing={0} className={clashd.rootHd}>
            <Grid item xs={12}>
              <Chip
                size="small"
                className={clashd.ChipHdSale}
                label={"بـحـث فـي الـفـواتـيـر"}
                color="primary"
              />
            </Grid>
          </Grid>

          <CssBaseline />
          <Container maxWidth="xl" className={clasup.AboverootupSer}>
            <SearchInvForm
              {...{
                searchPrms,
                fetchURL,
                fetching,
                onChangeAutoData,
                onChangeData,
                gets,
                customers,
                banks,
                branches,
                users,
              }}
            />
            {/* S front B */}
            <Grid item container className={clasup.rootup}>
              {/* S MatTableOld */}

              <Grid item md={12} sm={12} xs={12}>
                {fetching ? (
                  <LinearProgress />
                ) : (
                  <SearchInvTable
                    {...{ data, showChosedInv, stElemnt, setOr, or, setting }}
                  />
                )}
              </Grid>

              {/* E MatTableNew */}
            </Grid>
            {/* E front B */}
            <br /> <br />
          </Container>
        </React.Fragment>
      </div>

      {/* ====================================== end ======================================== */}
      <React.Fragment>
        <CssBaseline />
        <AppBar
          position="fixed"
          color="primary"
          // className={clasBA.appBarBASal}
          style={{ ...style.bgImg, ...stElemnt.all.appBarBA }}
        >
          <Toolbar variant="dense">
            <Hidden smUp={["sm"]}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={handCloseDw}
              >
                <CloseIcon />
              </IconButton>
            </Hidden>

            <Hidden only={["xs"]}>
              <Button
                style={{ color: "#fff" }}
                onClick={handCloseDw}
                startIcon={<ReplyIcon />}
              >
                الفاتورة
              </Button>
            </Hidden>
            <Typography style={{ marginLeft: "20px" }}>
              {"الإجمالي: "}
              {data.reduce((a, v) => (a = a + v.net_total), 0).toFixed(2)}
            </Typography>
            <div className={clasBA.growBA} />
          </Toolbar>
        </AppBar>
      </React.Fragment>
    </Dialog>
  );
}

// const getInvs = async (from, to, type, utype) => {
//   if (type == null) {
//     await axios
//       .get(
//         uri +
//           "InvDate" +
//           `?id=${sto.empi}&&dateF=${from}&&dateT=${to}&&PcIdIvPc=${setting.PrTy_InPr}&&uType=${utype}&&branch=${sto.branchi}`
//       )
//       .then((res) => {
//         cl("===res.data");
//         cl(res.data);
//         setInvsShow(res.data);
//         setInvsShowFltr(res.data);
//       })
//       .catch((error) => {});
//   } else {
//     await axios
//       .get(
//         uri +
//           "InvDate" +
//           `?id=${sto.empi}&&dateF=${from}&&dateT=${to}&&itype=${type}&&PcIdIvPc=${setting.PrTy_InPr}&&uType=${utype}&&branch=${sto.branchi}`
//       )
//       .then((res) => {
//         cl("===res.data");
//         cl(res.data);
//         setInvsShow(res.data);
//         setInvsShowFltr(res.data);
//       })
//       .catch((error) => {});
//   }
// };

// const getInvst = async (from, to, type, utype) => {
//   if (type == null) {
//     await axios
//       .get(
//         uri +
//           "InvDatet" +
//           `?id=${sto.empi}&&dateF=${from}&&dateT=${to}&&PcIdIvPc=${setting.PrTy_InPr}&&uType=${utype}&&bran=${sto.branchi}`
//       )
//       .then((res) => {
//         cl("===res.data");
//         cl(res.data);
//         setInvsShowt(res.data);
//         setInvsShowFltr(res.data);
//       })
//       .catch((error) => {});
//   } else {
//     await axios
//       .get(
//         uri +
//           "InvDatet" +
//           `?id=${sto.empi}&&dateF=${from}&&dateT=${to}&&itype=${type}&&PcIdIvPc=${setting.PrTy_InPr}&&uType=${utype}&&bran=${sto.branchi}`
//       )
//       .then((res) => {
//         cl("===res.data");
//         cl(res.data);
//         setInvsShow(res.data);
//         setInvsShowFltr(res.data);
//       })
//       .catch((error) => {});
//   }
// };
