import React, { useState,useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import PrintIcon from '@material-ui/icons/Print';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HomeIcon from '@material-ui/icons/Home';
import CancelIcon from '@material-ui/icons/Cancel';
import Header from '../../Layout/Header';
import Footer from '../../Layout/Footer';
import Body from './Body';
import  HeaderA from './A4/HeaderA';
import  BodyA  from './A4/BodyA';
import  FooterA from './A4/FooterA';
import { useHistory } from 'react-router-dom';

import {useStylesSpDial} from '../../../St/Reviews/UseStyle';
import {stElemnt} from '../../../St/Reviews/cStyle';


class ComponentToPrint extends React.Component {
  state = { }
constructor(){super();}

  render() {  
    return (
      <Container maxWidth='xl' style={stElemnt.AF.Reviws.continr}> 
        <CssBaseline />
        <Typography component="div" style={stElemnt.AF.Reviws.Typogmain}>
        <Typography component="div" style={stElemnt.AF.Reviws.Typogsub}>
          <HeaderA/>
          <BodyA/>
          <FooterA/>
          </Typography>
        </Typography>
      </Container> 
      );
  }
}


const ReviewSalTafsele = () => {
  const componentRef = useRef();
  const clasSp = useStylesSpDial();

  const [open, setOpen] = useState(true);
  const handleOpen = () => { setOpen(true); };
  const handleClose = () => { setOpen(true); };
  let his = useHistory();

  const back = (path) =>
  {
    path ? his.push({ pathname: '/'}) : his.goBack();

// his.push({ pathname: '/',
// // state: {idata:insh.IdataSaO,
// //   setting:{ 
// //titleHed:txtA.invName.sale,cusT:1,cusN:txtA.cusNA},
// // },
// });

  }

//   const back = () => his.push({ pathname: '/',

//   // state: {idata:insh.IdataSaO,
//   //   setting:{ titleHed:txtA.invName.sale,cusT:1,cusN:txtA.cusNA},
//   // },
// });

  const actions = [
    { icon: <ReactToPrint trigger={() => <PrintIcon /> } content={() => componentRef.current} />, name: 'طباعـة' },
    { icon: <ArrowBackIcon className={clasSp.ico} onClick={()=> back(false)}  />, name: 'رجـوع' },
  ];

  return (
    <div className={clasSp.root}>
      <ComponentToPrint ref={componentRef} />
      <SpeedDial ariaLabel="SpeedDial openIcon example"
        className={clasSp.speedDial} hidden={false}
        icon={<SpeedDialIcon openIcon={<HomeIcon />} onClick={()=> back(true)} />}
        onClose={handleClose} onOpen={handleOpen} open={open}>
        {actions.map((action,i) => ( <SpeedDialAction key={i}
         icon={action.icon} tooltipTitle={action.name} onClick={handleClose} />))}
      </SpeedDial>
    </div>
  );
};


export default ReviewSalTafsele;


