import React, { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { typeCosts, typeQties } from "../../../../services/safe-grd-services";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@material-ui/core";

export default function SafeGrdForm(props) {
  const { rptParams, onChangeData, AutoOptions, onChangeAutoData } = props;

  return (
    <React.Fragment>
      <Grid container style={{ padding: 5 }} spacing={1}>
        <Grid item lg={2} md={2} sm={2} xs={6}>
          <Autocomplete
            value={rptParams.safe}
            onChange={(event, newValue) => {
              onChangeAutoData("safe", newValue);
            }}
            id="combo-box-demo"
            size="small"
            options={AutoOptions.Safes}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            style={{}}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                label="المخزن"
                variant="outlined"
              />
            )}
          />
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={6}>
          <Autocomplete
            value={rptParams.typeCost}
            onChange={(event, newValue) => {
              onChangeAutoData("typeCost", newValue);
            }}
            id="combo-box-demo"
            size="small"
            options={typeCosts}
            //getOptionLabel={(option) => option.name}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            style={{}}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                label="طريقة التقييم "
                variant="outlined"
              />
            )}
          />
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={6}>
          <Autocomplete
            value={rptParams.typeQtys}
            onChange={(event, newValue) => {
              onChangeAutoData("typeQtys", newValue);
            }}
            id="combo-box-demo"
            size="small"
            options={typeQties}
            //getOptionLabel={(option) => option.name}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            style={{}}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                label="فرز حسب"
                variant="outlined"
              />
            )}
          />
        </Grid>

        <Grid item lg={4} md={4} sm={4} xs={6}>
          <FormControl component="fieldset">
            <FormLabel component="legend">نوع الصنف</FormLabel>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={rptParams.isStore}
                    onChange={onChangeData}
                    name="isStore"
                  />
                }
                size="small"
                color="primary"
                fontSize="small"
                label="مخزني"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={rptParams.isRaw}
                    onChange={onChangeData}
                    name="isRaw"
                  />
                }
                label="خام"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={rptParams.isFactory}
                    onChange={onChangeData}
                    name="isFactory"
                  />
                }
                label="مصنعي"
              />
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
