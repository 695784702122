import React, { useState } from "react";
import { colors, Grid, Typography } from "@material-ui/core";
import Barcode from "react-barcode/lib/react-barcode";
import { sto } from "../../../../../Context/InshData";

function BarcodeGeneratorTemplate02(props) {
  const { barcode, itemName, price, prody_date, expiry_date } = props;

  return (
    <div
      style={{
        textAlign: "center",
        backgroundColor: "#fff",
        paddingTop: "5px",
        paddingBottom: "0px",
        paddingInline: "5px",
      }}
    >
      <Typography
        style={
          {
            // padding: "0",
            // fontSize: "10px",
            // marginBottom: "0px",
            // backgroundColor: colors.grey[900],
            // color: "#fff",
            // zIndex: 100,
          }
        }
      >
        <span
          style={{
            padding: "2px",
            fontSize: "10px",
            fontWeight: "bold",
            backgroundColor: colors.grey[900],
            color: "#fff",
            // zIndex: 100,
          }}
        >
          {/* {itemName} */}
          {sto?.found}
        </span>
      </Typography>
      <Barcode
        id="mybarcode2"
        value={barcode}
        // background="#fff"
        // lineColor="black"
        width="1"
        height="20"
        // format="EAN13"
        displayValue="false"
        // font="monospace"
        // textAlign="center"
        // textPosition="bottom"
        // textMargin="0"
        // fontSize="14"
        marginTop="2"
        marginBottom="1"
      />
      <div style={{ direction: "rtl", textAlign: "center" }}>
        <Grid container xs={12} style={{ fontSize: "9px" }}>
          <Grid item xs={8}>
            {itemName}
          </Grid>
          <Grid item xs={4}>
            {" "}
            {price}
            {"رس  "}
          </Grid>
        </Grid>
        <Grid
          container
          xs={12}
          style={{
            fontSize: "8px",
          }}
        >
          <Grid item xs={6}>
            {prody_date}
          </Grid>
          <Grid item xs={6}>
            {" "}
            {expiry_date}
          </Grid>
        </Grid>
      </div>
      {/* <Typography
        style={{
          direction: "rtl",
          marginTop: "-10px",
          // fontSize: "10px",
          fontWeight: "bold",
          backgroundColor: "transparent",
          padding: "0",
        }}
        // color={"primary"}
      >
        {" "}
        {price} {"رس  "}
      </Typography> */}
    </div>
  );
}

export default BarcodeGeneratorTemplate02;
