import axios from "axios";
import { uri } from "../help/Api";

export function useAPI(subURL) {
  const URL = uri + subURL;
  function list() {
    return axios.get(URL);
  }
  function getNewId() {
    return axios.get(URL + "/newId");
  }
  function createFun(data) {
    return axios.post(URL, data);
  }

  function updateFun(data) {
    return axios.put(URL, data);
  }
  function removeFun(id) {
    return axios.delete(URL + `/${id}`);
  }

  return { URL, getNewId, list, createFun, updateFun, removeFun };
}
