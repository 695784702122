import React, { useState, useEffect } from "react";

import {
  Button,
  colors,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import EmpSalesForm from "./EmpSales-form";
import EmpSalesTable from "./EmpSales-table";
import useFetchURL from "../../../../../components/Hooks/useFetchURL";
import { uri } from "../../../../../help/Api";
import TotalCard from "../../../../../components/TotalCard";
import moment from "moment";
import BackdropSc from "../../../../../services/BackdropSc";
import { BeatLoader } from "react-spinners";
import { insh as inshst } from "../../../../../Context/InshSt";
import ScannerIcon from "@material-ui/icons/Scanner";
import KeyboardReturnSharpIcon from "@material-ui/icons/KeyboardReturnSharp";
import PrintIcon from "@material-ui/icons/Print";
import {
  useStylesSpDial,
  useStylesUiElemment,
} from "../../../../../St/comps/UseStyle";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { sto } from "../../../../../Context/InshData";
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
  },
  itemW: {
    // display:'flex',
    width: "90%",
  },
  headerTitle: {
    textAlign: "center",
    fontSize: 20,
    width: "100%",
  },
  allWidth: {
    minWidth: 650,
  },
  checStyl: {
    marginTop: "17px",
    fontSize: "16px",
    padding: "-1px",
  },
}));

const SAdefaultValues = {
  dateFrom: moment(new Date()).format("YYYY-MM-DD"),
  dateTo: moment(new Date()).format("YYYY-MM-DD"),
  emp: { id: -1, name: "الكل" },
};

export default function EmpSales(props) {
  const { AutoOptions, isSale } = props;
  const [rptParams, setRptParams] = React.useState(SAdefaultValues);
  const { t } = useTranslation(["forms", "translation"]);
  const his = useHistory();
  const classes = useStyles();
  const clasui = useStylesUiElemment();
  const clasSp = useStylesSpDial();
  const { data, fetching, fetchURL } = useFetchURL({
    ...{
      url:
        uri +
        `RptSales/EmpSales?type=${isSale ? "Sale" : "Purches"}&&branch=${-1}
    &&dateFrom=${rptParams.dateFrom}
    &&dateTo=${rptParams.dateTo}
    &&emp=${rptParams.emp.id}
    &&user=${sto.idi}`,
      dependencyArray: [],
    },
  });

  const onChangeData = (propName) => (event) => {
    setRptParams({ ...rptParams, [propName]: event.target.value });
  };
  const onChangeAutoData = (propName, newValue) => {
    setRptParams({ ...rptParams, [propName]: newValue });
  };
  return (
    <div className={classes.root}>
      <Grid container item xs={12}>
        <Typography
          size="small"
          className={classes.headerTitle}
          style={{
            backgroundColor: isSale ? colors.green[50] : colors.indigo[50],
          }}
          // color="primary"
        >
          {isSale
            ? t("translation:sale-employee")
            : t("translation:purch-employee")}
        </Typography>
      </Grid>
      <Typography component="div" style={{ direction: "rtl" }}>
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
            borderBottomColor: colors.indigo[300],
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
          }}
        >
          <EmpSalesForm
            {...{ AutoOptions, rptParams, onChangeData, onChangeAutoData }}
          />
          <Grid
            container
            justifyContent="center"
            lg={12}
            md={12}
            sm={12}
            xs={12}
            spacing={1}
          >
            <Grid item>
              <Button
                disabled={fetching}
                variant="contained"
                startIcon={<ScannerIcon />}
                size="large"
                style={isSale ? inshst.sal.iconBtn : inshst.purch.iconBtn}
                className={[clasui.button]}
                onClick={() => fetchURL()}
              >
                {t("forms:view")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={true}
                variant="contained"
                startIcon={<PrintIcon />}
                color="primary"
                size="large"
                className={[clasui.button, clasSp.clSec]}
                style={isSale ? inshst.sal.iconBtn : inshst.purch.iconBtn}
                // onClick={printHandle}
              >
                {t("forms:print")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                startIcon={<KeyboardReturnSharpIcon />}
                color="secondary"
                size="large"
                className={[clasui.button, clasSp.clcan]}
                style={isSale ? inshst.sal.iconBtn : inshst.purch.iconBtn}
                onClick={() => his.goBack()}
              >
                {t("forms:exit")}
              </Button>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth={true} style={{ padding: "4px" }}>
          <Grid container spacing={1} style={{ paddingTop: "10px" }}>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  title: "الإجمالي",
                  value: data
                    .reduce((a, v) => (a = a + v.sum_Price), 0)
                    ?.toFixed(2),
                }}
              />
            </Grid>
          </Grid>
        </Container>
        <br />
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
          }}
        >
          <Grid container justifyContent="center">
            <Grid item container justifyContent="center" xs={12}>
              <BeatLoader
                loading={fetching}
                color={isSale ? colors.green[300] : colors.indigo[300]}
              />
              {!fetching && (
                <EmpSalesTable
                  {...{ data: data?.length > 0 ? data : [], isSale }}
                />
              )}
            </Grid>
          </Grid>
        </Container>
      </Typography>
    </div>
  );
}
