import React, { useState, useEffect } from "react";
import {
  Button,
  Chip,
  colors,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";

import { uri } from "../../../help/Api";

import LoadingPage from "../../../services/LoadingPage";
import useFormDataCRUD from "../../../components/Hooks/useEDSCURD";
import { useAPI } from "../../../api/useAPI";
import i18next from "i18next";
import {
  defalutPrintOPS,
  titleOptions,
} from "../../../services/settings-services";

import ChipsSelect from "../../../components/chips-select";
import SaveIcon from "@material-ui/icons/Save";
import OraxPrint from "./orax-print";
import { sto } from "../../../Context/InshData";
import useFetchObjectByPost from "../../../components/Hooks/useFetchObjectByPost";

const useStyles = makeStyles((theme) => ({
  root: {
    // minHeight: '100%',
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(10),
  },
  itemW: {
    // display:'flex',
    width: "90%",
  },

  allWidth: {
    minWidth: 650,
  },
  checStyl: {
    marginTop: "17px",
    fontSize: "16px",
    padding: "-1px",
  },
  avatar: {
    backgroundColor: colors.grey[800],
    color: "#fff",
    height: 46,
    width: 46,
    marginTop: -5,
  },
  icon: {
    height: 32,
    width: 32,
  },
}));

export function PrintDefSettings(props) {
  const { permisApp, selectedId, setSelectedId, fetchURL } = props;
  const classes = useStyles();
  const { updateFun } = useAPI("PermissionApp");
  const {
    onChange,
    submit,
    update,
    remove,
    submitting,
    errorMessage,
    onShow,
    onEnd,
    object,
    formType,
  } = useFormDataCRUD(
    () => {},
    updateFun,
    () => {},
    undefined,
    { ...permisApp },
    null,
    null,
    "edit"
  );
  const tb = {
    PermissionApp: true,
    wait: true,
    /*params*/
    parms: {
      iuserid: sto.idi,
    },
    autos: {},
  };
  const handleUpdate = () => {
    // let _Data = {
    //   ...object,
    //   // sasInvHeadr: headerHeight,
    // };
    // console.log("the data is", _Data);
    update(object).then((dd) => {
      fetchURL();
    });
  };
  return (
    <Grid container justifyContent="center" spacing={0}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Container
          component={Paper}
          maxWidth={true}
          style={{
            // padding: "5px",
            borderBottomWidth: "0px",
            borderRightColor: colors.teal[100],
            borderRightWidth: "0px",
            borderRightStyle: "solid",
          }}
        >
          <ChipsSelect
            items={defalutPrintOPS}
            selectedId={selectedId}
            onSelect={(x) => {
              setSelectedId(x.id);
            }}
          />
        </Container>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Container
          component={Paper}
          maxWidth={true}
          style={{
            padding: "20px",
            border: "0px",
            borderColor: colors.teal[100],
            borderTopWidth: i18next.language == "ar" ? "5px" : "5px",
            borderLeftWidth: i18next.language == "ar" ? "0px" : "5px",
            borderStyle: "solid",
          }}
        >
          <OraxPrint
            {...{
              object,
              onChange,
              handleUpdate,
              index: selectedId,
            }}
          />
        </Container>
      </Grid>
      <Grid item container lg={12} md={12} sm={12} sx={12}>
        <Container
          maxWidth={true}
          style={{
            backgroundColor: colors.grey[200],
            padding: 10,
            // marginBottom: 80,
          }}
        >
          <Grid
            className={classes.GridButton}
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
          >
            <Button
              className={classes.buttons}
              // style={{ direction: "ltr" }}
              variant="contained"
              color="primary"
              style={{ width: "100%" }}
              fontSize="large"
              size="large"
              disabled={submitting}
              onClick={handleUpdate}
              // startIcon={<SaveIcon />}
            >
              حــفظ التعديلات
            </Button>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
}

export default function DefalutPrintSet() {
  const [selectedId, setSelectedId] = useState(1);
  const tb = {
    PermissionApp: true,
    wait: true,
    /*params*/
    parms: {
      iuserid: sto.idi,
    },
    autos: {},
  };
  const {
    object,
    fetching,
    fetch: fetchURL,
  } = useFetchObjectByPost(uri + "Res", tb);

  useEffect(() => {
    console.log("the object data is", object);
  });
  if (fetching || !object) return <LoadingPage />;

  return (
    <PrintDefSettings
      fetching={fetching}
      permisApp={object?.permisApp ?? {}}
      fetchURL={fetchURL}
      selectedId={selectedId}
      setSelectedId={setSelectedId}
    />
  );
}
