import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { uri } from "../../../../help/Api";

import LoadingPage from "../../../../services/LoadingPage";
// import Footer from "./Footer";
import * as _ from "lodash";
import { useLocation } from "react-router-dom";
import { sto } from "../../../../Context/InshData";
import HeaderA from "./HeaderA";
import BodyA, { Totals } from "./BodyA";
import FooterA from "./FooterA";
import { Typography } from "@material-ui/core";
import { stElemnt } from "../../../../St/Reviews/cStyle";
import { Rept } from "../../../../Context/InshData";
import useChunkMyData from "../../../../components/Hooks/useChunkMyData";
import useFetchURL from "../../../../components/Hooks/useFetchURL";

function OnePage(props) {
  const { found, chunk, isFirst, isLast } = props;
  return (
    <Fragment>
      <BodyA
        data={chunk ?? []}
        isFullPage={!isFirst ? true : false}
        isLast={isLast}
      />

      <FooterA found={found} />
    </Fragment>
  );
}

function ReviewAccountContainer(props) {
  const { chunks, chunksCount } = useChunkMyData(Rept.data, 12, 18);
  // const [chunks, setChunks] = useState(_.chunk(Rept.data, 14));
  // const firstChunk = _.chunk(Rept.data, 12);
  const url = uri + `Foundation?branch=${sto.branchi}`;
  const { data: found, fetching } = useFetchURL({
    ...{ url, dependencyArray: [] },
  });
  // const getInfo = async () => {
  //   axios
  //     .get(uri + `Foundation?branch=${sto.branchi}`)
  //     .then((res) => {
  //       setFound(res.data);
  //     })
  //     .catch((error) => {});
  // };

  // useEffect(() => {
  //   setTimeout(async () => {
  //     await getInfo();
  //     setLoading(false);
  //     // onUnmountComplete();
  //   }, 0);
  // }, []);

  if (fetching || !found || !chunks) return <div></div>;

  return (
    <Typography component="div" style={stElemnt.AF.Reviws.Typogmain}>
      <Typography component="div" style={{ ...stElemnt.AF.Reviws.Typogsub }}>
        <HeaderA found={found} />
        {chunks.map((page, i) => {
          return (
            <OnePage
              {...{
                found,
                chunk: page,
                isFirst: i == 0,
                isLast: i == chunksCount - 1,
              }}
            />
          );
        })}
      </Typography>
    </Typography>
  );
}
export default ReviewAccountContainer;
