import axios from 'axios'
import { uri } from '../help/Api'

export const URL =uri+'SandSQKh_R_RS_';

export let MURL =uri+'MultiQs';
export let MSURL =uri+'MultiSbds';
export const accountsURL =uri+`accounts-index/ByDoc?docType=${-1}`;

export function list(){
    return axios.get(URL);
}

export function create(data){
    return axios.post(URL, data);
}

export function update(data){
    return axios.put(URL, data)
}
export function remove(id){
    return axios.delete(URL+`/${id}`)
}


export function createM(data){
    return axios.post(MURL, data);
}

export function updateM(data){
    return axios.put(MURL, data)
}
export function removeM(id){
    return axios.delete(MURL+`/${id}`)
}
export function createMS(data){
    return axios.post(MSURL, data);
}

export function updateMS(data){
    return axios.put(MSURL, data)
}
export function removeMS(id){
    return axios.delete(MSURL+`/${id}`)
}

export default{URL, list, create, update, remove,MURL, createM, updateM, removeM, MSURL, createMS, updateMS, removeMS,}