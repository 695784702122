import React, { useState, useEffect } from "react";

import {
  SAdefaultValues,
  salesmanrpt,
} from "../../../../../services/saleAnalysis-services";
import {
  Button,
  Chip,
  colors,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";

import SaleManCommForm from "./SaleManComm-form";
import SaleManCommTable from "./SaleManComm-table";
import useFetchURL from "../../../../../components/Hooks/useFetchURL";
import { uri } from "../../../../../help/Api";
import TotalCard from "../../../../../components/TotalCard";
import { useHistory } from "react-router-dom";
import {
  useStylesHed,
  useStylesSpDial,
  useStylesUiElemment,
} from "../../../../../St/comps/UseStyle";
import { insh as inshst } from "../../../../../Context/InshSt";
import ScannerIcon from "@material-ui/icons/Scanner";
import KeyboardReturnSharpIcon from "@material-ui/icons/KeyboardReturnSharp";
import PrintIcon from "@material-ui/icons/Print";
import { useTranslation } from "react-i18next";
import BeatLoader from "react-spinners/BeatLoader";
import i18next from "i18next";
import SelectTypesFilter from "../CurrencySalesProfit/CurrencySalesProfit-typeFilter";
import { sto } from "../../../../../Context/InshData";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
  },
  itemW: {
    // display:'flex',
    width: "90%",
  },
  headerTitle: {
    backgroundColor: colors.green[50],
    textAlign: "center",
    fontSize: 20,
    width: "100%",
  },

  allWidth: {
    minWidth: 650,
  },
  checStyl: {
    marginTop: "17px",
    fontSize: "16px",
    padding: "-1px",
  },
}));
function toFixedValues(data) {
  const newData = data?.salesManComm?.map((row, i) => {
    return {
      ...row,
      col_tot_net: row.col_tot_net?.toFixed(2),
      col_commission: row.col_commission?.toFixed(2),
      col_commNet: row.col_commNet?.toFixed(2),
      col_Amount_collected: row.col_Amount_collected?.toFixed(2),
      col_CostAvg: row.col_CostAvg?.toFixed(2),
      col_amountOfVat: row.col_amountOfVat?.toFixed(2),
      col_profit: row.col_profit?.toFixed(2),
      col_qtys: row.col_qtys?.toFixed(2),
    };
  });

  return { ...data, salesManComm: newData };
}
export default function SaleManComm(props) {
  const { SalesMens } = props;
  const [rptParams, setRptParams] = React.useState({
    ...salesmanrpt,
    salesMan: SalesMens[0],
  });
  const classes = useStyles();
  const clasui = useStylesUiElemment();
  const clasSp = useStylesSpDial();
  const { t } = useTranslation(["forms", "translation"]);
  const { data, fetching, fetchURL } = useFetchURL({
    ...{
      url:
        uri +
        `Salesmen?salesMan=${rptParams.salesMan.id}
    &&dateFrom=${rptParams.dateFrom}
    &&dateTo=${rptParams.dateTo}
    &&branch=${sto.branchi}
    &&CostType=${rptParams.type_Cost.id}
    &&CommWithVat=${rptParams.CommWithVat}
    &&CommWithProfit=${rptParams.CommWithProfit}
    &&CommType=${rptParams.CommType.id}`,
      mapFunction: toFixedValues,
      dependencyArray: [],
    },
  });
  useEffect(() => {
    console.log("the data is", data);
  });
  const his = useHistory();

  const printHandle = () => {
    const info = {
      ...rptParams,
      data: data?.saleAnalysis,
      totals: data?.totals,
    };

    // his.push({ pathname: "/AnalysisPrint", state: info });

    // window.location.reload(false);
  };

  const onChangeData = (propName) => (event) => {
    setRptParams({ ...rptParams, [propName]: event.target.value });
  };
  const onChangeAutoData = (propName, newValue) => {
    setRptParams({ ...rptParams, [propName]: newValue });
  };
  return (
    <div className={classes.root}>
      <Grid container item xs={12}>
        <Typography
          size="small"
          className={classes.headerTitle}
          color="primary"
        >
          {t(" مبيعات مندوب خلال فترة")}
        </Typography>
      </Grid>
      <Typography
        component="div"
        style={{ direction: i18next.language == "ar" ? "rtl" : "ltr" }}
      >
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
            paddingBottom: "0px",
            borderBottomColor: colors.green[300],
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
          }}
        >
          <SelectTypesFilter {...{ setRptParams, rptParams }} />
          <SaleManCommForm
            {...{
              SalesMens,
              rptParams,
              onChangeData,
              onChangeAutoData,
              printHandle,
            }}
          />
          <Grid
            container
            justifyContent="center"
            lg={12}
            md={12}
            sm={12}
            xs={12}
            spacing={1}
          >
            <Grid item>
              <Button
                disabled={fetching}
                variant="contained"
                startIcon={<ScannerIcon />}
                size="large"
                style={{ ...inshst.sal.iconBtn, borderRadius: 0 }}
                className={[clasui.button]}
                onClick={() => fetchURL()}
              >
                {t("forms:view")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={fetching}
                variant="contained"
                startIcon={<PrintIcon />}
                color="primary"
                size="large"
                className={[clasui.button, clasSp.clSec]}
                style={{ ...inshst.sal.iconBtn, borderRadius: 0 }}
                onClick={printHandle}
              >
                {t("forms:print")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                startIcon={<KeyboardReturnSharpIcon />}
                color="secondary"
                size="large"
                className={[clasui.button, clasSp.clcan]}
                style={{ ...inshst.sal.iconBtn, borderRadius: 0 }}
                onClick={() => his.goBack()}
              >
                {t("forms:exit")}
              </Button>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth={true} style={{ padding: "4px" }}>
          <Grid container spacing={1} style={{ paddingTop: "10px" }}>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  title: t("إجمالي قيمة الفاتورة"),
                  value: data?.totals?.totDept?.toFixed(2),
                }}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <TotalCard
                {...{
                  title: t("إجمالي العمولة"),
                  value: data?.totals?.totCredit?.toFixed(2),
                }}
              />
            </Grid>
          </Grid>
        </Container>
        <br />
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
          }}
        >
          <Grid container justifyContent="center">
            <Grid item container justifyContent="center" xs={12}>
              <BeatLoader loading={fetching} color={colors.green[300]} />
              {!fetching && (
                <SaleManCommTable
                  {...{
                    data: data?.salesManComm ? data.salesManComm : [],
                    title: "",
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Container>
      </Typography>
    </div>
  );
}
