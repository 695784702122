import React, { useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { colors, Divider } from "@material-ui/core";
import { useStylesTF, useStylesLbl } from "../../../../../St/comps/UseStyle";
import ReceiptVoucherType from "../components/ReceiptVoucher-typeFilter";
import GlobleOmlatForm from "../../../../../components/controls/OmlatForm";
import { useTranslation } from "react-i18next";

export default function EReceiptVoucherForm(props) {
  const {
    changAutoCustomers,
    onChange,
    AutoOptions,
    changAutoBanks,
    changAutoStocks,
    object,
    onShow,
    formType,
  } = props;
  const clasTF = useStylesTF();
  const claslbl = useStylesLbl();
  const { t } = useTranslation(["forms"]);

  return (
    <React.Fragment>
      <Grid container style={{ padding: 10 }} spacing={2}>
        <Grid item lg={4} md={4} sm={4} xs={6}>
          <TextField
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            id="id"
            label={t("voucher-id")}
            className={clasTF.rootEDS}
            InputProps={{ className: clasTF.input, readOnly: true }}
            name="id"
            value={object.id}
          />
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={6}>
          <TextField
            type="number"
            fullWidth
            variant="outlined"
            size="small"
            id="ref_no"
            label={t("ref-no")}
            className={clasTF.rootEDS}
            InputProps={{ className: clasTF.input }}
            name="ref_no"
            value={object.ref_no}
            onChange={(e) =>
              onChange(null, { name: "ref_no", value: e.target.value })
            }
          />
        </Grid>

        <Grid item lg={4} md={4} sm={6} xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            id="date"
            label={t("date")}
            className={clasTF.rootEDS}
            type="date"
            format="YYYY-MM-DD"
            InputLabelProps={{ shrink: true }}
            name="date"
            value={object.date}
            onChange={(e) =>
              onChange(null, { name: "date", value: e.target.value })
            }
          />
        </Grid>

        <Grid item lg={6} md={6} sm={6} xs={6}>
          <Autocomplete
            value={object.customer}
            onChange={(event, newValue) => {
              changAutoCustomers(newValue);
            }}
            id="controllable-states-demo"
            size="small"
            options={AutoOptions.Customers}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            // style={{  }}
            style={{ backgroundColor: colors.orange[50] }}
            disableClearable
            disabled={false}
            renderInput={(params) => (
              <TextField
                id="valueCustomers"
                {...params}
                label={t("received-from-mr")}
                variant="outlined"
                size="small"
                InputLabelProps={{ className: claslbl.clor }}
              />
            )}
          />
        </Grid>

        <Grid item lg={6} md={6} sm={6} xs={6}>
          <TextField
            type="number"
            fullWidth
            variant="outlined"
            size="small"
            id="val"
            label={t("amount")}
            className={clasTF.rootEDS}
            style={{ backgroundColor: colors.grey[50] }}
            InputProps={{ className: clasTF.input }}
            name="val"
            disabled={false}
            value={object.val}
            onChange={(e) => {
              let nObject = {
                ...object,
                val: e.target.value,
                tot_val: e.target.value * object.cust_omla.exchange,
              };
              onShow(nObject, formType);
            }}
          />
        </Grid>
        <Grid item lg={4} md={4} sm={6} xs={6}>
          <Autocomplete
            value={object.center}
            onChange={(event, newValue) => {
              onChange(null, { name: "center", value: newValue });
            }}
            id="controllable-states-demo"
            size="small"
            options={AutoOptions.costcenters}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            // style={{  }}
            disableClearable
            renderInput={(params) => (
              <TextField
                id="valueCostcenter"
                {...params}
                label={t("center")}
                variant="outlined"
                size="small"
                InputLabelProps={{ className: claslbl.clor }}
              />
            )}
          />
        </Grid>
        <Grid item lg={8} md={8} sm={12} xs={12}>
          <GlobleOmlatForm
            {...{
              omlat: object.cust_omlat,
              omla: object.cust_omla,
              disabled: false,
              onExchangeChange: (value) => {
                let newObject = {
                  ...object,
                  cust_omla: { ...object.cust_omla, exchange: value },
                  tot_val: object.val * value,
                };
                onShow(newObject, formType);
              },
              onChange: (newValue) => {
                let newObject = {
                  ...object,
                  cust_omla: newValue,
                  tot_val: object.val * newValue.exchange,
                };
                onShow(newObject, formType);
              },
              amount: object.val,
            }}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Divider />
        </Grid>
        <Grid item lg={12} md={12} sm={12}>
          <ReceiptVoucherType
            {...{
              type: object.type,
              onChange,
            }}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          {object.type == "1" ? (
            <Autocomplete
              value={object.stock}
              onChange={(event, newValue) => {
                changAutoStocks(newValue);
              }}
              id="controllable-states-demoew"
              size="small"
              options={AutoOptions.Stocks}
              style={{ backgroundColor: colors.orange[50] }}
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option.name
              }
              // style={{  }}
              disabled={formType == "edit"}
              disableClearable
              renderInput={(params) => (
                <TextField
                  id="valueCostcenter"
                  {...params}
                  label={t("deposited-in-account")}
                  variant="outlined"
                  size="small"
                  InputLabelProps={{ className: claslbl.clor }}
                />
              )}
            />
          ) : (
            <Autocomplete
              value={object.bank}
              onChange={(event, newValue) => {
                changAutoBanks(newValue);
              }}
              id="controllable-states-demo67"
              size="small"
              options={AutoOptions.Banks}
              disabled={formType == "edit"}
              style={{ backgroundColor: colors.orange[50] }}
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option.name
              }
              // style={{  }}
              disableClearable
              renderInput={(params) => (
                <TextField
                  id="valueCostcenter"
                  {...params}
                  label={t("deposited-in-account")}
                  variant="outlined"
                  size="small"
                  InputLabelProps={{ className: claslbl.clor }}
                />
              )}
            />
          )}
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <TextField
            type="number"
            fullWidth
            variant="outlined"
            size="small"
            id="val"
            label={t("amount")}
            className={clasTF.rootEDS}
            style={{ backgroundColor: colors.grey[50] }}
            InputProps={{ className: clasTF.input }}
            name="val1"
            disabled={false}
            value={object.val1}
            onChange={(e) => {
              const ex_value =
                object.type == "1"
                  ? object.stock_omla?.exchange
                  : object.bank_omla.exchange;
              let nObject = {
                ...object,
                val1: e.target.value,
                tot_val1: e.target.value * ex_value,
              };
              onShow(nObject, formType);
            }}
          />
        </Grid>
        <Grid item lg={8} md={8} sm={12} xs={12}>
          {object.type == "1" ? (
            <GlobleOmlatForm
              {...{
                omlat: object.stock_omlat,
                omla: object.stock_omla,
                disabled: false,
                onExchangeChange: (value) => {
                  let newObject = {
                    ...object,
                    stock_omla: { ...object.stock_omla, exchange: value },
                    tot_val1: object.val1 * value,
                  };
                  onShow(newObject, formType);
                },
                onChange: (newValue) => {
                  let newObject = {
                    ...object,
                    stock_omla: newValue,
                    tot_val1: object.val1 * newValue.exchange,
                  };
                  onShow(newObject, formType);
                },
                amount: object.val1,
              }}
            />
          ) : (
            <GlobleOmlatForm
              {...{
                omlat: object.bank_omlat,
                omla: object.bank_omla,
                disabled: false,
                onExchangeChange: (value) => {
                  let newObject = {
                    ...object,
                    bank_omla: { ...object.bank_omla, exchange: value },
                    tot_val1: object.val1 * value,
                  };
                  onShow(newObject, formType);
                },
                onChange: (newValue) => {
                  let newObject = {
                    ...object,
                    bank_omla: newValue,
                    tot_val1: object.val1 * newValue.exchange,
                  };
                  onShow(newObject, formType);
                },
                amount: object.val1,
              }}
            />
          )}
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Autocomplete
            value={object.emp}
            onChange={(event, newValue) => {
              onChange(null, { name: "emp", value: newValue });
            }}
            id="controllable-states-demo4"
            size="small"
            options={AutoOptions.Users}
            disabled={formType == "edit"}
            // getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            disableClearable
            renderInput={(params) => (
              <TextField
                id="valueSalesmen"
                {...params}
                label={t("delegate")}
                variant="outlined"
                size="small"
                InputLabelProps={{ className: claslbl.clor }}
              />
            )}
          />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            id="notes"
            label={t("note")}
            className={clasTF.rootEDS}
            InputProps={{ className: clasTF.input }}
            name="notes"
            value={object.notes}
            onChange={(e) =>
              onChange(null, { name: "notes", value: e.target.value })
            }
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
