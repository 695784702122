import React, { useState, useEffect } from "react";

import useFetchObjectByPost from "../../../../../components/Hooks/useFetchObjectByPost";
import { sto } from "../../../../../Context/InshData";
import { uri } from "../../../../../help/Api";
import LoadingPage from "../../../../../services/LoadingPage";
import EmpSales from "./EmpSales";
const defaultAutos = {
  Employees: [],
};
export default function EmpSalesCon() {
  const tb = {
    wait: true,
    Employees: true,
    stor: sto,
    parms: {},
    autos: { Employees: true },
  };

  const { object: AutoOptions, fetching: fetchAll } = useFetchObjectByPost(
    uri + "Res",
    tb
  );

  if (fetchAll || !AutoOptions?.Employees) return <LoadingPage />;

  return (
    <EmpSales
      {...{
        
        AutoOptions: AutoOptions?.Employees ? AutoOptions : defaultAutos,
        isSale: true,
      }}
    />
  );
}

export function EmpPurchCon() {
  const tb = {
    wait: true,
    Employees: true,
    stor: sto,
    parms: {},
    autos: { Employees: true },
  };

  const { object: AutoOptions, fetching: fetchAll } = useFetchObjectByPost(
    uri + "Res",
    tb
  );

  if (fetchAll || !AutoOptions?.Employees) return <LoadingPage />;

  return (
    <EmpSales
      {...{
        AutoOptions: AutoOptions?.Employees ? AutoOptions : defaultAutos,
        isSale: false,
      }}
    />
  );
}
