import React from "react";
import IExchangeVoucherForm from "./IEV-form";
import {
  Avatar,
  Chip,
  colors,
  Container,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { uri } from "../../../../../help/Api";
import TotalCard from "../../../../../components/TotalCard";
import ExternalFormSub from "../components/External-form-sub";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import { useStylesHed } from "../../../../../St/comps/UseStyle";
import toast from "react-hot-toast";
import useFormDataCRUD from "../../../../../components/Hooks/useEDSCURD";
import IExchangeAPI from "../../../../../api/IExchange";
import {
  defaultValues,
  getEndDept,
  ReFormatingData,
} from "../../../../../services/InernalVoucher-service";
import axios from "axios";
import { sto } from "../../../../../Context/InshData";
import IExchangeVoucherAppBar from "./IExchangeVoucher-AppBar";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    // minHeight: '100%',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  itemW: {
    // display:'flex',
    width: "90%",
  },

  allWidth: {
    minWidth: 650,
  },
  checStyl: {
    marginTop: "17px",
    fontSize: "16px",
    padding: "-1px",
  },
  avatar: {
    backgroundColor: colors.grey[700],
    color: "#fff",
    height: 46,
    width: 46,
    marginTop: -5,
  },
  icon: {
    height: 32,
    width: 32,
  },
  card: {
    backgroundColor: colors.grey[100],
    height: "100px",
    color: colors.grey[900],
    // borderColor: colors.grey[700],
    borderWidth: "0px",
    borderStyle: "solid",
    fontSize: "26px",
    // color:colors.green[900]
  },
}));

export default function IExchangeVoucher(props) {
  const { AutoOptions, fetchURL, prmis, isDialog, item, onClose } = props;

  const defaultV = isDialog
    ? ReFormatingData({ ...item }, AutoOptions)
    : {
        ...defaultValues,
        id: AutoOptions.other.lastid,
        emp: AutoOptions.Users[0],
        stock: AutoOptions.Stocks[0],
        bank: AutoOptions.Banks[0],
        user: AutoOptions.Users[0],
      };
  const {
    onChange,
    submit,
    update,
    remove,
    submitting,
    errorMessage,
    onShow,
    onEnd,
    object,
    formType,
  } = useFormDataCRUD(
    IExchangeAPI.create,
    IExchangeAPI.update,
    IExchangeAPI.remove,
    undefined,
    defaultV,
    null,
    () => {
      if (isDialog) onClose(true);
      else fetchURL();
    },
    isDialog ? "edit" : "new"
  );

  const changAutoCustomers = async (newValue) => {
    let end_dept1 = "0.0";
    await axios
      .get(
        uri +
          `AccountReports/rptABalanceSum?branch=${-1}
                &&costCenter=${-1}
                &&acc_code=${newValue.code.toString()}`
      )
      .then((res) => {
        end_dept1 = res.data.end_Dept - res.data.end_Credit;
      })
      .catch((res) => {
        toast.error("لم يتم جلب مديونية العميل");
        console.log("erro", res.error);
      });

    let nObject = {
      ...object,
      acc_code: newValue,
      end_Dept: end_dept1,
      notes: "دفعة لحساب " + newValue.name,
    };

    onShow(nObject, formType);
  };
  const { t } = useTranslation(["translation", "forms"]);
  const classes = useStyles();
  const clashd = useStylesHed();
  const subTile = formType == "edit" ? t("edit") : t("new");
  return (
    <>
      <div className={classes.root} style={{ direction: "rtl" }}>
        <Grid item container className={clashd.rootHd}>
          <Grid item xs={12}>
            <Typography
              variant={"h5"}
              // className={clashd.ChipHdAccount}

              component="h3"
              style={{
                marginBlock: 10,
                // backgroundColor:'#fff',
              }}
            >
              {subTile} {t("int-exchange")}
            </Typography>
          </Grid>
        </Grid>
        <Typography component="div" style={{ direction: "rtl" }}>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item lg={9} md={8} sm={12}>
              <Container
                component={Paper}
                maxWidth={false}
                style={{
                  padding: "30px",
                  // height:'400px',

                  borderTopColor: colors.grey[700],
                  borderTopWidth: "2px",
                  borderTopStyle: "solid",
                }}
              >
                {/* <SelectTypesFilter {...{rptParams, setRptParams }}/> */}
                <IExchangeVoucherForm
                  {...{
                    ...props,
                    object,
                    formType,
                    changAutoCustomers,
                    onChange,
                  }}
                />
                {/* <CurrencySalesProfitForm {...{AutoOptions, rptParams, onChangeData, onChangeAutoData}} /> */}
              </Container>
            </Grid>
            <Grid item lg={3} md={4} sm={12}>
              <TotalCard
                {...{
                  className: classes.card,
                  title: t("forms:account-balance-to-date"),
                  value: getEndDept(object.end_Dept),
                }}
              />
              <br />
              <Container
                component={Paper}
                maxWidth={true}
                style={{
                  padding: "20px",
                  borderBottomWidth: "0px",
                }}
              >
                <Grid container justify="space-between">
                  <Grid item>
                    <Typography
                      style={{ paddingTop: "8px" }}
                      variant="h4"
                      component="h4"
                    >
                      {t("forms:check-data")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Avatar className={classes.avatar}>
                      <AttachMoneyIcon className={classes.icon} />
                    </Avatar>
                  </Grid>
                </Grid>
                <Divider />
                <br />

                <ExternalFormSub
                  {...{
                    ...props,
                    object,
                    changAutoCustomers,
                    onChange,
                  }}
                />

                <br />
              </Container>
            </Grid>
          </Grid>
        </Typography>
      </div>
      <IExchangeVoucherAppBar
        {...{
          URL: IExchangeAPI.URL,
          ...props,
          prmis,
          onChange,
          submit,
          update,
          defaultV,
          remove,
          submitting,
          errorMessage,
          onShow,
          onEnd,
          object,
          formType,
          AutoOptions,
        }}
      />
    </>
  );
}
