import React from "react";
import MultiVoucherForm from "./MV-form";
import {
  Avatar,
  Chip,
  colors,
  Container,
  Divider,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import { useStylesHed } from "../../../../../St/comps/UseStyle";
import useFormDataCRUD from "../../../../../components/Hooks/useEDSCURD";
import EReceiptAPI, { changeURL } from "../../../../../api/EReceipt";
import {
  MRVdefaultValues,
  ReFormatingData,
} from "../../../../../services/multi-receipt-services";
import MReceiptVoucherAppBar from "./MReceiptVoucher-AppBar";
import { getAccountOmlat } from "../../../../../services/omlat-services";
import MultiSub from "./multi-sub/multiSub";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    // minHeight: '100%',
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(10),
  },
  itemW: {
    // display:'flex',
    width: "90%",
  },

  allWidth: {
    minWidth: 650,
  },
  checStyl: {
    marginTop: "17px",
    fontSize: "16px",
    padding: "-1px",
  },
  avatar: {
    backgroundColor: colors.grey[800],
    color: "#fff",
    height: 46,
    width: 46,
    marginTop: -5,
  },
  icon: {
    height: 32,
    width: 32,
  },
}));

export default function MultiVoucher(props) {
  const { AutoOptions, prmis, fetchURL, isReceipt, isDialog, item, onClose } =
    props;

  const StockOmlat = getAccountOmlat(
    AutoOptions.Stocks[0].acc_omlat,
    AutoOptions.omlats
  );
  const BankOmlat = getAccountOmlat(
    AutoOptions.Banks[0].acc_omlat,
    AutoOptions.omlats
  );

  const defaultV = isDialog
    ? ReFormatingData(item, AutoOptions)
    : {
        ...MRVdefaultValues,
        id: AutoOptions.other.lastid,
        stock: AutoOptions.Stocks[0],
        bank: AutoOptions.Banks[0],
        rows: [],
        stock_omlat: StockOmlat,
        stock_omla: StockOmlat[0],
        bank_omlat: BankOmlat,
        bank_omla: BankOmlat[0],
      };

  const {
    onChange,
    submit,
    update,
    remove,
    submitting,
    errorMessage,
    onShow,
    onEnd,
    object,
    formType,
  } = useFormDataCRUD(
    isReceipt ? EReceiptAPI.createM : EReceiptAPI.createMS,
    isReceipt ? EReceiptAPI.updateM : EReceiptAPI.updateMS,
    isReceipt ? EReceiptAPI.removeM : EReceiptAPI.removeMS,
    undefined,
    defaultV,
    null,
    () => {
      if (isDialog) onClose(true);
      else fetchURL();
    },
    isDialog ? "edit" : "new"
  );

  const changAutoStocks = async (newValue) => {
    const accOmlat = getAccountOmlat(newValue.acc_omlat, AutoOptions.omlats);
    let nObject = {
      ...object,
      stock: newValue,
      stock_omlat: accOmlat,
      stock_omla: accOmlat[0],
    };
    onShow(nObject, formType);
  };

  const changAutoBanks = async (newValue) => {
    const accOmlat = getAccountOmlat(newValue.acc_omlat, AutoOptions.omlats);
    let nObject = {
      ...object,
      bank: newValue,
      bank_omlat: accOmlat,
      bank_omla: accOmlat[0],
    };
    onShow(nObject, formType);
  };

  const { t } = useTranslation(["forms", "translation"]);

  const classes = useStyles();
  const clashd = useStylesHed();
  const total = object?.rows?.reduce((a, v) => (a = a + v.credit), 0);

  const accounts = AutoOptions?.Customers;

  return (
    <>
      <div
        className={classes.root}
        style={{ direction: i18next.language == "ar" ? "rtl" : "ltr" }}
      >
        <Grid
          item
          container
          justifyContent="space-evenly"
          spacing={0}
          className={clashd.rootHd}
          style={{
            padding: "15px",
            color: colors.grey[900],
            backgroundColor: colors.grey[400],
          }}
        >
          <Grid item>
            <Typography variant={"h4"} component="h2" style={{}}>
              {isReceipt
                ? t("translation:mult-receipt")
                : t("translation:mult-exchange")}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <AttachMoneyIcon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
        <Typography
          component="div"
          style={{
            direction: i18next.language == "ar" ? "rtl" : "ltr",
            marginTop: "0px",
            borderRightColor: colors.grey[400],
            borderRightWidth: "10px",
            borderRightStyle: "solid",
          }}
        >
          <Grid container justifyContent="center" spacing={0}>
            <Grid item lg={5} md={5} sm={12} xs={12}>
              <Container
                component={Paper}
                maxWidth={true}
                style={{
                  padding: "20px",
                  borderBottomWidth: "0px",
                  borderRightColor: colors.grey[200],
                  borderRightWidth: "0px",
                  borderRightStyle: "solid",
                }}
              >
                <MultiVoucherForm
                  {...{
                    ...props,
                    object,
                    formType,
                    changAutoBanks,
                    changAutoStocks,
                    onChange,
                    onShow,
                  }}
                />
                <br />
              </Container>
            </Grid>
            <Grid item lg={7} md={7} sm={12} xs={12}>
              <Container
                component={Paper}
                maxWidth={true}
                style={{
                  padding: "20px",
                  border: "0px",
                  borderColor: colors.grey[400],
                  borderRightWidth: i18next.language == "ar" ? "5px" : "0px",
                  borderLeftWidth: i18next.language == "ar" ? "0px" : "5px",
                  borderStyle: "solid",
                }}
              >
                <MultiSub
                  {...{
                    ...props,
                    object,
                    accounts: accounts,
                    onShow,
                    formType,
                    onChange,
                  }}
                />
              </Container>
            </Grid>
            <Grid item container lg={12} md={12} sm={12} sx={12}>
              <Container
                maxWidth={true}
                style={{ backgroundColor: colors.grey[400], padding: 20 }}
              >
                <Grid
                  item
                  container
                  lg={12}
                  md={12}
                  sm={12}
                  sx={12}
                  spacing={3}
                  style={{ backgroundColor: "#fff" }}
                >
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <TextField
                      type="number"
                      fullWidth
                      variant="outlined"
                      size="small"
                      id="total"
                      label={t("froms:total")}
                      name="total"
                      value={total}
                      disabled={true}
                    />
                  </Grid>

                  <Grid item lg={3} md={3} sm={3} xs={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      id="taxperc"
                      label={t("forms:value-tax") + "%"}
                      // className={object.type=='1'?null: clasTF.rootEDS}
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      name="taxperc"
                      value={object.taxperc}
                      onChange={(e) => {
                        let nObj = {
                          ...object,
                          taxperc: e.target.value,
                          taxval: total / e.target.value,
                        };
                        onShow(nObj, "new");
                      }}
                    />
                  </Grid>
                  <Grid item lg={3} md={3} sm={3} xs={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      id="taxperc"
                      label={t("forms:value-tax")}
                      // className={object.type=='1'?null: clasTF.rootEDS}
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      name="taxperc"
                      value={total * (object.taxperc / 100)}
                      disabled={true}
                    />
                  </Grid>
                </Grid>
              </Container>
            </Grid>
          </Grid>
        </Typography>
      </div>
      <MReceiptVoucherAppBar
        {...{
          URL: isReceipt ? EReceiptAPI.MURL : EReceiptAPI.MSURL,
          prmis,
          ...props,
          onChange,
          submit,
          update,
          defaultV,
          remove,
          submitting,
          errorMessage,
          onShow,
          onEnd,
          object,
          formType,
          AutoOptions,
        }}
      />
    </>
  );
}
