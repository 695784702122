import React, { useEffect, useState } from "react";
import axios from "axios";
import { uri } from "../../../../help/Api";
import { insh, sto } from "../../../../Context/InshData";
import CssBaseline from "@material-ui/core/CssBaseline";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import ScannerIcon from "@material-ui/icons/Scanner";
import KeyboardReturnSharpIcon from "@material-ui/icons/KeyboardReturnSharp";
import Chip from "@material-ui/core/Chip";
import PrintIcon from "@material-ui/icons/Print";
import { useHistory } from "react-router-dom";
import Container from "@material-ui/core/Container";
// cuG
import {
  useStylesUp,
  useStylesHed,
  useStylesAuto,
  useStylesTF,
  useStylesTD,
  useStylesBA,
  useStylesLbl,
  useStylesUiElemment,
  useStylesSpDial,
} from "../../../../St/Reports/UseStyle";
import CurrencyDetailsRPTTable from "./CurrencyDetailsRPT-table";
import { Toaster } from "react-hot-toast";

export default function CurrencyDetailsRPTForm(props) {
  const {
    data,
    onChangeData,
    rptParams,
    printHandle,
    setRptParams,
    fetching,
    fetchData,
  } = props;

  const clasup = useStylesUp();
  const clashd = useStylesHed();
  const clasAo = useStylesAuto();
  const clasTF = useStylesTF();
  const clasTD = useStylesTD();
  const claslbl = useStylesLbl();
  const clasui = useStylesUiElemment();
  const clasSp = useStylesSpDial();

  const [gets, setGets] = useState(insh.tbname);
  const [barcode, setBarcode] = useState("");
  // const getItem = (barcode) => {
  //   const result = gets.item?.filter((d) => d.i == barcode);
  //   console.log("the result is", result);
  //   if (result.length > 0) changAutos("curr", result[0]);
  // };

  const loadAutos = async () => {
    const tb = {
      wait: true,
      Safes: true,
      Branches: true,
      Currencies: true,
      stor: sto,
      parms: {
        safe: -1,
      },
    };
    await axios
      .post(uri + "Rept", tb)
      .then((res) => {
        const [, ...items] = res.data.items;
        setGets({ ...res.data, items: items });
      })
      .catch((error) => {
        /*console.log('no rpt');*/
      });
  };

  useEffect(async () => {
    await loadAutos();
  }, []);

  const changAutos = (prop, newValue) => {
    setRptParams({ ...rptParams, [prop]: newValue });
  };

  const toBack = () => {
    his.push({ pathname: "/" });
  };

  let his = useHistory();

  const frontDownR = () => {
    return (
      <Grid container lg={12} md={12} className={[clasup.rootdown]}>
        <Grid container xs={12} className={[clasup.downsub, clasup.downsubR]}>
          {/* S front F */}
          <Grid item lg={12} md={12} sm={12} xs={6}>
            <Chip
              className={[clasui.chipS, clasui.chipTR]}
              label={"الرصيد"}
              clickable
              color="primary"
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={6}>
            <Chip
              className={[clasui.chipV, clasui.chipVR]}
              label={data.reduce((a, v) => (a = a + v.Qtys), 0)}
              clickable
              color="default"
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <React.Fragment>
      <Toaster />
      <CssBaseline />
      <Container maxWidth="xl">
        <Typography
          component="div"
          style={{
            textAlign: "center",
            backgroundColor: "#fff",
            height: "100vh",
            direction: "rtl",
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Chip
                size="small"
                className={clashd.ChipHdSale}
                label={`حركة صنف محددة`}
                color="primary"
              />
            </Grid>

            {/* S front Right */}
            <Grid container lg={4} md={5}>
              {/* S front up R */}
              <Grid container lg={12} md={12} className={[clasup.rootup]}>
                <Grid
                  container
                  spacing={1}
                  xs={12}
                  className={[clasup.upsub, clasup.upsubL]}
                >
                  <Grid item lg={6} md={6} sm={4} xs={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      id="fd"
                      label="من تاريخ"
                      className={clasTF.rootSale}
                      InputProps={{ className: clasTD.input }}
                      type="date"
                      format="YYYY-MM-DD"
                      name="fd"
                      value={rptParams.dateFrom}
                      onChange={onChangeData("dateFrom")}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} sm={4} xs={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      id="td"
                      label="إلى تاريخ"
                      className={clasTF.rootSale}
                      InputProps={{ className: clasTD.input }}
                      type="date"
                      format="YYYY-MM-DD"
                      name="td"
                      value={rptParams.dateTo}
                      onChange={onChangeData("dateTo")}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} sm={4} xs={6}>
                    <Autocomplete
                      classes={clasAo}
                      value={rptParams.safe}
                      labelPlacement=""
                      onChange={(event, newValue) => {
                        changAutos("safe", newValue);
                      }}
                      id="controllable-states-demo"
                      size="small"
                      options={gets.Safes}
                      getOptionLabel={(option) =>
                        typeof option === "string" ? option : option.name
                      }
                      // style={{  }}
                      disableClearable
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="المخزن"
                          variant="outlined"
                          size="small"
                          InputLabelProps={{ className: claslbl.clrSale }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} sm={4} xs={6}>
                    <Autocomplete
                      classes={clasAo}
                      value={rptParams.branch}
                      onChange={(event, newValue) => {
                        changAutos("branch", newValue);
                      }}
                      id="controllable-states-demo"
                      size="small"
                      options={gets.Branches}
                      getOptionLabel={(option) =>
                        typeof option === "string" ? option : option.name
                      }
                      disableClearable
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="الفرع"
                          variant="outlined"
                          size="small"
                          InputLabelProps={{ className: claslbl.clrSale }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item lg={4} md={4} sm={8} xs={12}>
                    <Autocomplete
                      classes={clasAo}
                      value={rptParams.curr}
                      onChange={(event, newValue) => {
                        changAutos("curr", newValue);
                        // console.log(newValue);
                      }}
                      id="controllable-states-demo"
                      size="small"
                      options={gets.items}
                      getOptionLabel={(option) =>
                        typeof option === "string"
                          ? option
                          : option?.i?.toString()
                      }
                      disableClearable
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"باركود"}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{ className: claslbl.clrSale }}
                        />
                      )}
                    />
                    {/* <TextField
                      type="text"
                      fullWidth
                      variant="outlined"
                      size="small"
                      id="stay"
                      label="الباركود "
                      name="curr.i"
                      InputLabelProps={{ shrink: true }}
                      // error={submitting && currentRow.barcode === ""}
                      value={rptParams.curr.i}
                      onChange={(e) => {
                        console.log(currentRow);
                        setCurrentRow({
                          ...currentRow,
                          barcode: e.target.value,
                        });
                        getRow(e.target.value);
                      }}
                    /> */}
                  </Grid>
                  <Grid item lg={8} md={8} sm={8} xs={12}>
                    <Autocomplete
                      classes={clasAo}
                      value={rptParams.curr}
                      onChange={(event, newValue) => {
                        changAutos("curr", newValue);
                        console.log(newValue);
                      }}
                      id="controllable-states-demo"
                      size="small"
                      options={gets.items}
                      getOptionLabel={(option) =>
                        typeof option === "string" ? option : option.name
                      }
                      disableClearable
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"الصنف"}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{ className: claslbl.clrSale }}
                        />
                      )}
                    />
                  </Grid>

                  <Hidden>
                    <Grid item container spacing={1} className={clasui.contbnt}>
                      <Grid item>
                        <Button
                          disabled={fetching}
                          variant="contained"
                          startIcon={<ScannerIcon />}
                          color="primary"
                          size="large"
                          className={[clasui.button, clasSp.clPri]}
                          onClick={() => {
                            fetchData();
                          }}
                        >
                          عرض{" "}
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button
                          variant="contained"
                          startIcon={<PrintIcon />}
                          color="primary"
                          size="large"
                          className={[clasui.button, clasSp.clSec]}
                          disabled={true}
                          onClick={printHandle}
                        >
                          طباعة{" "}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          startIcon={<KeyboardReturnSharpIcon />}
                          color="secondary"
                          size="large"
                          className={[clasui.button, clasSp.clcan]}
                          onClick={toBack}
                        >
                          خروج
                        </Button>
                      </Grid>
                    </Grid>
                  </Hidden>
                </Grid>
              </Grid>
              <Hidden smDown={["sm"]}>{frontDownR()}</Hidden>
              {/* E front down R */}
            </Grid>
            <Grid container lg={8} md={7}>
              {/* s front up L */}
              <Grid container lg={12} md={12} className={[clasup.rootup]}>
                <Grid
                  container
                  xs={12}
                  className={[clasup.upsub, clasup.upsubL]}
                >
                  <Grid item xs={12}>
                    <CurrencyDetailsRPTTable
                      {...{
                        data: data,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Hidden mdUp={["md"]}>{frontDownR()}</Hidden>
          </Grid>
        </Typography>
      </Container>
    </React.Fragment>
  );
}
