import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {  Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
export default function EmpSalesForm(props){

  const {
    rptParams, 
    onChangeData,
    AutoOptions,
    onChangeAutoData} = props;

    return (
          <React.Fragment>
              <Grid container style={{padding:5 }} spacing={1}>
                <Grid item lg={3} md={3}  sm={3} xs={6}>
                    <TextField  fullWidth variant="outlined" size="small" id="datef" 
                    label="من تاريخ"
                    type="date" format="YYYY-MM-DD"
                    name="datef" value={rptParams.dateFrom} 
                    onChange={onChangeData("dateFrom")}/>
                </Grid>

                <Grid item lg={3} md={3} sm={3} xs={6}>
                  <TextField fullWidth variant="outlined" size="small" id="dateT" 
                  label="إلى تاريخ"
                  type="date" format="YYYY-MM-DD"
                  name="datet" value={rptParams.dateTo} 
                  onChange={onChangeData("dateTo")}/>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={6}>
                  <Autocomplete value={rptParams.emp}
                    onChange={(event, newValue) => { 
                      onChangeAutoData("emp", newValue) 
                    }}
                    id="combo-box-demo" size="small"
                    options={AutoOptions.Employees} 
                    getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                    style={{  }}
                    disableClearable
                    renderInput={(params) => <TextField {...params}  InputLabelProps={{shrink: true}} label="الموظف" variant="outlined" />}   
                  />
                </Grid>


            </Grid>  

          </React.Fragment>
    );
 
}