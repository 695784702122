import React, { useState, useEffect } from "react";
import { txtA, txtE } from "../../../Label/Lbl";

import axios from "axios";
import { uri } from "../../../help/Api";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// import MaterialTable from "material-table";
import { insh, sto } from "../../../Context/InshData";
// Ust
import { useInvCa, useInvMain } from "../../../St/Reviews/UseStyle";
// csG
// import {stElemnt} from '../../St/Reviews/cStyle'
import Paper from "@material-ui/core/Paper";
import Footer from "./Footer";
import Header from "./Header";
import { Divider } from "@material-ui/core";

const Body = () => {
  const clasm = useInvMain();
  const clas = useInvCa();
  // const clasB = useInvBodyCa();
  const [AE, setAE] = useState(null);

  const locInv = JSON.parse(localStorage.getItem("datainv")) || {};
  const permApp = JSON.parse(localStorage.getItem("premiApp")) || {};

  const [sta, setSta] = useState({ info: insh.RptInv, tot: {}, StngIA: {} });
  // const [list, setList] = useState([]);
  const getInfo = async () => {
    const tb = {
      Rpt_Inv: true,
      SettInvsApp: true,
      parms: {
        lprocid: locInv.proc_id,
        ibranchid: locInv.branch,
        sProcTypeInvProc: locInv.ProcTypeInvProc,
        iFoundid: 1,
      },
      autos: {},
    };
    //  console.log('Rpt__tb'); console.log(tb)
    //  alert(`${uri}Res`)
    axios
      .post(`${uri}Res`, tb)
      .then((res) => {
        // console.log('res.data.RptInv'); console.log(res.data);
        setSta({
          info: res.data.RptInv,
          tot: res.data.infoBill,
          StngIA: res.data.SettInvsApp,
        });

        console.log("res.data.RptInv");
        console.log(res.data);
      })
      .catch((error) => {});

    setAE(false);
  };

  useEffect(() => {
    getInfo();
  }, []);

  // stayWCa

  const totalsContntA_ = () => {
    return (
      <Grid
        item
        container
        className={clas.BdytotResSub}
        style={{ borderTop: "solid 0.07rem black" }}
        spacing={1}
      >
        {permApp?.tobaccoFees ? (
          <Grid item container xs={12}>
            <Grid item xs={8}>
              <Grid item xs={12}>
                {"Sub Total: المجموع"}
              </Grid>
            </Grid>
            <Grid item xs={4} className={clasm.minrit}>
              {(sta.tot.tot_net - sta.tot.amountOfVat) / 2}
            </Grid>
            <Grid item xs={8}>
              {"Tobacco Fees رسوم التبغ "}
              {"(100%)"}
            </Grid>
            <Grid item xs={4} className={clasm.minrit}>
              {sta.tot.tab3Vat}
            </Grid>
            <Grid item xs={8}>
              {"VAT  ضريبة القيمة المضافة"}
              {/* {`(${Number((sta.tot.tax - 100) / 2)}%)`} */}
            </Grid>
            <Grid item xs={4} className={clasm.minrit}>
              {Number(sta.tot.amountOfVat).toFixed(2)}
            </Grid>
          </Grid>
        ) : (
          <Grid item container xs={12}>
            <Grid item xs={8}>
              <Grid item xs={12}>
                {"Sub Total: المجموع"}
              </Grid>
            </Grid>
            <Grid item xs={4} className={clasm.minrit}>
              {sta.tot.tot_net - sta.tot.amountOfVat + sta.tot.discount}
            </Grid>
            <Grid item xs={8}>
              {"VAT  ضريبة القيمة المضافة"}
            </Grid>
            <Grid item xs={4} className={clasm.minrit}>
              {sta.tot.amountOfVat}
            </Grid>
          </Grid>
        )}

        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={8}>
          <Grid item xs={12}>
            {"Orand Total الإجمالي"}
          </Grid>
        </Grid>
        <Grid item xs={4} className={clasm.minrit}>
          {sta.tot.tot_net}
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid item container className={clas.Bdyroot} spacing={1}>
      <Grid item container className={clas.BdyundrHeaderTF}>
        {locInv.ProcTypeInvProc != "03" && (
          <Grid item xs={12} className={clasm.mincntr}>
            {/* <Typography className={[clas.contnt,clasm.minclr]}>{sta.StngIA.titleFatoraA}</Typography> */}
            <Typography className={[clas.contnt]}>
              {sta.tot.tax_no_cust === ""
                ? "فاتورة ضريبية مبسطة"
                : "فاتورة ضريبية"}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Header />
        </Grid>
        <Grid item xs={12} className={clasm.mincntr}>
          <Typography className={[clas.contnt]}>
            {locInv.invN +
              " " +
              sta.tot.inv_type_name +
              " " +
              sta.tot.pay_type_name}
            {/* {locInv.invN+" "+sta.tot.inv_type_name+" "+ sta.tot.pay_type_name} */}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} className={clasm.mincntr}>
        <Typography className={clasm.minclr} style={{ fontSize: 24 }}>
          {sta.tot.id}
        </Typography>
      </Grid>
      <Grid item xs={12} className={clasm.minrit}>
        <Typography className={clas.contnt}>
          {moment(sta.tot.date).format("h:mm A YYYY/MM/DD")}
        </Typography>
        <Divider />
      </Grid>
      <Grid item container>
        <Grid item xs={sta.StngIA.ncol}>
          {"Ref NO:"}
        </Grid>
        <Grid item xs={sta.StngIA.ncol}>
          <Paper className={clasm.minclr}>{sta.tot?.refno}</Paper>
        </Grid>
        <Grid item xs={12} className={clasm.minborB}>
          <div
            className={clas.contnt}
            style={{ textAlign: "right", marginBottom: "0.2rem" }}
          >
            {sto.usernames}
          </div>
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>

      <Grid item container>
        {sta.info.map((item, i) => (
          <Grid item container key={i}>
            <Grid item className={clas.BdyrowRes} xs={10}>
              {item.val1 + " * "}
              {item.nameEN}
            </Grid>
            {/* <Grid item className={clas.BdyrowRes} xs={2}>
              {item.new_exchange_price}
            </Grid> */}

            <Grid item className={clasm.minrit} xs={2}>
              {item.tab3Val + item.tot_net_curr}
            </Grid>
            <Grid item className={clas.BdyrowSyRes} xs={12}>
              {item.symbol}
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Divider />
      {totalsContntA_()}
    </Grid>
  );
};
export default Body;
