import React, { useEffect } from "react";

import {
  Avatar,
  Chip,
  colors,
  Container,
  Divider,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { useStylesHed } from "../../../../St/comps/UseStyle";
import useFormDataCRUD from "../../../../components/Hooks/useEDSCURD";
import EReceiptAPI from "../../../../api/EReceipt";

import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useAPI } from "../../../../api/useAPI";
import { restrictDefaultValue } from "../../../../services/restrictionService";
import DRestForm from "./daily-restrictions-form";
import DRestrAppBar from "./daily-restrictions-AppBar";
import ORestSub from "../opening-restrictions/opening-restrictions-sub/opening-restrictions-sub";

const useStyles = makeStyles((theme) => ({
  root: {
    // minHeight: '100%',
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(10),
  },
  itemW: {
    // display:'flex',
    width: "90%",
  },

  allWidth: {
    minWidth: 650,
  },
  checStyl: {
    marginTop: "17px",
    fontSize: "16px",
    padding: "-1px",
  },
  avatar: {
    backgroundColor: colors.grey[800],
    color: "#fff",
    height: 46,
    width: 46,
    marginTop: -5,
  },
  icon: {
    height: 32,
    width: 32,
  },
}));

export default function ORestrictions(props) {
  const { AutoOptions, prmis, fetchURL, isDialog, item, onClose } = props;
  const { createFun, updateFun, removeFun } = useAPI("RestrictionInsert");

  const defaultV = {
    ...restrictDefaultValue,
    doc_no2: AutoOptions?.other?.lastid,
    book_id: AutoOptions?.AccountBooks[0],
    rows: [],
  };
  const accounts = AutoOptions?.Accounts;

  useEffect(() => {
    console.log("the effect ", defaultV);
  });

  const {
    onChange,
    submit,
    update,
    remove,
    submitting,
    errorMessage,
    onShow,
    onEnd,
    object,
    formType,
  } = useFormDataCRUD(
    createFun,
    updateFun,
    removeFun,
    undefined,
    defaultV,
    null,
    () => {
      if (isDialog) onClose(true);
      else fetchURL();
    },
    isDialog ? "edit" : "new"
  );

  const { t } = useTranslation(["forms", "translation"]);

  const classes = useStyles();
  const clashd = useStylesHed();
  const total_credit = object?.rows
    ?.reduce((a, v) => (a = a + v.credit_loc), 0)
    .toFixed(2);
  const total_dept = object?.rows
    ?.reduce((a, v) => (a = a + v.dept_loc), 0)
    .toFixed(2);
  let total_difference = Math.abs(total_credit - total_dept).toFixed(2);

  return (
    <>
      <div
        className={classes.root}
        style={{ direction: i18next.language == "ar" ? "rtl" : "ltr" }}
      >
        <Grid
          item
          container
          justifyContent="space-evenly"
          spacing={0}
          className={clashd.rootHd}
          style={{
            // paddingTop: "8px",
            color: colors.grey[900],
            // backgroundColor: colors.grey[400],
          }}
        >
          <Grid item>
            <Typography
              variant={"h4"}
              component="h2"
              style={{
                backgroundColor: colors.grey[400],
                paddingInline: 15,
                paddingTop: 5,
              }}
            >
              القيود اليومية
            </Typography>
          </Grid>
        </Grid>
        <Typography
          component="div"
          style={{
            direction: i18next.language == "ar" ? "rtl" : "ltr",
            marginTop: "0px",
            borderColor: colors.grey[400],
            borderWidth: "10px",
            borderStyle: "solid",
          }}
        >
          <Grid container justifyContent="center" spacing={0}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Container
                component={Paper}
                maxWidth={true}
                style={{
                  padding: "10px",
                  borderBottomWidth: "0px",
                  borderRightColor: colors.grey[200],
                  borderRightWidth: "0px",
                  borderRightStyle: "solid",
                }}
              >
                <DRestForm
                  {...{
                    ...props,
                    object,
                    formType,
                    onChange,
                    onShow,
                    AutoOptions,
                  }}
                />
              </Container>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Container
                component={Paper}
                maxWidth={true}
                style={{
                  padding: "20px",
                  border: "0px",
                  borderColor: colors.grey[400],
                  borderTopWidth: i18next.language == "ar" ? "5px" : "5px",
                  borderLeftWidth: i18next.language == "ar" ? "0px" : "5px",
                  borderStyle: "solid",
                }}
              >
                <ORestSub
                  {...{
                    ...props,
                    object,
                    accounts: accounts,
                    onShow,
                    formType,
                    onChange,
                  }}
                />
              </Container>
            </Grid>
            <Grid item container lg={12} md={12} sm={12} sx={12}>
              <Container
                maxWidth={true}
                style={{
                  backgroundColor: colors.grey[200],
                  padding: 20,
                  // marginBottom: 80,
                }}
              >
                <Grid item container>
                  <Grid item lg={2} md={2} sm={2} xs={3}>
                    <Typography color="textSecondary" component="p">
                      الفارق :
                      {total_difference > 0 ? (
                        <b
                          style={{
                            paddingInline: 5,
                            color: "white",
                            backgroundColor: "red",
                          }}
                        >
                          {total_difference} {"  "}
                          {total_credit > total_dept ? "دائن" : "مدين"}
                        </b>
                      ) : (
                        <b>{total_difference}</b>
                      )}
                    </Typography>
                  </Grid>

                  <Grid item lg={2} md={2} sm={2} xs={3}>
                    <Typography color="textSecondary" component="p">
                      اجمالي المدين :<b>{total_dept}</b>
                    </Typography>
                  </Grid>

                  <Grid item lg={2} md={2} sm={2} xs={3}>
                    <Typography color="textSecondary" component="p">
                      اجمالي الدائن :<b>{total_credit}</b>
                    </Typography>
                  </Grid>

                  <Grid item lg={2} md={2} sm={2} xs={3}>
                    <Typography color="textSecondary" component="p">
                      <b>
                        {total_difference > 0 ? "قيد غير متوازن" : "قيد متوازن"}
                      </b>
                    </Typography>
                  </Grid>
                </Grid>
              </Container>
            </Grid>
          </Grid>
        </Typography>
      </div>
      <DRestrAppBar
        {...{
          URL: EReceiptAPI.MSURL,
          prmis,
          ...props,
          onChange,
          submit,
          update,
          defaultV,
          remove,
          submitting,
          errorMessage,
          onShow,
          onEnd,
          object,
          formType,
          AutoOptions,
        }}
      />
    </>
  );
}
