import React, { useState, useEffect } from 'react';

import useFetchObjectByPost from '../../../../../components/Hooks/useFetchObjectByPost';
import { sto } from '../../../../../Context/InshData';
import { uri } from '../../../../../help/Api';
import CurrencySalesProfit from './CurrencySalesProfit';
const defaultAutos ={
  Safes:[],
}
export default function CurrencySalesProfitCon() {

      const tb = {
                  wait:true,
                  Safes:true, 
                  Customers: true,
                  Currencies: true,
                  stor:sto,
                  parms:{safe:-1}
            }
        const {object: AutoOptions, fetching:fetchAll} = useFetchObjectByPost(uri+'Rept',tb); 

  useEffect(()=>{

  },[AutoOptions])

  if(fetchAll || !AutoOptions?.Safes )
    return <></>

  return (
  <CurrencySalesProfit {...{
    AutoOptions: AutoOptions?.Safes? AutoOptions: defaultAutos,
  } 
  }/>
  );




};