import React, { useState, useEffect } from "react";

import useFetchObjectByPost from "../../../../../components/Hooks/useFetchObjectByPost";
import useFetchURL from "../../../../../components/Hooks/useFetchURL";
import { sto } from "../../../../../Context/InshData";
import { uri } from "../../../../../help/Api";
import SaleManComm from "./SaleManComm";
import HashLoader from "react-spinners/HashLoader";
import { colors, Container, Dialog, Modal } from "@material-ui/core";
import LoadingPage from "../../../../../services/LoadingPage";
import { LocalConvenienceStoreOutlined } from "@material-ui/icons";
const defaultAutos = {
  Safes: [],
};

export default function SaleManCommCon() {
  const tb = {
    wait: true,
    salesmen: true,
    autos: { salesmens: true },
    parms: { safe: -1 },
  };

  const { object: data, fetching: fetchAll } = useFetchObjectByPost(
    uri + "Res",
    tb
  );

  if (fetchAll || !data?.salesmen) return <LoadingPage />;

  return (
    <SaleManComm
      {...{
        SalesMens: [{ id: -1, name: "الكل" }, ...data?.salesmen],
      }}
    />
  );
}
