import React, {useEffect, useState} from 'react';
// import Container from '@material-ui/core/Container';
import  Swipeable  from '../../../../../Fronts/Main/Swipeable';
import Typography from '@material-ui/core/Typography';

import Body from './Body';

export default function ReportSalTafsele(){

return (
<>
<Body/>
<Typography component="div"> <Swipeable/> </Typography>
</>
  );
}


