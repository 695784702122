import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import PhonelinkIcon from "@material-ui/icons/Phonelink";
import Paper from "@material-ui/core/Paper";
import { setLS } from "../utils/useLS";

import Image from "../assets/img/apple-icon.png"; // Import using relative path

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submitContent: {
    margin: theme.spacing(0, 0, 2),
    backgroundColor: "#d9edff",
  },

  submit: {
    margin: theme.spacing(30, 0, 2),
    fontSize: "1rem",
    color: "#fff",
    fontWeight: 900,
    padding: (50, 20),
    width: "50%",
    backgroundImage: "linear-gradient(to right, #4e9e84 , #2688ba)",
    borderRadius: 10,
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    width: "85%",
    height: "178px",
  },
}));

const styles = {
  paperContainer: {
    backgroundImage: `url(${Image})`,
    height: "100px",
    boxShadow: "none",
    width: "100px",
    borderRadius: "79%",
    backgroundSize: "contain",
  },
  txtLin: {
    textAlign: "-webkit-center",
    display: "flex",
  },
};

export default function Accsept() {
  const classes = useStyles();
  let his = useHistory();

  const inshState = {
    server: "",
    ipinsh: "10.147.20.",
    ip: "10.147.20.",
    // ipinsh: "127.0.0.",
    // ip: "127.0.0.",
    changip: "",
    endDigtip: "",
    namedb: "OraxLite",
    id: "sa",
    pass: "0",
    ftp: "https",
    port: ":443",
    // ftp: "http",
    // port: ":8184",
    // ftp:'http', port: ':80',
    useUri: false,
    typeConnct: false,
    mode: 0,
    leavle: "endemam",
  };

  async function reCon() {
    // setConn();
    await setLS("infoConn", inshState);
    await setLS("showCurr", { statu: false, rout: "D" });
    // localStorage.setItem('showCurr', JSON.stringify({statu:false,rout:"D"}));
    //  let ic = await getLS('infoConn');
    //  alert(ic.leavle)
    // window.location.reload(false);
    // window.localStorage.setItem("data", JSON.stringify(dataa));
    // let data = JSON.parse(window.localStorage.getItem("data"));
    // alert(data.url)
    // his.replace({ pathname: '/'})
    // console.log('reCon')
    // alert(inshState.leavle)

    his.replace({ pathname: "/" });
    // his.go(0)
    // his.reload();
    // location.reload(true)
  }

  // useEffect(() => { }, []);

  return (
    <React.Fragment>
      <CssBaseline />
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Paper style={styles.paperContainer}> </Paper>

          <br />
          <Typography component="h1" variant="h5">
            <Button
              style={{
                backgroundColor: "#fafafa",
                fontSize: "2rem",
                color: "#1770a1",
              }}
              fullWidth
              disabled
              variant="contained"
              className={classes.submitContent}
              color="primary"
            >
              {/* {`مرحـــًبا بـك في`} */}
            </Button>

            <Button
              style={{
                backgroundColor: "#fafafa",
                fontSize: "1.5rem",
                color: "#279ed5",
              }}
              fullWidth
              disabled
              variant="contained"
              className={classes.submitContent}
              color="primary"
            >
              {`اوراكس كـلاود`}
            </Button>
          </Typography>

          <Grid container ex={12} justify="center">
            <Button
              className={classes.submit}
              onClick={() => reCon()}
              color="primary"
              size="large"
              startIcon={<PhonelinkIcon />}
            >
              {"ابـــــــدأ"}
            </Button>
          </Grid>
        </div>
      </Container>
    </React.Fragment>
  );
}
