import React, { useState } from "react";

import { TableIcons as tableIcons } from "../../../../../Elemnts/TableIcons";
import MaterialTable from "material-table";
import { Mtb } from "../../../../../St/comps/vstyle";

import { useEffect } from "react";
import { cellS, headerS } from "../../../../../St/comps/cStyle";
import { sto } from "../../../../../Context/InshData";
import Print from "@material-ui/icons/Print";
import { PictureAsPdfOutlined, ViewAgendaOutlined } from "@material-ui/icons";
import { PrintInvoiceDirectly } from "../../components/printSerivce";

export default function SearchInvTable(props) {
  const { data, showChosedInv, stElemnt, setOr, or, setting } = props;

  var columnsInv = [
    { title: "الرقم", field: "id", headerStyle: headerS, cellStyle: cellS },
    {
      title: "التاريخ",
      field: "date",
      headerStyle: headerS,
      cellStyle: cellS,
    },
    {
      title: "الوقت",
      field: "time",
      headerStyle: headerS,
      cellStyle: cellS,
    },
    {
      title: setting.cusN == undefined ? "" : setting.cusN,
      field: "customer_name",
      headerStyle: headerS,
      cellStyle: cellS,
    },
    {
      title: "المبلغ",
      field: "net_total",
      headerStyle: headerS,
      cellStyle: cellS,
    },
    {
      title: "نوع الفاتورة",
      field: "inv_type",
      headerStyle: headerS,
      cellStyle: cellS,
      render: (data) => (data.inv_type == 1 ? "اجل" : "نقدي"),
    },
    {
      title: "طريقة الدفع",
      field: "pay_typ",
      headerStyle: headerS,
      cellStyle: cellS,
      render: (data) =>
        data.inv_type == 1 ? "" : data.pay_type == 1 ? "كاش" : "شبكة",
    },
    {
      title: "المستخدم",
      field: "username",
      headerStyle: headerS,
      cellStyle: cellS,
    },
  ];

  return (
    <MaterialTable
      style={{ boxShadow: "none" }}
      //style={{direction:'ltr'}}
      options={{
        showTitle: false,
        toolbar: false,
        sorting: false,
        selection: false,
        // ToolBar Search
        searchFieldAlignment: "left",
        searchFieldVariant: "outlined",
        // ToolBar header
        showSelectAllCheckbox: false,
        showTextRowsSelected: false,
        pageSize: 5,
        paginationType: "stepped",
        showFirstLastPageButtons: false,
        actionsColumnIndex: -1,
        paging: false,

        maxBodyHeight: Mtb.minmaxBHInvs,
        minBodyHeight: Mtb.minmaxBHInvs,
        // padding: 'dense',
        rowStyle: stElemnt.InvsR,
        headerStyle: stElemnt.InvsH,
      }}
      localization={{
        body: {
          emptyDataSourceMessage: (
            <h3 style={{ color: "#000" }}>لا توجد فواتير بعد</h3>
          ),
        },
        toolbar: { searchPlaceholder: "بـحـث" },
      }}
      columns={columnsInv}
      data={data}
      icons={tableIcons}
      onRowClick={(event, rowData) => {
        setOr({ ...or, postput: "toPut", PrAC: 0 });
        showChosedInv(false, rowData.proc_id);
        event.stopPropagation();
      }}
      actions={[
        {
          // icon: "save",
          icon: () => <Print />,
          // iconProps: {
          //   style: {
          //     justifyContent: "center",
          //     fontSize: "40px",
          //     // color: "red",
          //   },
          // },
          tooltip: "طباعة",
          onClick: async (event, data) => {
            const serializedinv = JSON.stringify({
              proc_id: data.proc_id,
              invN: setting.titleHed,
              invNEn: setting.titleHedEn,
              // salesman: valueSalesmen.name,
              backInv: "InvSO",
              branch: sto.branchi,
              ProcTypeInvProc: setting.PrTy_InPr,
            });
            await PrintInvoiceDirectly(
              JSON.parse(serializedinv),
              false,
              () => {}
            );
          },
        },
      ]}
    />
  );
}
