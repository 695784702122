import React, { useState, useEffect } from "react";

import useFetchURL from "../../../../components/Hooks/useFetchURL";
import { uri } from "../../../../help/Api";
import moment from "moment";

import { useHistory, useLocation } from "react-router-dom";
import SaleReportTaxForm from "./saleReportTax-form";

const defaultValues = {
  dateFrom: moment(new Date()).format("YYYY-MM-DD"),
  dateTo: moment(new Date()).format("YYYY-MM-DD"),
  branch: { id: -1, name: "الكل" },
  safe: { id: -1, name: "الكل" },
  costCenter: { id: -1, name: "الكل" },
  cust: { id: -1, name: "الكل" },
  itemsTypeVat: "0",
  user_id: -1,
};

const defaultData = {
  SalePurchVat: [],
  totals: {
    rowsCount: 0,
    TotalVat: 0,
    TotalBeforVat: 0,
    TotalAfterVat: 0,
  },
};
export default function SaleReportTax() {
  let his = useHistory();
  const locat = useLocation();

  const [rptParams, setRptParams] = React.useState(defaultValues);

  const { data, fetching, fetchURL } = useFetchURL({
    ...{
      url:
        uri +
        `SalePurchVat?branch=${rptParams.branch.id}
    &&safe=${rptParams.safe.id}
    &&costCenter=${rptParams.costCenter.id}
    &&dateF=${rptParams.dateFrom}
    &&dateT=${rptParams.dateTo}
    &&cust_id=${rptParams.cust.id}
    &&itemsTypeVat=${rptParams.itemsTypeVat}
    &&systemId=${locat.state.systemId}
    &&user_id=${rptParams.user_id}`,
      dependencyArray: [],
    },
  });

  // useEffect(()=>{
  //   console.log("histort is ", data);
  // })

  const onChangeData = (propName) => (event) => {
    setRptParams({ ...rptParams, [propName]: event.target.value });
  };

  const printHandle = () => {
    const SalePurchVat = data?.SalePurchVat?.map((d) => {
      return { ...d, Date: moment(d.Date).format("YYYY-MM-DD") };
    });
    const info = {
      ...{ ...data, SalePurchVat: SalePurchVat },
      dateFrom: rptParams.dateFrom,
      dateTo: rptParams.dateTo,
      title: locat.state.titleHed,
    };

    his.push({ pathname: "/TSP", state: info });

    window.location.reload(false);
  };

  return (
    <SaleReportTaxForm
      {...{
        data: data?.SalePurchVat ? data : defaultData,
        onChangeData,
        printHandle,
        rptParams,
        setRptParams,
        fetchURL,
        fetching,
        title: locat.state.titleHed,
        systemId: locat.state.systemId,
      }}
    />
  );
}
