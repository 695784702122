import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { colors } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  tableRightBorder: {
    borderWidth: 1,
    borderColor: "black",
    borderStyle: "solid",
    fontFamily: "Amiri-Regular",
    fontWeight: 800,
    fontSize: 16,
  },
  rowHeader: {
    fontFamily: "Amiri-Regular",
    fontWeight: 800,
    fontSize: 16,
  },
}));

export default function MainRBalanceTable(props) {
  const { data, title } = props;
  const { t } = useTranslation(["forms"]);

  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow
            style={{
              backgroundColor: colors.blue[300],
              color: "#000",
              fontWeight: 800,
              fontFamily: "Amiri-Regular",
              fontSize: 22,
            }}
          >
            <TableCell align="center" colSpan={2}></TableCell>
            <TableCell
              align="center"
              colSpan={2}
              className={classes.tableRightBorder}
            >
              {t("initial-balance")}
            </TableCell>
            <TableCell
              className={classes.tableRightBorder}
              align="center"
              colSpan={2}
            >
              {t("prev-balance")}
            </TableCell>
            <TableCell
              className={classes.tableRightBorder}
              align="center"
              colSpan={2}
            >
              {" "}
              {t("acc-move")}
            </TableCell>
            <TableCell
              className={classes.tableRightBorder}
              align="center"
              colSpan={2}
            >
              {t("acc-balance")}
            </TableCell>
          </TableRow>
          <TableRow
            style={{
              backgroundColor: colors.blue[300],
              fontSize: 18,
              fontFamily: "Amiri-Regular",
              color: "#000",
            }}
          >
            <TableCell className={classes.tableRightBorder} align="center">
              {t("account-code")}
            </TableCell>
            <TableCell className={classes.tableRightBorder} align="center">
              {t("account")}
            </TableCell>
            <TableCell
              className={classes.tableRightBorder}
              style={{ fontWeight: 800, fontSize: 16 }}
              align="center"
            >
              {" "}
              {t("dept")}
            </TableCell>
            <TableCell className={classes.tableRightBorder} align="center">
              {" "}
              {t("credit")}
            </TableCell>
            <TableCell
              className={classes.tableRightBorder}
              style={{ fontWeight: 800, fontSize: 16 }}
              align="center"
            >
              {" "}
              {t("dept")}{" "}
            </TableCell>
            <TableCell className={classes.tableRightBorder} align="center">
              {" "}
              {t("credit")}
            </TableCell>
            <TableCell className={classes.tableRightBorder} align="center">
              {" "}
              {t("dept")}{" "}
            </TableCell>
            <TableCell className={classes.tableRightBorder} align="center">
              {" "}
              {t("credit")}{" "}
            </TableCell>
            <TableCell className={classes.tableRightBorder} align="center">
              {" "}
              {t("dept")}{" "}
            </TableCell>
            <TableCell className={classes.tableRightBorder} align="center">
              {" "}
              {t("credit")}{" "}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.id}>
              <TableCell align="center" className={classes.tableRightBorder}>
                {row.ac}
              </TableCell>
              <TableCell align="center" className={classes.tableRightBorder}>
                {row.AName}
              </TableCell>
              <TableCell align="center" className={classes.tableRightBorder}>
                {row.val_open_dept?.toFixed(2) ?? "-"}
              </TableCell>
              <TableCell align="center" className={classes.tableRightBorder}>
                {row.val_open_credit?.toFixed(2) ?? "-"}
              </TableCell>
              <TableCell align="center" className={classes.tableRightBorder}>
                {row.OB_DR_AMT_L?.toFixed(2) ?? "-"}
              </TableCell>
              <TableCell align="center" className={classes.tableRightBorder}>
                {row.OB_CR_AMT_L?.toFixed(2) ?? "-"}
              </TableCell>
              <TableCell align="center" className={classes.tableRightBorder}>
                {row.DR_AMT_L?.toFixed(2) ?? "-"}
              </TableCell>
              <TableCell align="center" className={classes.tableRightBorder}>
                {row.CR_AMT_L?.toFixed(2) ?? "-"}
              </TableCell>
              <TableCell align="center" className={classes.tableRightBorder}>
                {row.End_DR_AMT_L?.toFixed(2) ?? "-"}
              </TableCell>
              <TableCell align="center" className={classes.tableRightBorder}>
                {row.End_CR_AMT_L?.toFixed(2) ?? "-"}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
