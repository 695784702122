import moment from "moment/moment";

export const selectTypes = [
  { id: 1, finalAcc: 1, name: "mezania", label: "balance-sheet" },
  { id: 2, finalAcc: 2, name: "arbah", label: "profit-loss" },
  { id: 3, finalAcc: -1, name: "", label: "trial-balance" },
];

export const levelOptions = [
  { id: -1, label: "الكل", type: -1 },
  { id: 1, label: "1", type: 1 },
  { id: 2, label: "2", type: 1 },
  { id: 3, label: "3", type: 1 },
  { id: 4, label: "4", type: 2 },
];
export const typeCostOptions = [
  { name: "متوسط سعر التكلفة", id: 1 },
  { name: "أخر سعر شراء", id: 2 },
  { name: "اخر سعر توريد", id: 3 },
  { name: "سعر الشراء في الأصناف", id: 4 },
];

export const aMdefaultValues = {
  dateFrom: moment(new Date()).subtract(6, "month").format("YYYY-MM-DD"),
  dateTo: moment(new Date()).format("YYYY-MM-DD"),
  branch: { id: -1, name: "الكل" },
  center: { id: -1, name: "الكل" },
  level: levelOptions[0],
  type: selectTypes[0],
  chk_Prev: true,
  chkPrev: true,
  type_cost: typeCostOptions[0],
  no_stock: true,
  acc_type: 0,
};

export const excelHeaders = [
  { label: "رقم الحساب", key: "ac" },
  { label: "الحساب", key: "AName" },
  { label: "مدين (افتتاحي)", key: "val_open_dept" },
  { label: "دائن (افتتاحي)", key: "val_open_credit" },
  { label: "مدين (سابق)", key: "OB_DR_AMT_L" },
  { label: "دائن (سابق)", key: "OB_CR_AMT_L" },
  { label: "مدين (حركة)", key: "DR_AMT_L" },
  { label: "دائن (حركة)", key: "CR_AMT_L" },
  { label: "مدين (رصيد)", key: "End_DR_AMT_L" },
  { label: "دائن (رصيد)", key: "End_CR_AMT_L" },
];
