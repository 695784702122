import React from "react";
import Typography from "@material-ui/core/Typography";
import Swipeable from "../../../../Fronts/Main/Swipeable";
import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";
import { Button } from "@material-ui/core";
import CheckboxItem, { useStylesAcc } from "../CheckBoxComponent";
const formData = [
  { name: "Automatic_Insert", label: "الإدخال (أوتماتيك)", visible: false },
  { name: "ShowQtyScreen", label: "اخفاء شاشة عرض الكميات", visible: false },
  {
    name: "UpdateSalePrice",
    label: "السماح بتعديل السعر عند البيع",
    visible: true,
  },
  {
    name: "sale_no_less_than_purch",
    label: "عدم السماح بسعر البيع اقل من سعر الشراء",
    visible: true,
  },
  {
    name: "UpdateSaleDate",
    label: "السماح بتعديل تاريخ فاتورة البيع",
    visible: true,
  },
  {
    name: "SaleInMinus",
    label: "السماح بالبيع بدون كمية بالمخزن",
    visible: true,
  },
  {
    name: "saleVatUpdate",
    label: "السماح بتعديل الضريبة عند البيع",
    visible: true,
  },
  {
    name: "allow_salePrice_zero",
    label: "السماح بالبيع بصفر كزيادة او هدية",
    visible: false,
  },
  {
    name: "allow_return_Without_bill",
    label: "السماح بالارجاع بدون رقم فاتورة",
    visible: true,
  },
  {
    name: "allow_replace_Without_bill",
    label: "السماح بالاستبدال بدون رقم فاتورة",
    visible: true,
  },
  {
    name: "inv_post_unlock",
    label: "السماح بتعديل او حذف فاتورة بعد الترحيل",
    visible: true,
  },
  {
    // this field for users table
    name: "register_customer_no_account",
    label: "السماح بتسجيل عميل للفاتورة بدون حساب",
    visible: false,
  },
  {
    name: "PrintAfter_Save",
    label: "طباعة الفاتورة مباشرة بعد الحفظ",
    visible: true,
  },
  { name: "", label: "السماح بالخصم ", visible: false },
];

export default function SalePermissions(props) {
  const { onChange, object, update, sub } = props;

  const classes = useStylesAcc();

  return (
    <div className={classes.root}>
      <Accordion expanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ background: "#d9dcf078" }}
        >
          <Typography className={classes.heading}>إعدادات المبيعات</Typography>
        </AccordionSummary>

        <AccordionDetails className={classes.subTitle}>
          {" "}
          {/* <Typography> الاعدادات الاساسية</Typography>{" "} */}
        </AccordionDetails>

        <AccordionDetails>
          <Grid item container spacing={1}>
            {formData
              ?.filter((d) => d.visible)
              ?.map((item) => {
                // console.log(object);
                return (
                  <Grid xs={6}>
                    <CheckboxItem
                      value={object[item.name] ?? null}
                      name={item.name}
                      label={item.label}
                      onChange={onChange}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </AccordionDetails>

        <Divider className={classes.divider} />

        <AccordionDetails className={classes.subTitle}>
          {" "}
          <Typography>
            {" "}
            <Button
              variant="contained"
              color="secondary"
              size="large"
              disabled={props.submitting}
              onClick={update}
            >
              {"حفظ التعديلات"}
            </Button>
          </Typography>{" "}
        </AccordionDetails>
      </Accordion>
      <Swipeable />
    </div>
  );
}
